import React, { useContext } from "react";
import { Redirect, Route } from "react-router-dom";

import { AuthContext } from "../Context/Api/Openstack/AuthContextProvider";

const PrivateRoute = ({ context, component: Component, ...rest }) => {
    const authContext = useContext(AuthContext);
    return (
        <Route { ...rest } render={ props => authContext.loggedIn ? (
            <Component { ...props } />
        ) : (
            <Redirect to={ { pathname: "/", state: { from: props.location } } }/>
        ) }
        />
    );
};

export default React.memo(PrivateRoute);
