import React, { Component, createContext } from "react";

import API from "../../../Api/Openstack";
import { ErrorContext } from "../../ErrorContextProvider";
import isEqual from "../../../Ultils/isEqual";

export const UserContext = createContext({});

class UserContextProvider extends Component {
    static contextType = ErrorContext;

    state = {
        user: {},
        loading: true,
        crefoPayToken: null
    };

    /**
     * fetchUser
     */
    getUser = () => {
        return API.getUser()
            .then(({ data }) => {
                // check if loading is true => set it to false
                if (this.state.loading) {
                    this.setState({ loading: false });
                }

                if (!isEqual(this.state.user, data)) {
                    this.setState({ user: data });
                }
            })
            .catch(error => {
                // check if loading is true => set it to false
                if (this.state.loading) {
                    this.setState({ loading: false });
                }

                this.context.actions.showError(error);
                return new Error(error);
            });
    };

    /**
     * updateUser
     */
    updateUser = userData => {
        return API.updateUser(userData)
            .then(() => {
                // check if loading is true => set it to false
                if (this.state.loading) {
                    this.setState({ loading: false });
                }
            })
            .catch(error => {
                // check if loading is true => set it to false
                if (this.state.loading) {
                    this.setState({ loading: false });
                }

                this.context.actions.showError(error);
                return new Error(error);
            });
    };

    /**
     * @param userData
     * @param captchaCode
     * @returns {AxiosPromise<any>}
     */
    registerUser = (userData, captchaCode) => {
        return API.registerUser(userData, captchaCode)
            .then((response) => {
                // check if loading is true => set it to false
                if (this.state.loading) {
                    this.setState({ loading: false });
                }

                if (!isEqual(this.state.crefoPayToken, response.data.data)) {
                    this.setState({ crefoPayToken: response.data.data });
                }
            })
            .catch(error => {
                // check if loading is true => set it to false
                if (this.state.loading) {
                    this.setState({ loading: false });
                }

                this.context.actions.showError(error);
                return new Error(error);
            })
    };

    /**
     * @param userId
     * @returns {Promise<AxiosResponse<any> | Error>}
     */
    userConfirmation = userId => {
        return API.confirmUser(userId)
            .catch(error => {
                // check if loading is true => set it to false
                if (this.state.loading) {
                    this.setState({ loading: false });
                }

                this.context.actions.showError(error);
                throw new Error(error);
            })
    };

    userChangePayment = () => {
        return API.changePayment()
            .then(({ data }) => {
                return data;
            })
            .catch(error => {
                // check if loading is true => set it to false
                if (this.state.loading) {
                    this.setState({ loading: false });
                }

                this.context.actions.showError(error);
                return new Error(error);
            });
    };

    render() {
        return (
            <UserContext.Provider value={ {
                ...this.state,
                actions: {
                    getUser: this.getUser,
                    updateUser: this.updateUser,
                    userChangePayment: this.userChangePayment,
                    userConfirmation: this.userConfirmation,
                    registerUser: this.registerUser,
                }
            } }>
                { this.props.children }
            </UserContext.Provider>
        );
    }
}

export default UserContextProvider;
