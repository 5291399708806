import React, { Fragment, useContext, useState } from "react";
import { useDidMount } from "react-hooks-lib";
import { NotificationManager } from "react-notifications";
import Skeleton from "react-loading-skeleton";

import withTranslation from "../../../Ultils/withTranslation";
import FloatingItem from "./FloatingItem";
import { FloatsContext } from "../../../Context/Api/Openstack/FloatingsContextProvider";
import { ModalContext } from "../../../Context/ModalContextProvider";
import ConfirmationModal from "../../Modal/ConfirmationModal";
import { EmptyGroup } from "../../Styling/EmptyGroup";

const Floating = props => {
    const { translation } = props;
    const floatingContext = useContext(FloatsContext);
    const modalContext = useContext(ModalContext);
    const [ isBusy, setIsBusy ] = useState(false);

    useDidMount(() => {
        floatingContext.actions.fetchFloats();
    });

    /**
     * @param computeId
     */
    const handleRequestNewFloat = () => {
        modalContext.actions.setModalContent(ConfirmationModal, {
            confirmAction: () => requestNewFloat(),
            title: translation.react.float.create.title,
            description: translation.react.float.create.description,
            action: translation.react.global.order,
            cancel: translation.react.global.cancel
        }, { maxWidth: '600px' });
    };

    const requestNewFloat = () => {
        setIsBusy(true);
        floatingContext.actions.requestNewFloating(() => {
            setIsBusy(false);
            NotificationManager.success(translation.react.float.create.success, translation.react.global.success);
        });
    };

    return (
        <Fragment>
            { floatingContext.floats.length > 0 && (
                <button disabled={ isBusy } className="btn btn--primary float-right" onClick={ handleRequestNewFloat }>{ translation.react.float.newIp }</button>
            ) }
            <h2 className="mb-4">{ translation.react.float.floatingIps }</h2>
            { !floatingContext.loading ? (
                <Fragment>
                    { floatingContext.floats.length > 0 ? (
                        <table className="table table--striped table--bordered mb-0">
                            <thead>
                            <tr>
                                <th scope="col">{ translation.react.global.ip }</th>
                                <th scope="col">{ translation.react.global.server }</th>
                                <th scope="col" width={ 65 }></th>
                            </tr>
                            </thead>
                            <tbody>
                            { floatingContext.floats.map(float => (
                                <FloatingItem key={ float.id } translation={ translation } float={ float }/>
                            )) }
                            </tbody>
                        </table>
                    ) : (
                        <EmptyGroup>
                            <svg className="svg-inline--fa fa-globe fa-w-16 fa-3x" aria-hidden="true" focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 496 512">
                                <path fill="currentColor" d="M336.5 160C322 70.7 287.8 8 248 8s-74 62.7-88.5 152h177zM152 256c0 22.2 1.2 43.5 3.3 64h185.3c2.1-20.5 3.3-41.8 3.3-64s-1.2-43.5-3.3-64H155.3c-2.1 20.5-3.3 41.8-3.3 64zm324.7-96c-28.6-67.9-86.5-120.4-158-141.6 24.4 33.8 41.2 84.7 50 141.6h108zM177.2 18.4C105.8 39.6 47.8 92.1 19.3 160h108c8.7-56.9 25.5-107.8 49.9-141.6zM487.4 192H372.7c2.1 21 3.3 42.5 3.3 64s-1.2 43-3.3 64h114.6c5.5-20.5 8.6-41.8 8.6-64s-3.1-43.5-8.5-64zM120 256c0-21.5 1.2-43 3.3-64H8.6C3.2 212.5 0 233.8 0 256s3.2 43.5 8.6 64h114.6c-2-21-3.2-42.5-3.2-64zm39.5 96c14.5 89.3 48.7 152 88.5 152s74-62.7 88.5-152h-177zm159.3 141.6c71.4-21.2 129.4-73.7 158-141.6h-108c-8.8 56.9-25.6 107.8-50 141.6zM19.3 352c28.6 67.9 86.5 120.4 158 141.6-24.4-33.8-41.2-84.7-50-141.6h-108z" className=""></path>
                            </svg>
                            <h3 className="mb-0">{ translation.react.float.empty.title }</h3>
                            <p>{ translation.react.float.empty.text }</p>
                            <button className="btn btn--primary mt-4 mb-0" onClick={ handleRequestNewFloat }>{ translation.react.float.empty.buttontext }</button>
                        </EmptyGroup>
                    ) }
                </Fragment>
            ) : (
                <table className="table table--striped table--bordered mb-0">
                    <thead>
                    <tr>
                        <th scope="col">{ translation.react.global.ip }</th>
                        <th scope="col">{ translation.react.global.server }</th>
                        <th scope="col" width={ 65 }></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td><Skeleton/></td>
                        <td><Skeleton/></td>
                        <td><Skeleton/></td>
                    </tr>
                    </tbody>
                </table>
            ) }

        </Fragment>
    );
};

export default withTranslation(Floating, [ 'react' ]);
