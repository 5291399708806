import React, { Component, createContext } from "react";

import API from "../../../Api/Openstack";
import { ErrorContext } from "../../ErrorContextProvider";
import isEqual from "../../../Ultils/isEqual";

export const SnapshotContext = createContext({});

class SnapshotsContextProvider extends Component {
    static contextType = ErrorContext;

    // default snapshot state
    state = {
        snapshots: [],
        loading: true,
    };

    /**
     * fetch Snapshots
     */
    fetchSnapshots = () => {
        return API.fetchSnapshots()
            .then(({ data }) => {
                // check if loading is true => set it to false
                if (this.state.loading) {
                    this.setState({ loading: false });
                }
                if (!isEqual(this.state.snapshots, Object.values(data))) {
                    this.setState({ snapshots: Object.values(data) });
                }
            })
            .catch(error => {
                // check if loading is true => set it to false
                if (this.state.loading) {
                    this.setState({ loading: false });
                }

                this.context.actions.showError(error);
                throw new Error(error);
            });
    };

    /**
     * @param snapshotId
     * @param callbackFn
     */
    deleteSnapshot = (snapshotId, callbackFn) => {
        return API.deleteSnapshot(snapshotId)
            .then(() => {
                callbackFn();
            })
            .catch(error => {
                callbackFn();
                this.context.actions.showError(error);
                throw new Error(error);
            });
    };

    /**
     * @param computeId
     * @param callbackFn
     */
    createSnapshot = (computeId, callbackFn) => {
        return API.createSnapshot(computeId)
            .then(() => {
                callbackFn();
            })
            .catch(error => {
                callbackFn();
                this.context.actions.showError(error);
                throw new Error(error);
            });
    };

    restoreSnapshot = (formData, callbackFn = () => {
    }) => {
        return API.restoreSnapshot(formData)
            .then(() => {
                callbackFn();
            })
            .catch(error => {
                callbackFn();
                this.context.actions.showError(error);
                throw new Error(error);
            });
    };

    /**
     * @param snapshotId
     * @returns {*}
     */
    getSnapshotWithId = snapshotId => {
        return this.state.snapshots.find(snapshot => snapshot.id === snapshotId);
    };

    render() {
        return (
            <SnapshotContext.Provider value={ {
                ...this.state,
                actions: {
                    fetchSnapshots: this.fetchSnapshots,
                    createSnapshot: this.createSnapshot,
                    deleteSnapshot: this.deleteSnapshot,
                    restoreSnapshot: this.restoreSnapshot,
                    getSnapshotWithId: this.getSnapshotWithId,
                }
            } }>
                { this.props.children }
            </SnapshotContext.Provider>
        );
    }
}

export default SnapshotsContextProvider;
