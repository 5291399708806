import React, { Fragment, useContext, useState } from "react";

import withTranslation from "../../../Ultils/withTranslation";
import RegisterSteps from "./RegisterSteps";
import { Card } from "../../Styling/Card";
import { CrefoPayContext } from "../../../Context/Api/Openstack/CrefoPayContextProvider";

import Logo from "../../../Assets/Images/mobi-logo-login.svg";
import { useDidMount } from "react-hooks-lib";

const RegisterConfirmation = props => {
    const crefoPayContext = useContext(CrefoPayContext);
    const [ registerState ] = useState(false);
    const { translation, history } = props;

    useDidMount(() => {
        // check if there is anything in the crefoPayContext => redirect to
        // registration
        if (!crefoPayContext.orderID || !crefoPayContext.paymentMethod || !crefoPayContext.paymentInstrumentID) {
            history.push("/register");
        }
    });

    return (
        <Fragment>
            <Card
                className={ `register register--step-3 ${ registerState ? 'register--is-busy' : '' }` }>
                <img className="logo mt-4" src={ Logo } width={ 160 } alt="Mobilistics"/>
                <RegisterSteps step={ 2 }/>
                <h2>{ translation.react.register.headline }</h2>
                { crefoPayContext.confirmationStatus === 200 && (
                    <Fragment>
                        <p className="mb-8">{ translation.react.validations.paymentConfirmed }</p>
                        <button className="btn btn--primary" onClick={ () => history.push("/") }>{ translation.react.global.login }</button>
                    </Fragment>
                ) }
                { crefoPayContext.confirmationStatus !== null && crefoPayContext.confirmationStatus !== 200 && (
                    <p>{ translation.react.validations.paymentConfirmationError }</p>
                ) }
            </Card>
        </Fragment>
    );
};

export default withTranslation(React.memo(RegisterConfirmation), [ 'react' ]);

