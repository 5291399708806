import React, { Component, createContext } from "react";

import API from "../../../Api/Openstack";

export const AuthContext = createContext({});

class AuthContextProvider extends Component {
    state = {
        loggedIn: false,
        loginExpires: null,
        token: null,
    };

    /**
     *
     * @param username
     * @param password
     */
    authenticate = ({ username, password }) => {
        if (!this.state.loggedIn && !this.state.token) {
            return API.authenticate(username, password)
                .then(({ data: { data } }) => {
                    this.setState({
                        loggedIn: true,
                        token: data.jwt,
                    });
                    window.sessionStorage.setItem('jwt', data.jwt);
                    window.sessionStorage.setItem('expires', data.expires);
                })
                .catch(error => {
                    this.setState({
                        loggedIn: false,
                        hasError: true,
                        token: null,
                        error,
                    });
                    return new Error(error);
                });
        }
    };

    getAuthenticationFromSession = () => {
        const { jwt, expires } = window.sessionStorage;

        if (jwt && expires && Math.floor(Date.now() / 1000) < expires) {
            // this.setState auth state to "loggedIn"
            this.setState({
                loggedIn: true,
                token: jwt,
            });
        } else {
            // clear session
            window.sessionStorage.clear();

            // reset auth state
            this.setState({
                loggedIn: false,
            });
        }
    };

    isUserLoggedIn = () => {
        const { jwt, expires } = window.sessionStorage;
        return (jwt && expires && Math.floor(Date.now() / 1000) < expires);
    };

    logout = () => {
        window.sessionStorage.clear();
        this.setState({
            loggedIn: false,
            loginExpires: null,
            token: null,
        });
    };

    clearError = () => {
        this.setState({
            loggedIn: false,
            loginExpires: null,
            token: null,
            hasError: false,
            error: null,
        })
    };

    render() {
        return (
            <AuthContext.Provider value={ {
                ...this.state,
                actions: {
                    authenticate: this.authenticate,
                    getAuthenticationFromSession: this.getAuthenticationFromSession,
                    isUserLoggedIn: this.isUserLoggedIn,
                    logout: this.logout,
                    clearError: this.clearError,
                }
            } }>
                { this.props.children }
            </AuthContext.Provider>
        )
    }
};

export default AuthContextProvider;
