import axios from "axios";
import API_CONST from "../constants";

/**
 * fetch snapshots action
 *
 * @returns {AxiosPromise<any>}
 */
const fetchSnapshots = () => {
    return axios.get(API_CONST.SNAPSHOT_URL);
};

/**
 * create snapshots action
 *
 * @param computeId
 * @returns {AxiosPromise<any>}
 */
const createSnapshot = computeId => {
    return axios.post(`${ API_CONST.COMPUTE_URL }/${ computeId }/snapshot`, {});
};

/**
 * delete snapshots action
 *
 * @param snapshotId
 * @returns {AxiosPromise<any>}
 */
const deleteSnapshot = snapshotId => {
    return axios.delete(`${ API_CONST.SNAPSHOT_URL }/${ snapshotId }`);
};

/**
 * restore compute via snapshot
 *
 * @param data
 * @returns {AxiosPromise<any>}
 */
const restoreSnapshot = formData => {
    return axios.post(`${ API_CONST.COMPUTE_URL }/snapshot/restore`, { ...formData })
};

export {
    fetchSnapshots,
    createSnapshot,
    deleteSnapshot,
    restoreSnapshot,
}
