import React, { Fragment, useContext, useState } from "react";
import { NotificationManager } from "react-notifications";
import { useDidMount } from "react-hooks-lib";

import withTranslation from "../../../Ultils/withTranslation";
import { Card } from "../../Styling/Card";
import RegisterSteps from "./RegisterSteps";
import { CrefoPayContext } from "../../../Context/Api/Openstack/CrefoPayContextProvider";
import { UserContext } from "../../../Context/Api/Openstack/UserContextProvider";
import { Spinner } from "../../Styling/Loading";

import Logo from "../../../Assets/Images/mobi-logo-login.svg";

const RegisterUserConfirmation = props => {
    const crefoPayContext = useContext(CrefoPayContext);
    const userContext = useContext(UserContext);
    const [ registerState ] = useState(true);
    const { translation, history, match: { params: { userId } } } = props;

    useDidMount(() => {
        confirmUser();
    });

    const confirmUser = async () => {
        if (!userId) {
            history.push("/");
            return;
        }

        // confirm user with given token
        const userConfiguration = await userContext.actions.userConfirmation(userId);

        // check if error is returned
        if (userConfiguration.status === 200) {
            // show success notification
            NotificationManager.success(translation.react.validations.accountConfirmed, translation.react.global.success);
        } else {
            // show error notification
            NotificationManager.error(translation.react.validations.accountConfirmationError, translation.react.global.error);
        }

        // redirect to login
        history.push("/");
    };

    return (
        <Fragment>
            <Card className={ `register register--step-3 ${ registerState ? 'register--is-busy' : '' }` }>
                <img className="logo mt-4" src={ Logo } width={ 160 } alt="Mobilistics"/>
                <RegisterSteps step={ 2 }/>
                <h2>{ translation.react.global.pleaseWait } ...</h2>
                { crefoPayContext.confirmationStatus === 200 && (
                    <p className="mb-8">{ translation.react.validations.accountConfirmed }</p>
                ) }
                { crefoPayContext.confirmationStatus !== null && crefoPayContext.confirmationStatus !== 200 && (
                    <p>{ translation.react.validations.accountConfirmationError }</p>
                ) }
                <Spinner className="loader"/>
            </Card>
        </Fragment>
    );
};

export default withTranslation(React.memo(RegisterUserConfirmation), [ 'react' ]);

