import React, { useContext, useState } from "react";
import PropTypes from "prop-types";

import ConfirmationModal from "../../Modal/ConfirmationModal";
import { SnapshotContext } from "../../../Context/Api/Openstack/SnapshotsContextProvider";
import { ModalContext } from "../../../Context/ModalContextProvider";
import { Dropdown } from "../../Styling/Dropdown";
import { NotificationManager } from "react-notifications";

const SnapshotItem = props => {
    const snapshotsContext = useContext(SnapshotContext);
    const modalContext = useContext(ModalContext);
    const { snapshot, translation } = props;
    const [ isBusy, setIsBusy ] = useState(false);

    /**
     * delete snapshot
     * @param snapshotId
     */
    const deleteSnapshot = async snapshotId => {
        if (!snapshotId) return;

        // set busy state to deactivate delete button
        setIsBusy(true);

        try {
            await snapshotsContext.actions.deleteSnapshot(snapshotId, () => {
                snapshotsContext.actions.fetchSnapshots();
            });
            NotificationManager.success(translation.react.snapshot.delete.success, translation.react.global.success);
        } catch (e) {

        }
    };

    /**
     * handle fn for delete btn
     * @param snapshotId
     */
    const handleDeleteSnapshot = snapshotId => {
        modalContext.actions.setModalContent(ConfirmationModal, {
            confirmAction: () => deleteSnapshot(snapshotId),
            title: translation.react.snapshot.delete.title,
            action: translation.react.global.delete,
            cancel: translation.react.global.cancel
        }, { maxWidth: '330px' });
    };

    return (
        <tr>
            <td data-label={ `${ translation.react.global.name }:` }><b>{ snapshot.name }</b></td>
            <td data-label={ `${ translation.react.global.status }:` }>{ snapshot.status }</td>
            <td data-label={ `${ translation.react.global.action }:` } className="ta-r">
                <Dropdown
                    dropdownAlignment="right-desktop"
                    arrowAlignment="right-desktop"
                >
                    <ul>
                        <li>
                            <button disabled={ isBusy } onClick={ () => handleDeleteSnapshot(snapshot.id) } className="btn"><i className="far fa-trash-alt" aria-label="Delete"/> { translation.react.global.delete }</button>
                        </li>
                    </ul>
                </Dropdown>
            </td>
        </tr>
    );
};

SnapshotItem.propTypes = {
    translation: PropTypes.object.isRequired,
    snapshot: PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        status: PropTypes.string.isRequired
    })
};

export default React.memo(SnapshotItem);
