import React, { createRef, useContext, useState } from "react";

import { ModalContext } from "../../Context/ModalContextProvider";
import PropTypes from 'prop-types';

const SelectboxModal = props => {
    const [ canConfirm, setCanConfirm ] = useState(false);
    const modalContext = useContext(ModalContext);
    const { computeId, confirmAction, selectedValue = null, selectBoxData, title, action, cancel } = props;
    const selectFieldRef = createRef();

    // allow confirmation if value has changed and if it's not the selectedValue
    const handleFormChange = () => {
        let selectValue = selectFieldRef.current.value;
        setCanConfirm(selectValue !== selectedValue);
    };

    // allow confirmation if no selectedValue is given
    if (!canConfirm && selectedValue === null) {
        setCanConfirm(true);
    }

    const handleSubmit = event => {
        event.preventDefault();
        let selectValue = selectFieldRef.current.value;
        confirmAction(computeId, selectValue);
        modalContext.actions.closeModal();
    };

    return (
        <div>
            <h2>{ title }</h2>
            <form onSubmit={ handleSubmit }>
                <select id="selectField" name="selectField" className="select" defaultValue={ selectedValue } onChange={ handleFormChange } ref={ selectFieldRef }>
                    { selectBoxData.map(option => <option key={ option.id } value={ option.id }>{ option.name }</option>) }
                </select>
                <div className="btn-group btn-group--full-width">
                    <button disabled={ !canConfirm } type="submit" className="btn btn--big btn--danger mr-3">{ action }</button>
                    <button onClick={ modalContext.actions.closeModal } className="btn btn--big btn--secondary">{ cancel }</button>
                </div>
            </form>
        </div>
    );
}

SelectboxModal.propTypes = {
    confirmAction: PropTypes.func.isRequired,
    computeId: PropTypes.string.isRequired,
    selectBoxData: PropTypes.array.isRequired,
    selectedValue: PropTypes.string,
    title: PropTypes.string.isRequired,
    action: PropTypes.string.isRequired,
    cancel: PropTypes.string.isRequired,
};

export default React.memo(SelectboxModal);
