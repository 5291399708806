import axios from "axios";
import API_CONST from "../constants";

/**
 * fetch Volumes
 *
 * @returns {AxiosPromise<any>}
 */
const fetchVolumes = () => {
    return axios.get(API_CONST.VOLUME_URL);
};

/**
 * Create a Volume
 *
 * @param data
 * @returns {AxiosPromise<any>}
 */
const createNewVolume = data => {
    if (!data) return;
    return axios.post(`${ API_CONST.VOLUME_URL }`, data);
};

/**
 * Resize a volume
 *
 * @param data
 * @returns {AxiosPromise<any>}
 */
const resizeVolume = data => {
    if (!data) return;
    return axios.post(`${ API_CONST.VOLUME_URL }/${ data.id }/resize`, data);
};

/**
 * Delete a volume
 *
 * @param volumeId
 * @returns {AxiosPromise}
 */
const deleteVolume = volumeId => {
    return axios.delete(`${ API_CONST.VOLUME_URL }/${ volumeId }`);
};

export {
    fetchVolumes,
    createNewVolume,
    resizeVolume,
    deleteVolume
}
