import React, { Component, createContext } from "react";

import API from "../../../Api/Openstack";
import isEqual from "../../../Ultils/isEqual";
import { NotificationManager } from "react-notifications";

export const TranslationContext = createContext({});

class TranslationContextProvider extends Component {
    // default state
    state = {
        translation: {},
        fetchError: false,
    };

    /**
     * @param translationId
     */
    getTranslationWithId = translationId => {
        return API.fetchTranslationsWithId(translationId)
            .then(({ data }) => {
                if (!isEqual(this.state.translation, data)) {
                    this.setState({
                        translation: data
                    })
                }
            })
            .catch(error => {
                this.setState({ fetchError: true });
                NotificationManager.error(error.message, "Error");
                return new Error(error);
            });
    };

    render() {
        return (
            <TranslationContext.Provider value={ {
                ...this.state,
                actions: {
                    getTranslationWithId: this.getTranslationWithId,
                }
            } }>
                { this.props.children }
            </TranslationContext.Provider>
        );
    }
}

export default TranslationContextProvider;
