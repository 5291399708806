import React, { Fragment, useContext, useEffect, useRef } from "react";
import { useDidMount, useWillUnmount } from "react-hooks-lib";

import withTranslation from "../../../Ultils/withTranslation";
import { ComputesContext } from "../../../Context/Api/Openstack/ComputesContextProvider";
import { FlavorsContext } from "../../../Context/Api/Openstack/FlavorsContextProvider";
import { FloatsContext } from "../../../Context/Api/Openstack/FloatingsContextProvider";
import { VolumesContext } from "../../../Context/Api/Openstack/VolumesContextProvider";
import { BillingContext } from "../../../Context/Api/Openstack/BillingContextProvider";
import { SecurityGroupsContext } from "../../../Context/Api/Openstack/SecurityGroupsContextProvider";
import { Column, Grid } from "../../Styling/Grid";
import { EmptyGroup } from "../../Styling/EmptyGroup";
import ComputeItem from "./ComputeItem";
import ComputeItemSkeleton from "./ComputeItemSkeleton";

const Computes = props => {
    const computesContext = useContext(ComputesContext);
    const flavorsContext = useContext(FlavorsContext);
    const floatingContext = useContext(FloatsContext);
    const volumesContext = useContext(VolumesContext);
    const billingContext = useContext(BillingContext);
    const securityGroupsContext = useContext(SecurityGroupsContext);
    const { translation, history } = props;
    let fetchInterval = useRef();

    useDidMount(() => {
        const fetchData = async () => {
            // fetching data
            await volumesContext.actions.fetchVolumes();
            await flavorsContext.actions.fetchFlavors();
            await floatingContext.actions.fetchFloats();
            await billingContext.actions.fetchPrices();
            await computesContext.actions.fetchComputes();

            // last load securityGroups => performance issues
            await securityGroupsContext.actions.fetchAllSecurityGroups();
        };

        fetchData();
    });

    // clear and reinitialize interval
    useEffect(() => {
        clearInterval(fetchInterval.current);
        fetchInterval.current = setInterval(async () => {
            await computesContext.actions.fetchComputes();
        }, 3000);
    }, [ fetchInterval, computesContext.actions ]);

    // clear and reinitialize interval
    useWillUnmount(() => {
        clearInterval(fetchInterval.current);
    });

    /*
     const helpModal = event => {
     event.preventDefault();
     modalContext.actions.setModalContent(props => (<h1>{ props.headline }</h1>), { headline: 'Willkommen bei der Hilfe' });
     };
     */

    return (
        <Fragment>
            { computesContext.computes.length > 0 && (
                <button className="btn btn--primary float-right clear mb-3" onClick={ () => history.push("/dashboard/computes/new") }>{ translation.react.global.new }</button>
            ) }
            <h2 className="mb-4">{ translation.react.compute.computes }</h2>
            <Grid className="clear">
                { !computesContext.loading && !flavorsContext.loading && !volumesContext.loading && !floatingContext.loading ? (
                    <Fragment>
                        { computesContext.computes.length > 0 ? (
                            <Fragment>
                                { computesContext.computes.map(compute => (
                                    <ComputeItem key={ compute.id } translation={ translation } compute={ compute } history={ history }/>
                                )) }
                            </Fragment>
                        ) : (
                            <Column xs={ 12 }>
                                <EmptyGroup>
                                    <svg className="svg-inline--fa fa-server fa-w-16 fa-3x" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="server" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg="">
                                        <path fill="currentColor" d="M480 160H32c-17.673 0-32-14.327-32-32V64c0-17.673 14.327-32 32-32h448c17.673 0 32 14.327 32 32v64c0 17.673-14.327 32-32 32zm-48-88c-13.255 0-24 10.745-24 24s10.745 24 24 24 24-10.745 24-24-10.745-24-24-24zm-64 0c-13.255 0-24 10.745-24 24s10.745 24 24 24 24-10.745 24-24-10.745-24-24-24zm112 248H32c-17.673 0-32-14.327-32-32v-64c0-17.673 14.327-32 32-32h448c17.673 0 32 14.327 32 32v64c0 17.673-14.327 32-32 32zm-48-88c-13.255 0-24 10.745-24 24s10.745 24 24 24 24-10.745 24-24-10.745-24-24-24zm-64 0c-13.255 0-24 10.745-24 24s10.745 24 24 24 24-10.745 24-24-10.745-24-24-24zm112 248H32c-17.673 0-32-14.327-32-32v-64c0-17.673 14.327-32 32-32h448c17.673 0 32 14.327 32 32v64c0 17.673-14.327 32-32 32zm-48-88c-13.255 0-24 10.745-24 24s10.745 24 24 24 24-10.745 24-24-10.745-24-24-24zm-64 0c-13.255 0-24 10.745-24 24s10.745 24 24 24 24-10.745 24-24-10.745-24-24-24z"></path>
                                    </svg>
                                    <h3 className="mb-0">{ translation.react.compute.empty.title }</h3>
                                    <p>{ translation.react.compute.empty.text }</p>
                                    <button className="btn btn--primary mt-4 mb-0" onClick={ () => history.push("/dashboard/computes/new") }>{ translation.react.compute.empty.buttontext }</button>
                                </EmptyGroup>
                            </Column>
                        ) }
                    </Fragment>
                ) : (
                    <Fragment>
                        <ComputeItemSkeleton/>
                        <ComputeItemSkeleton/>
                    </Fragment>
                ) }
            </Grid>

            { /*<a href="#root" className="float-right clear mb-4" onClick={ helpModal }><i className="fas fa-question-circle" aria-label="Hilfe"/> Hilfe anzeigen</a>*/ }
        </Fragment>
    );
};

export default React.memo(withTranslation(Computes, [ 'react' ]));
