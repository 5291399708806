import React, { useContext, useState } from "react";
import PropTypes from 'prop-types';

import ConfirmationModal from "../../Modal/ConfirmationModal";
import { FloatsContext } from "../../../Context/Api/Openstack/FloatingsContextProvider";
import { ModalContext } from "../../../Context/ModalContextProvider";
import { Dropdown } from "../../Styling/Dropdown";
import { NotificationManager } from "react-notifications";

const FloatingItem = props => {
    const floatingContext = useContext(FloatsContext);
    const modalContext = useContext(ModalContext);
    const { float, translation } = props;
    const [ isBusy, setIsBusy ] = useState(false);

    /**
     * @param computeId
     */
    const deleteFloating = floatId => {
        if (!floatId) return;
        // set busy state to deactivate delete button
        setIsBusy(true);

        floatingContext.actions.deleteFloating(floatId, () => {
            floatingContext.actions.fetchFloats();
            NotificationManager.success(translation.react.float.confirm.success, translation.react.global.success);
        });
    };

    /**
     * @param computeId
     */
    const handleDelete = floatId => {
        modalContext.actions.setModalContent(ConfirmationModal, {
            confirmAction: () => deleteFloating(floatId),
            title: translation.react.float.confirm.title,
            action: translation.react.global.delete,
            cancel: translation.react.global.cancel
        }, { maxWidth: '330px' });
    };

    return (
        <tr key={ float.id }>
            <td data-label={ `${ translation.react.global.ip }:` }><b>{ float.floatingIpAddress }</b></td>
            <td data-label={ `${ translation.react.global.server }:` }><b>{ float.compute ? float.compute : translation.react.float.notInUse }</b></td>
            <td data-label={ `${ translation.react.global.action }:` } className="ta-r">
                <Dropdown
                    dropdownAlignment="right-desktop"
                    arrowAlignment="right-desktop"
                >
                    <ul>
                        <li>
                            <button disabled={ isBusy } onClick={ () => handleDelete(float.id) } className="btn"><i className="far fa-trash-alt" aria-label="Delete"/> { translation.react.global.delete }</button>
                        </li>
                    </ul>
                </Dropdown>
            </td>
        </tr>
    );
};

FloatingItem.propTypes = {
    translation: PropTypes.object.isRequired,
    float: PropTypes.shape({
        id: PropTypes.string.isRequired,
        floatingIpAddress: PropTypes.any
    })
};

export default React.memo(FloatingItem);
