const API_URL = process.env.REACT_APP_ROOT_API;

const AUTH_URL = `${ API_URL }/auth/`;
const BILLING_URL = `${ API_URL }/billing`;
const PRICES_URL = `${ API_URL }/billing/prices`;
const COMPUTE_URL = `${ API_URL }/computes`;
const FLAVOR_URL = `${ API_URL }/flavors`;
const FLOATING_URL = `${ API_URL }/floating`;
const IMAGE_URL = `${ API_URL }/images`;
const KEYPAIR_URL = `${ API_URL }/keypairs`;
const LANG_URL = `${ API_URL }/locale/`;
const LOGS_URL = `${ API_URL }/logs/`;
const REGISTER_URL = `${ API_URL }/register`;
const CONFIRM_URL = `${ REGISTER_URL }/confirm/`;
const CHANGEPAYMENT_URL = `${ REGISTER_URL }/crefopay/paymentinstrument`;
const CONFIRMPAYMENT_URL = `${ REGISTER_URL }/crefopay`;
const SNAPSHOT_URL = `${ COMPUTE_URL }/snapshots`;
const SECURITYGROUPS_URL = `${ API_URL }/security_groups`;
const VOLUME_URL = `${ API_URL }/volumes`;
const USER_URL = `${ API_URL }/user`;

export default {
    AUTH_URL,
    BILLING_URL,
    COMPUTE_URL,
    CONFIRM_URL,
    CHANGEPAYMENT_URL,
    CONFIRMPAYMENT_URL,
    FLAVOR_URL,
    FLOATING_URL,
    IMAGE_URL,
    KEYPAIR_URL,
    LANG_URL,
    LOGS_URL,
    PRICES_URL,
    REGISTER_URL,
    SNAPSHOT_URL,
    SECURITYGROUPS_URL,
    USER_URL,
    VOLUME_URL,
};
