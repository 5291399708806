import React, { Component, createContext } from "react";

import API from "../../../Api/Openstack";
import { ErrorContext } from "../../ErrorContextProvider";
import isEqual from "../../../Ultils/isEqual";

export const SecurityGroupsContext = createContext({});

class SecurityGroupsContextProvider extends Component {
    static contextType = ErrorContext;

    // default securitygroups state
    state = {
        securitygroups: [],
        loading: true,
    };

    /**
     * fetchAllSecurityGroups
     */
    fetchAllSecurityGroups = () => {
        return API.getAllSecurityGroups()
            .then(({ data }) => {
                // check if loading is true => set it to false
                if (this.state.loading) {
                    this.setState({ loading: false });
                }
                if (!isEqual(this.state.securitygroups, Object.values(data))) {
                    this.setState({ securitygroups: Object.values(data) });
                }
            })
            .catch(error => {
                // check if loading is true => set it to false
                if (this.state.loading) {
                    this.setState({ loading: false });
                }
                this.context.actions.showError(error);
                throw new Error(error);
            });
    };

    /**
     * deleteSecurityGroup
     *
     * @param securityGroupId
     * @param callbackFn
     */
    deleteSecurityGroup = (securityGroupId, callbackFn = () => {
    }) => {
        if (!securityGroupId) return;
        return API.deleteSecurityGroup(securityGroupId)
            .then(() => {
                const newSecurityGroupsArray = this.state.securitygroups.filter(securitygroup => securitygroup.id !== securityGroupId);
                if (!isEqual(this.state.securitygroups, newSecurityGroupsArray)) {
                    this.setState({ securitygroups: newSecurityGroupsArray });
                }
                callbackFn();
            })
            .catch((error) => {
                callbackFn();
                this.context.actions.showError(error);
                throw new Error(error);
            });
    };

    /**
     * @param data
     * @param callbackFn
     */
    createNewSecurityGroup = (data, callbackFn = () => {
    }) => {
        if (!data) return;
        return API.createNewSecurityGroup(data)
            .then(() => {
                callbackFn();
            })
            .catch(error => {
                callbackFn();
                throw new Error(error);
            });
    };

    /**
     * @param groupId
     */
    getSecurityGroupNameById = groupId => {
        const securityGroup = this.state.securitygroups.find(securityGroup => securityGroup.id === groupId);
        if (!securityGroup) {
            return "";
        }
        return `"${ securityGroup.name }"`;
    };

    render() {
        return (
            <SecurityGroupsContext.Provider value={ {
                ...this.state,
                actions: {
                    fetchAllSecurityGroups: this.fetchAllSecurityGroups,
                    getSecurityGroupNameById: this.getSecurityGroupNameById,
                    deleteSecurityGroup: this.deleteSecurityGroup,
                    createNewSecurityGroup: this.createNewSecurityGroup,
                }
            } }>
                { this.props.children }
            </SecurityGroupsContext.Provider>
        );
    }
}

export default SecurityGroupsContextProvider;
