import React, { Fragment, useContext, useState } from 'react';
import { useDidMount } from "react-hooks-lib";
import Skeleton from "react-loading-skeleton";

import withTranslation from "../../../Ultils/withTranslation";
import { KeypairsContext } from "../../../Context/Api/Openstack/KeypairsContextProvider";
import KeypairItem from "./KeypairItem";
import { ModalContext } from "../../../Context/ModalContextProvider";
import NewKeypairModal from "./Modal/NewKeypairModal";
import EmptyKeyPairs from "./EmptyKeyPairs";

const Keypairs = props => {
    const keypairsContext = useContext(KeypairsContext);
    const modalContext = useContext(ModalContext);
    const { translation } = props;
    const [ isBusy ] = useState(false);

    useDidMount(() => {
        keypairsContext.actions.fetchKeypairs();
    });

    const handleRequestNewKeypair = () => {
        modalContext.actions.setModalContent(NewKeypairModal, {}, { maxWidth: '400px' });
    };
    return (
        <Fragment>
            { keypairsContext.keypairs.length > 0 && (
                <button disabled={ isBusy } className="btn btn--primary float-right" onClick={ handleRequestNewKeypair }>{ translation.react.global.new }</button>
            ) }
            <h2 className="mb-4">{ translation.react.keypair.keypairs }</h2>
            { !keypairsContext.loading ? (
                <Fragment>
                    { keypairsContext.keypairs.length > 0 ? (
                        <table className="table table--striped table--bordered">
                            <thead>
                            <tr>
                                <th scope="col">{ translation.react.global.name }</th>
                                <th scope="col" width={ 400 }>{ translation.react.keypair.fingerprint }</th>
                                <th scope="col" width={ 60 }></th>
                            </tr>
                            </thead>
                            <tbody>
                            { keypairsContext.keypairs.length > 0 && keypairsContext.keypairs.map(keypair => (
                                <KeypairItem key={ keypair.id } keypair={ keypair } translation={ translation }/>
                            )) }
                            </tbody>
                        </table>
                    ) : (
                        <EmptyKeyPairs handleClickFn={ handleRequestNewKeypair }/>
                    ) }
                </Fragment>
            ) : (
                <table className="table table--striped table--bordered">
                    <thead>
                    <tr>
                        <th scope="col">{ translation.react.global.name }</th>
                        <th scope="col" width={ 400 }>{ translation.react.keypair.fingerprint }</th>
                        <th scope="col" width={ 60 }></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td><Skeleton/></td>
                        <td><Skeleton/></td>
                        <td><Skeleton/></td>
                    </tr>
                    </tbody>
                </table>
            ) }
        </Fragment>
    );
};

export default withTranslation(Keypairs, [ 'react' ]);
