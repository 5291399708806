import React, { Component, createContext } from "react";

import API from "../../../Api/Openstack";
import { ErrorContext } from "../../ErrorContextProvider";
import isEqual from "../../../Ultils/isEqual";

export const ComputesContext = createContext({});

class ComputesContextProvider extends Component {
    static contextType = ErrorContext;

    state = {
        computes: [],
        securityGroups: [],
        loading: true,
    };

    /**
     * @param computeData
     */
    createCompute = computeData => {
        if (!computeData) return;
        return API.createCompute(computeData)
            .catch(error => {
                this.context.actions.showError(error);
                throw new Error(error);
            });
    };

    /**
     * fetchComputes
     */
    fetchComputes = () => {
        return API.fetchComputes()
            .then(({ data }) => {
                // check if loading is true => set it to false
                if (this.state.loading) {
                    this.setState({ loading: false });
                }

                if (!isEqual(this.state.computes, Object.values(data))) {
                    this.setState({ computes: Object.values(data) });
                }
            })
            .catch(error => {
                // check if loading is true => set it to false
                if (this.state.loading) {
                    this.setState({ loading: false });
                }

                this.context.actions.showError(error);
                return new Error(error);
            });
    };

    /**
     * @param computeId
     * @param callbackFn
     */
    startCompute = (computeId, callbackFn = () => {
    }) => {
        if (!computeId) return;

        return API.startCompute(computeId)
            .then(() => {
                this.setServerWithIdToStatus(computeId, "ACTIVE");
                callbackFn();
            })
            .catch((error) => {
                callbackFn();
                this.context.actions.showError(error);
                throw new Error(error);
            });
    };

    /**
     * @param computeId
     * @param callbackFn
     */
    shutdownCompute = (computeId, callbackFn = () => {
    }) => {
        if (!computeId) return;
        return API.shutdownCompute(computeId)
            .then(() => {
                this.setServerWithIdToStatus(computeId, "SHUTOFF");
                callbackFn();
            })
            .catch((error) => {
                callbackFn();
                this.context.actions.showError(error);
                throw new Error(error);
            });
    };

    /**
     * @param computeId
     * @param callbackFn
     */
    deleteCompute = (computeId, callbackFn = () => {
    }) => {
        if (!computeId) return;

        return API.deleteCompute(computeId)
            .then(() => {
                const newComputeArray = this.state.computes.filter(compute => compute.id !== computeId);
                this.setState({ computes: newComputeArray });
            })
            .catch((error) => {
                callbackFn();
                this.context.actions.showError(error);
                throw new Error(error);
            });
    };

    /**
     *
     * @param computeId
     * @param data
     * @param callbackFn
     */
    resizeFlavor = (computeId, data, callbackFn = () => {
    }) => {
        if (!computeId && data) return;
        return API.resizeComputeFlavor(computeId, data)
            .then(() => {
                this.setServerWithIdToStatus(computeId, "RESIZE");
                callbackFn();
            })
            .catch((error) => {
                callbackFn();
                this.context.actions.showError(error);
                throw new Error(error);
            });
    };

    /**
     *
     * @param data
     * @param callbackFn
     */
    confirmResizeFlavor = (data, callbackFn = () => {
    }) => {
        if (!data) return;
        return API.confirmResize(data)
            .then(() => {
                callbackFn();
            })
            .catch((error) => {
                callbackFn();
                this.context.actions.showError(error);
                throw new Error(error);
            });
    };

    /**
     *
     * @param data
     * @param callbackFn
     */
    resetResizeFlavor = (data, callbackFn = () => {
    }) => {
        if (!data) return;
        return API.resetResize(data)
            .then(() => {
                callbackFn();
            })
            .catch((error) => {
                callbackFn();
                this.context.actions.showError(error);
                throw new Error(error);
            });
    };

    /**
     * @param computeId
     * @param status
     */
    setServerWithIdToStatus = (computeId, status) => {
        if (!computeId || !status) return;

        let computesArray = [ ...this.state.computes ];
        let computeIndex = this.state.computes.findIndex(compute => compute.id === computeId);

        computesArray[ computeIndex ].status = status;

        // check if new data is not already set to state ==> prevent rerender
        this.setState({ computes: [ ...computesArray ] });
    };

    getServerNameFromId = computeId => {
        if (!computeId) return;
        let computeResult = this.state.computes.find(compute => compute.id === computeId);

        if (computeResult) {
            return computeResult.name;
        }
    };

    /**
     *
     * @param data
     * @param callbackFn
     * @returns {Q.Promise<any> | Promise<T>}
     */
    attachVolume = (data, callbackFn = () => {
    }) => {
        if (!data) return;
        return API.attachVolume(data)
            .then(() => {
                callbackFn();
            })
            .catch((error) => {
                callbackFn();
                this.context.actions.showError(error);
                return new Error(error);
            });
    };

    detachVolume = (data, callbackFn = () => {
    }) => {
        if (!data) return;
        return API.detachVolume(data)
            .then(() => {
                callbackFn();
            })
            .catch((error) => {
                this.context.actions.showError(error);
                return new Error(error);
            });
    };

    getAttachedSecurityGroupsFromCompute = computeId => {
        if (!computeId) return;

        return API.getAttachedSecurityGroups(computeId)
            .then(({ data }) => {
                // check if loading is true => set it to false
                if (this.state.loading) {
                    this.setState({ loading: false });
                }

                this.setState({ securityGroups: Object.values(data) });
            })
            .catch(error => {
                // check if loading is true => set it to false
                if (this.state.loading) {
                    this.setState({ loading: false });
                }

                this.context.actions.showError(error);
                throw new Error(error);
            });
    };

    setSecurityGroupsToCompute = (computeId, data, callbackFn = () => {
    }) => {
        if (!computeId || !data) return;

        return API.setSecurityGroupsToCompute(computeId, data)
            .then(() => {
                callbackFn();
            })
            .catch((error) => {
                callbackFn();
                this.context.actions.showError(error);
                return new Error(error)
            });
    };

    checkIfGroupIsActive = groupId => {
        if (!groupId) return;
        let groupResult = this.state.securityGroups.find(group => group.id === groupId);

        if (groupResult) {
            return true;
        }
    };

    render() {
        return (
            <ComputesContext.Provider value={ {
                ...this.state,
                actions: {
                    fetchComputes: this.fetchComputes,
                    startCompute: this.startCompute,
                    shutdownCompute: this.shutdownCompute,
                    createCompute: this.createCompute,
                    deleteCompute: this.deleteCompute,
                    resizeFlavor: this.resizeFlavor,
                    confirmResizeFlavor: this.confirmResizeFlavor,
                    resetResizeFlavor: this.resetResizeFlavor,
                    attachVolume: this.attachVolume,
                    detachVolume: this.detachVolume,
                    getServerNameFromId: this.getServerNameFromId,
                    getAttachedSecurityGroupsFromCompute: this.getAttachedSecurityGroupsFromCompute,
                    setSecurityGroupsToCompute: this.setSecurityGroupsToCompute,
                    checkIfGroupIsActive: this.checkIfGroupIsActive,
                }
            } }>
                { this.props.children }
            </ComputesContext.Provider>
        );
    }
}

export default ComputesContextProvider;
