import axios from "axios";
import API_CONST from "../constants";

/**
 * get logged in User
 *
 * @returns {AxiosPromise<any>}
 */
const getUser = () => {
    return axios.get(API_CONST.USER_URL);
};

/**
 *
 * @param userData
 * @returns {AxiosPromise<any>}
 */
const updateUser = userData => {
    return axios.post(API_CONST.USER_URL, { ...userData });
};

const registerUser = (formData, recaptchaCode) => {
    return axios.post(`${ API_CONST.REGISTER_URL }/?g-recaptcha-response=${ recaptchaCode }`, { ...formData });
};

const confirmUser = userId => {
    return axios.get(`${ API_CONST.CONFIRM_URL }${ userId }`);
};

const confirmPayment = paymentData => {
    return axios.post(`${ API_CONST.CONFIRMPAYMENT_URL }`, { ...paymentData });
};

const changePayment = () => {
    return axios.get(`${ API_CONST.CHANGEPAYMENT_URL }`);
};

export {
    getUser,
    updateUser,
    confirmUser,
    changePayment,
    confirmPayment,
    registerUser,
}
