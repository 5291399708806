import React, { useState } from "react";
import PropTypes from "prop-types";
import { DropdownHandler, DropdownHeader } from "@reactway/dropdown";

import { DropdownContent } from "./DropdownContent";

export const Dropdown = React.memo(props => {
    const {
        // "left", "right" or "center"
        arrowAlignment = "left",
        // "left", "right" or "center"
        dropdownAlignment = "left",
        Header,
        HeaderClassName,
        className,
        children,
        ...rest
    } = props;

    // state for Dropdown
    const [ dropdownOpen, setDropdownOpen ] = useState(false);

    return (
        <DropdownHandler className={ `dropdown-wrapper ${ className ? className : '' }` } isOpen={ dropdownOpen } onToggle={ () => setDropdownOpen(!dropdownOpen) } { ...rest }>
            <DropdownHeader className={ `${ HeaderClassName ? HeaderClassName : '' }` }>
                { Header ? (
                    <Header/>
                ) : (
                    <button className="btn compute-card__settings-toggle">
                        { dropdownOpen ? (
                            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="chevron-up" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" className="svg-inline--fa fa-chevron-up fa-w-16">
                                <path fill="currentColor" d="M240.971 130.524l194.343 194.343c9.373 9.373 9.373 24.569 0 33.941l-22.667 22.667c-9.357 9.357-24.522 9.375-33.901.04L224 227.495 69.255 381.516c-9.379 9.335-24.544 9.317-33.901-.04l-22.667-22.667c-9.373-9.373-9.373-24.569 0-33.941L207.03 130.525c9.372-9.373 24.568-9.373 33.941-.001z" className=""></path>
                            </svg>
                        ) : (
                            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="chevron-down" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" className="svg-inline--fa fa-chevron-down fa-w-16">
                                <path fill="currentColor" d="M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z" className=""></path>
                            </svg>
                        ) }
                    </button>
                ) }
            </DropdownHeader>
            <DropdownContent className={ `dropdown dropdown--${ dropdownAlignment } dropdown--arrow-${ arrowAlignment }` }>
                <div className="dropdown__content">
                    { children }
                </div>
            </DropdownContent>
        </DropdownHandler>
    );
});

Dropdown.propTypes = {
    arrowAlignment: PropTypes.string,
    dropdownAlignment: PropTypes.string,
    Header: PropTypes.func,
    HeaderClassName: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.object,
    defaultIsOpen: PropTypes.bool,
    isOpen: PropTypes.bool,
    onToggle: PropTypes.func,
    disabled: PropTypes.bool,
    toggleOnHeaderClick: PropTypes.bool,
    closeOnSectionClick: PropTypes.bool,
    closeOnOutsideClick: PropTypes.bool,
    closeOnEscapeClick: PropTypes.bool,
};
