/**
 * Helper function to scroll smooth to top
 *
 * @param element
 * @param topPosition
 * @param leftPosition
 */
const scrollToTop = (element = document.querySelector('.page'), topPosition = 0, leftPosition = 0) => {
    // check if browser is firefox => change normal behavior
    const isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
    element.scrollTo({ top: topPosition, left: leftPosition, behavior: !isFirefox ? "smooth" : "auto" });
};

export default scrollToTop;
