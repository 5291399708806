import React, { Fragment, useState, useContext } from "react";
import { Link, Redirect } from "react-router-dom";
import { useDidMount, useWillUnmount } from "react-hooks-lib";
import { useForm } from "react-hook-form";

import withTranslation from "../../../Ultils/withTranslation";
import { AuthContext } from "../../../Context/Api/Openstack/AuthContextProvider";
import { ErrorContext } from "../../../Context/ErrorContextProvider";
import { Card } from "../../Styling/Card";

import Logo from "../../../Assets/Images/mobi-logo-login.svg";
import { Spinner } from "../../Styling/Loading";

const Login = props => {
    const authContext = useContext(AuthContext);
    const errorContext = useContext(ErrorContext);
    const { translation, location } = props;

    const { register, handleSubmit, errors, formState } = useForm();
    const [ loginState, setLoginState ] = useState(false);
    const redirectToPath = location.state ? location.state.from.pathname : '/dashboard/computes';

    // get login from sessionStorage
    useDidMount(() => {
        authContext.actions.clearError();
        authContext.actions.getAuthenticationFromSession();
    });

    useWillUnmount(() => resetForm());

    const handleOnSubmit = loginFormData => {
        // check if errors happen or if formState is valid to submit
        if (Object.keys(errors).length < 1 || formState.isValid) {
            // clear error in errorContext
            authContext.actions.clearError();

            // set loginState
            setLoginState(true);

            // call auth method
            authContext.actions.authenticate(loginFormData);
        } else {
            setLoginState(false);
        }
    };

    const resetForm = () => {
        setLoginState(false);
    };

    if (authContext.hasError && loginState) {
        setLoginState(false);
    }

    return (
        <Fragment>
            { !authContext.loggedIn ? (
                <Card className={ `login ${ loginState ? 'login--is-busy' : '' }` }>
                    <img className="logo mt-4" src={ Logo } width={ 160 } alt="Mobilistics"/>
                    { authContext.hasError && (
                        <ul>
                            { errorContext.actions.getTranslatedErrorMessagesByError(authContext.error).map((errorMessage, index) => (
                                <li key={ index } className="form-error">{ errorMessage }</li>
                            )) }
                        </ul>
                    ) }
                    <form onSubmit={ handleSubmit(handleOnSubmit) }>
                        <div className={ `form-group${ errors.username && (' form-group--error') }` }>
                            <label htmlFor="username">{ translation.react.login.customerid }</label>
                            <input type="text" className="form-control" id="username" name="username" placeholder={ translation.react.login.placeholder } ref={ register({ required: translation.react.validations.required }) }/>
                            { errors.username && (
                                <div className="form-group-error">{ errors.username.message }</div>
                            ) }
                        </div>
                        <div className={ `form-group${ errors.password && (' form-group--error') }` }>
                            <label htmlFor="password">{ translation.react.global.password }</label>
                            <input type="password" className="form-control" id="password" name="password" placeholder="******" ref={ register({ required: translation.react.validations.required }) }/>
                            { errors.password && (
                                <div className="form-group-error">{ errors.password.message }</div>
                            ) }
                        </div>
                        <button disabled={ Object.keys(errors).length > 0 } type="submit" className="btn btn--primary btn--big">{ translation.react.global.login }</button>
                        <Link to="/register" className="btn btn--secondary btn--big">{ translation.react.global.register }</Link>
                    </form>
                    <Spinner className="loader"/>
                </Card>
            ) : <Redirect to={ redirectToPath }/> }
        </Fragment>
    );
};

export default withTranslation(Login, [ 'react' ]);

