import axios from "axios";
import API_CONST from "../constants";

/**
 * fetch Prices
 *
 * @returns {AxiosPromise<any>}
 */
const fetchPrices = () => {
    return axios.get(API_CONST.PRICES_URL);
};

/**
 * fetch Billings
 *
 * @returns {AxiosPromise<any>}
 */
const fetchBillings = () => {
    return axios.get(`${ API_CONST.BILLING_URL }/list`);
};

/**
 * fetch interim Billings
 *
 * @returns {AxiosPromise<any>}
 */
const fetchInterimBillings = () => {
    return axios.get(`${ API_CONST.BILLING_URL }/interim`);
};

/**
 * get billing as pdf download
 *
 * @param billingNumber
 * @returns {AxiosPromise<any>}
 */
const getBillingAsPdf = billingNumber => {
    return axios.get(`${ API_CONST.BILLING_URL }/${ billingNumber }`, { responseType: 'blob' });
};

export {
    fetchPrices,
    fetchBillings,
    fetchInterimBillings,
    getBillingAsPdf,
}
