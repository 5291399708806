import React, { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import PropTypes from "prop-types";

import { ModalContext } from "../../../../Context/ModalContextProvider";
import { no_start_space_alpha } from "../../../../Ultils/RegexPatterns";
import withTranslation from "../../../../Ultils/withTranslation";
import { Column, Grid } from "../../../Styling/Grid";
import { RadioCard } from "../../../Styling/Card";
import { BillingContext } from "../../../../Context/Api/Openstack/BillingContextProvider";
import { useDidMount } from "react-hooks-lib";
import { NotificationManager } from "react-notifications";

//@todo move volume in a own component to reuse it
const VolumeSizes = [ 50, 100, 250, 500, 1000, 2000 ];

const NewVolumeModal = props => {
    const [ loading, setLoading ] = useState(true);
    const modalContext = useContext(ModalContext);
    const { volumes } = props;
    const { handleSubmit, errors, register, formState, getValues } = useForm();
    const { translation } = props;

    const billingContext = useContext(BillingContext);
    const [ hddSize, setHddSize ] = useState(10);
    const [ hddTypeKey, setHddTypeKey ] = useState('VolumeSSD');

    useDidMount(() => {
        // load intial fresh data before the form appears
        const loadFormInitialData = async () => {
            await billingContext.actions.fetchPrices();
            setLoading(false);
        };
        loadFormInitialData();
    });

    const handleOnSubmit = newVolumeFormData => {
        // check if errors happen or if formState is valid to submit
        if (Object.keys(errors).length < 1 || formState.isValid) {
            setLoading(true);
            let size = parseInt(newVolumeFormData.customSize) > 0 && !newVolumeFormData.size ? parseInt(newVolumeFormData.customSize) : parseInt(newVolumeFormData.size);
            let data = { name: newVolumeFormData.name, size: size, type: newVolumeFormData.type }; //Convert to Object for REST-Api
            volumes.actions.createNewVolume(data, async () => {
                setTimeout(() => {
                    volumes.actions.fetchVolumes(); //@todo Basti: Ich glaube das geht auch eleganter
                    setLoading(false);
                    modalContext.actions.closeModal();
                    NotificationManager.success(translation.react.volume.new.success, translation.react.global.success);
                }, 5000);
            });
        }
    };

    const handleChangeHddType = () => {
        const HddTypeKey = `Volume${ getValues()[ 'type' ].toUpperCase() }`;
        setHddTypeKey(HddTypeKey);
    };

    const handleChangeHddSize = e => {
        const newSize = parseInt(e.target.value);
        if (!newSize || newSize < 10) {
            setHddSize(10);
            return;
        }

        setHddSize(newSize);
    };

    return (
        <div className="form-group">
            <h2>{ translation.react.volume.new.title }</h2>
            { loading ? (
                <h3>{ translation.react.global.loading } ...</h3>
            ) : (
                <form onSubmit={ handleSubmit(handleOnSubmit) }>
                    <fieldset className="mb-5 form-group">
                        <div className={ `form-group${ errors.name && (' form-group--error') }` }>
                            <label className="h3-style" htmlFor="name">{ translation.react.global.name }*</label>
                            <input type="text" id="name" name="name" ref={ register({
                                required: translation.react.validations.required,
                                pattern: {
                                    value: no_start_space_alpha,
                                    message: translation.react.validations.spaces
                                }
                            }) }/>
                            { errors.name && (
                                <div className="form-group-error">{ errors.name.message }</div>
                            ) }
                        </div>
                    </fieldset>
                    <fieldset className="mb-5 form-group">
                        <h3>Type</h3>
                        <Grid>
                            <Column xs={ 6 } md={ 3 } xxl={ 4 }>
                                <RadioCard
                                    id="ssd"
                                    name="type"
                                    defaultChecked={ true }
                                    value="ssd"
                                    onChange={ handleChangeHddType }
                                    radioRef={ register }
                                >
                                    <div className="radio-card__content">
                                        <svg aria-hidden="true" focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" className="svg-inline--fa fa-w-32 fa-sm">
                                            <path fill="currentColor" d="M567.403 235.642L462.323 84.589A48 48 0 0 0 422.919 64H153.081a48 48 0 0 0-39.404 20.589L8.597 235.642A48.001 48.001 0 0 0 0 263.054V400c0 26.51 21.49 48 48 48h480c26.51 0 48-21.49 48-48V263.054c0-9.801-3-19.366-8.597-27.412zM153.081 112h269.838l77.913 112H75.168l77.913-112zM528 400H48V272h480v128zm-32-64c0 17.673-14.327 32-32 32s-32-14.327-32-32 14.327-32 32-32 32 14.327 32 32zm-96 0c0 17.673-14.327 32-32 32s-32-14.327-32-32 14.327-32 32-32 32 14.327 32 32z" className=""></path>
                                        </svg>
                                        &nbsp;SSD
                                    </div>
                                </RadioCard>
                            </Column>
                            <Column xs={ 6 } md={ 3 } xxl={ 4 }>
                                <RadioCard
                                    id="hdd"
                                    name="type"
                                    value="hdd"
                                    onChange={ handleChangeHddType }
                                    radioRef={ register }
                                >
                                    <div className="radio-card__content">
                                        <svg aria-hidden="true" focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" className="svg-inline--fa fa-w-32 fa-sm">
                                            <path fill="currentColor" d="M567.403 235.642L462.323 84.589A48 48 0 0 0 422.919 64H153.081a48 48 0 0 0-39.404 20.589L8.597 235.642A48.001 48.001 0 0 0 0 263.054V400c0 26.51 21.49 48 48 48h480c26.51 0 48-21.49 48-48V263.054c0-9.801-3-19.366-8.597-27.412zM153.081 112h269.838l77.913 112H75.168l77.913-112zM528 400H48V272h480v128zm-32-64c0 17.673-14.327 32-32 32s-32-14.327-32-32 14.327-32 32-32 32 14.327 32 32zm-96 0c0 17.673-14.327 32-32 32s-32-14.327-32-32 14.327-32 32-32 32 14.327 32 32z" className=""></path>
                                        </svg>
                                        &nbsp;HDD
                                    </div>
                                </RadioCard>
                            </Column>
                        </Grid>
                    </fieldset>

                    <fieldset className="mb-5 form-group">
                        <h3>{ translation.react.compute.create.disksize }</h3>
                        <Grid>
                            <Column xs={ 6 } md={ 4 } xl={ 3 } xxl={ 4 }>
                                <RadioCard
                                    id="size"
                                    name="size"
                                >
                                    <div className="radio-card__header">
                                        <h3 className="flavor-price mb-0">
                                            { billingContext.actions.getPricingPerMonth(billingContext.prices[ hddTypeKey ], hddSize) }
                                            <small> € / { translation.react.global.month }</small>
                                        </h3>
                                    </div>
                                    <div className="radio-card__content">
                                        <span className="flavor-price flavor-price--input mb-0">
                                            <input id={ `customgb` } type="number" name="customSize" onChange={ handleChangeHddSize } defaultValue={ 10 } min={ 10 } step={ 10 } max={ 2000 } ref={ register }/>
                                            <small> GB</small>
                                        </span>
                                    </div>
                                </RadioCard>
                            </Column>
                            { VolumeSizes.map(volumeSize => (
                                <Column key={ `${ volumeSize }gb` } xs={ 6 } md={ 4 } xl={ 3 } xxl={ 4 }>
                                    <RadioCard
                                        id={ `${ volumeSize }gb` }
                                        name="size"
                                        defaultChecked={ volumeSize === 100 }
                                        value={ volumeSize }
                                        radioRef={ register }
                                    >
                                        <div className="radio-card__header">
                                            <h3 className="flavor-price mb-0">
                                                { billingContext.actions.getPricingPerMonth(billingContext.prices[ hddTypeKey ], volumeSize) }
                                                <small> € / { translation.react.global.month }</small>
                                            </h3>
                                        </div>
                                        <div className="radio-card__content">
                                            <span className="flavor-price mb-0">
                                                { volumeSize }
                                                <small> GB</small>
                                            </span>
                                        </div>
                                    </RadioCard>
                                </Column>
                            )) }
                        </Grid>
                    </fieldset>
                    <button disabled={ Object.keys(errors).length > 0 } type="submit" className="btn btn--big btn--danger mr-3">{ translation.react.global.save }</button>
                    <button type="button" className="btn btn--secondary btn--big" onClick={ () => modalContext.actions.closeModal() }>{ translation.react.global.cancel }</button>
                </form>
            ) }
        </div>
    );
};

NewVolumeModal.propTypes = {
    volumes: PropTypes.shape({
        actions: PropTypes.object.isRequired
    }).isRequired
};

export default withTranslation(NewVolumeModal, [ 'react' ]);
