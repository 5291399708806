import React, { Component, createContext } from "react";

export const ModalContext = createContext({});

const defaultModalContentStyles = {
    top: '120px',
    left: '50%',
    right: '50%',
    bottom: 'auto',
    height: 'auto',
    width: '80vw',
    maxWidth: '1100px',
    marginRight: '-50%',
    marginBottom: '120px',
    transform: 'translate(-50%, 0)',
    zIndex: '100',
};

class ModalContextProvider extends Component {
    // default modal default state
    state = {
        isOpen: false,
        modalContentComponent: () => <span/>,
        modalContentProps: {},
        modalStyles: {
            overlay: {
                backgroundColor: 'rgba(0,0,0,.9)',
                zIndex: 100,
            },
            content: {
                ...defaultModalContentStyles
            }
        }
    };

    // only rerender modalContext if changing isOpen value
    shouldComponentUpdate(children, state, context) {
        return state.isOpen !== this.state.isOpen;
    }

    /**
     * @param Component
     * @param props
     * @param customContentStyles
     */
    setModalContent = (Component, props = {}, customContentStyles = {}) => {
        this.setState({
            isOpen: true,
            modalContentComponent: Component,
            modalContentProps: { ...props },
            modalStyles: {
                ...this.state.modalStyles,
                content: {
                    ...this.state.modalStyles.content,
                    ...customContentStyles,
                }
            }
        });
    };

    closeModal = () => {
        this.setState({
            isOpen: false,
            modalContentComponent: () => <span/>,
            modalContentProps: {},
            modalStyles: {
                ...this.state.modalStyles,
                content: {
                    ...defaultModalContentStyles,
                }
            }
        });
    };

    render() {
        return (
            <ModalContext.Provider value={ {
                ...this.state,
                actions: {
                    setModalContent: this.setModalContent,
                    closeModal: this.closeModal,
                }
            } }>
                { this.props.children }
            </ModalContext.Provider>
        );
    }
}

export default ModalContextProvider;
