import React from "react";
import PropTypes from "prop-types";

export const Spinner = React.memo(props => {
    const { size = 28, color = "#007bff", style, className } = props;

    return (
        <svg width={ size } height={ size } viewBox="-1 -1 40 40" xmlns="http://www.w3.org/2000/svg" stroke="currentColor" className={ `loading-spinner ${ className }` } style={ { ...style } }>
            <g fill="none" fillRule="evenodd">
                <g transform="translate(1 1)" strokeWidth="2">
                    <circle stroke={ color } strokeWidth="4" cx="18" cy="18" r="18" strokeDasharray="90"></circle>
                </g>
            </g>
        </svg>
    );
});

Spinner.propTypes = {
    size: PropTypes.number,
    color: PropTypes.string,
    duration: PropTypes.string,
};
