import React, { Fragment, useContext, useState } from "react";
import PropTypes from 'prop-types';
import { useDidUpdate } from "react-hooks-lib";
import { NotificationManager } from "react-notifications";

import { FlavorsContext } from "../../../Context/Api/Openstack/FlavorsContextProvider";
import { ComputesContext } from "../../../Context/Api/Openstack/ComputesContextProvider";
import { SnapshotContext } from "../../../Context/Api/Openstack/SnapshotsContextProvider";
import { ModalContext } from "../../../Context/ModalContextProvider";
import { FloatsContext } from "../../../Context/Api/Openstack/FloatingsContextProvider";
import { VolumesContext } from "../../../Context/Api/Openstack/VolumesContextProvider";
import { BillingContext } from "../../../Context/Api/Openstack/BillingContextProvider";
import AssignSecurityGroupToComputeModal from "./Modal/AssignSecurityGroupToComputeModal";

import { Column, Grid } from "../../Styling/Grid";
import { Card } from "../../Styling/Card";
import { Dropdown } from "../../Styling/Dropdown";

import SelectboxModal from "../../Modal/SelectboxModal"
import ConfirmationModal from "../../Modal/ConfirmationModal";
import { Spinner } from "../../Styling/Loading";
import { SecurityGroupsContext } from "../../../Context/Api/Openstack/SecurityGroupsContextProvider";
import { ErrorContext } from "../../../Context/ErrorContextProvider";
import ChangeFlavorSizeModal from "./Modal/ChangeFlavorSizeModal";

const ComputeItem = props => {
    const errorContext = useContext(ErrorContext);
    const flavorsContext = useContext(FlavorsContext);
    const computesContext = useContext(ComputesContext);
    const snapshotsContext = useContext(SnapshotContext);
    const modalContext = useContext(ModalContext);
    const floatingContext = useContext(FloatsContext);
    const volumesContext = useContext(VolumesContext);
    const billingContext = useContext(BillingContext);
    const securityGroupsContext = useContext(SecurityGroupsContext);

    // get compute from props
    const { compute, translation } = props;
    const [ isBusy, setIsBusy ] = useState(false);
    const [ dropdownOpen, setDropdownOpen ] = useState(false);
    const computeVolumes = volumesContext.actions.getVolumeForCompute(compute.id);

    /**
     * @param computeId
     */
    const handleDeleteCompute = computeId => {
        if (!computeId) return;
        modalContext.actions.setModalContent(ConfirmationModal, {
            confirmAction: () => deleteCompute(computeId),
            title: translation.react.compute.delete.title,
            description: translation.react.compute.delete.description,
            action: translation.react.global.delete,
            cancel: translation.react.global.cancel
        }, { maxWidth: '400px' });
    };

    /**
     * @param computeId
     */
    const handleChangeFlavor = computeId => {
        if (!computeId) return;
        modalContext.actions.setModalContent(ChangeFlavorSizeModal, {
            confirmAction: changeFlavor,
            computeId: computeId,
            selectBoxData: flavorsContext.flavors.filter(flavor => flavor.bookable),
            selectedValue: compute.flavor,
            title: translation.react.flavor.resize.title,
        }, { maxWidth: '860px' });
    };

    /**
     * @param computeId
     */
    const handleConfirmResize = computeId => {
        if (!computeId) return;
        modalContext.actions.setModalContent(ConfirmationModal, {
            confirmAction: () => confirmResize(computeId),
            denyAction: () => resetResize(computeId),
            title: translation.react.compute.resize.title,
            action: translation.react.compute.resize.button,
            cancel: translation.react.global.cancel
        }, { maxWidth: '400px' });
    };

    /**
     * @param computeId
     */
    const handleStartCompute = async computeId => {
        if (!computeId) return;
        setIsBusy(true);
        try {
            await computesContext.actions.startCompute(computeId);
            NotificationManager.success(translation.react.compute.start.success, translation.react.global.success);
        } catch (e) {

        }
    };

    /**
     * @param computeId
     */
    const handleShutdownCompute = async computeId => {
        if (!computeId) return;
        setIsBusy(true);
        try {
            await computesContext.actions.shutdownCompute(computeId);
            NotificationManager.success(translation.react.compute.stop.success, translation.react.global.success);
        } catch (e) {

        }
    };

    /**
     * @param computeId
     */
    const handleCreateSnapshot = async computeId => {
        let price = billingContext.actions.getPricingPerMonth(billingContext.prices[ 'VolumeHDD' ], computeVolumes[ 0 ].size);

        modalContext.actions.setModalContent(ConfirmationModal, {
            confirmAction: () => createSnapshot(computeId),
            title: translation.react.snapshot.create.title,
            description: `${ translation.react.snapshot.create.description } ${ price } EUR / ${ translation.react.global.month }.`,
            action: translation.react.global.order,
            cancel: translation.react.global.cancel
        }, { maxWidth: '600px' });
    };

    /**
     * @param computeId
     */
    const createSnapshot = async computeId => {
        if (!computeId) return;
        setIsBusy(true);

        try {
            await snapshotsContext.actions.createSnapshot(computeId, () => {
                setIsBusy(false);
            });
            NotificationManager.success(translation.react.snapshot.create.success, translation.react.global.success);
        } catch (e) {
            setIsBusy(false);
        }
    };

    /**
     *
     * @param compute
     */
    const handleAssignFloatingToServer = compute => {
        let floatsArray = floatingContext.floats.filter(float => !float.routerID);
        let select_array = [];

        if (compute.floatingIP) {
            select_array.push({ id: 'delete', name: translation.react.float.detach });
        }
        floatsArray.map(float => (
            select_array.push({ id: float.id, name: float.floatingIpAddress })
        ));

        modalContext.actions.setModalContent(SelectboxModal, {
            confirmAction: assignFloatingToServer,
            computeId: compute.id,
            selectBoxData: select_array,
            title: translation.react.float.attach.title,
            action: translation.react.float.attach.button,
            cancel: translation.react.global.cancel
        }, { maxWidth: '500px' });
    };

    const handleAssignSecurityGroupToComputeModal = async computeId => {
        setIsBusy(true);

        try {
            await computesContext.actions.getAttachedSecurityGroupsFromCompute(computeId);
            modalContext.actions.setModalContent(AssignSecurityGroupToComputeModal, { computeId: computeId, securityGroups: securityGroupsContext.securitygroups, activeGroups: computesContext.securityGroups }, { maxWidth: '600px' });
            setIsBusy(false);
        } catch (error) {
            setIsBusy(false);
            errorContext.actions.showError(error);
        }
    };

    /**
     * @param computeId
     */
    const deleteCompute = async computeId => {
        if (!computeId) return;

        // set busy state to deactivate delete button
        setIsBusy(true);

        try {
            await computesContext.actions.deleteCompute(computeId, () => {
                computesContext.actions.fetchComputes();
            });
            NotificationManager.success(translation.react.compute.delete.success, translation.react.global.success);
        } catch (e) {

        }
    };

    /**
     * @param computeId
     * @param flavorId
     */
    const changeFlavor = async (computeId, flavorId) => {
        if (!computeId || !flavorId) return;

        // set busy state to deactivate delete button
        setIsBusy(true);

        let data = { flavor: flavorId }; //Convert to Object for REST-Api

        try {
            await computesContext.actions.resizeFlavor(computeId, data);
            NotificationManager.success(translation.react.compute.flavor.init.success, translation.react.global.success);
        } catch (e) {

        }
        setIsBusy(false);
    };

    /**
     * @param computeId
     */
    const confirmResize = async computeId => {
        if (!computeId) return;

        // set busy state to deactivate delete button
        setIsBusy(true);

        let data = { id: computeId }; //Convert to Object for REST-Api
        try {
            await computesContext.actions.confirmResizeFlavor(data);
            NotificationManager.success(translation.react.compute.flavor.confirm.success, translation.react.global.success);
        } catch (e) {

        }
    };

    /**
     * @param computeId
     */
    const resetResize = async computeId => {
        if (!computeId) return;

        // set busy state to deactivate delete button
        setIsBusy(true);

        let data = { id: computeId }; //Convert to Object for REST-Api
        try {
            await computesContext.actions.resetResizeFlavor(data);
            NotificationManager.success(translation.react.compute.flavor.cancel.success, translation.react.global.success);
        } catch (e) {

        }
    };

    /**
     *
     * @param computeId
     * @param IP
     */
    const assignFloatingToServer = async (computeId, IP) => {
        setIsBusy(true);
        let data = { floating_id: IP, compute_id: computeId }; //Convert to Object for REST-Api
        try {
            await floatingContext.actions.assignFloatingToServer(data, () => {
                floatingContext.actions.fetchFloats(() => setIsBusy(false));
            });
            NotificationManager.success(translation.react.compute.float.success, translation.react.global.success);
        } catch (e) {

        }
    };

    // set busy state => false if the status of compute changes
    useDidUpdate(() => setIsBusy(false), [ compute.status ]);

    const computeFlavor = flavorsContext.actions.getFlavourById(compute.flavor);

    if (!computeFlavor) return;

    return (
        <Column sm={ 6 } xxl={ 4 }>
            <Card className={ `compute-card compute-card--status-${ compute.status.toLowerCase() }` }>
                <div className="compute-card__header mb-4">
                    <span className={ `compute-card__status-led status-led status-led--${ compute.status.toLowerCase() }` }/>
                    <div className="compute-card__title">
                        <svg className="svg-inline--fa fa-server fa-w-16 fa-sm" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="server" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg="">
                            <path fill="currentColor" d="M480 160H32c-17.673 0-32-14.327-32-32V64c0-17.673 14.327-32 32-32h448c17.673 0 32 14.327 32 32v64c0 17.673-14.327 32-32 32zm-48-88c-13.255 0-24 10.745-24 24s10.745 24 24 24 24-10.745 24-24-10.745-24-24-24zm-64 0c-13.255 0-24 10.745-24 24s10.745 24 24 24 24-10.745 24-24-10.745-24-24-24zm112 248H32c-17.673 0-32-14.327-32-32v-64c0-17.673 14.327-32 32-32h448c17.673 0 32 14.327 32 32v64c0 17.673-14.327 32-32 32zm-48-88c-13.255 0-24 10.745-24 24s10.745 24 24 24 24-10.745 24-24-10.745-24-24-24zm-64 0c-13.255 0-24 10.745-24 24s10.745 24 24 24 24-10.745 24-24-10.745-24-24-24zm112 248H32c-17.673 0-32-14.327-32-32v-64c0-17.673 14.327-32 32-32h448c17.673 0 32 14.327 32 32v64c0 17.673-14.327 32-32 32zm-48-88c-13.255 0-24 10.745-24 24s10.745 24 24 24 24-10.745 24-24-10.745-24-24-24zm-64 0c-13.255 0-24 10.745-24 24s10.745 24 24 24 24-10.745 24-24-10.745-24-24-24z"></path>
                        </svg>
                        { compute.name }
                    </div>

                    { compute.status.toLowerCase() === 'verify_resize' && (
                        <div className="dropdown-wrapper compute-card__settings-toggle-wrapper">
                            <button disabled={ isBusy } onClick={ () => handleConfirmResize(compute.id) } className="btn compute-card__settings-toggle">
                                <svg className="svg-inline--fa fa-check fa-w-16" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="check" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                    <path fill="currentColor" d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path>
                                </svg>
                                &nbsp;{ translation.react.global.confirm }
                            </button>
                        </div>
                    ) }

                    { (isBusy || compute.status.toLowerCase() === 'build') && (
                        <div className="compute-card__spinner-wrapper">
                            <Spinner size={ 25 }/>
                        </div>
                    ) }

                    { (compute.status.toLowerCase() === 'active' || compute.status.toLowerCase() === 'shutoff' || compute.status.toLowerCase() === 'resize') && (
                        <Dropdown
                            className="compute-card__settings-toggle-wrapper"
                            isOpen={ dropdownOpen }
                            onToggle={ () => setDropdownOpen(!dropdownOpen) }
                            dropdownAlignment="right"
                            arrowAlignment="right"
                            Header={ () => (
                                <Fragment>
                                    { compute.status.toLowerCase() !== 'error' && (
                                        <button className="btn compute-card__settings-toggle">
                                            { dropdownOpen ? (
                                                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="chevron-up" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" className="svg-inline--fa fa-chevron-up fa-w-16">
                                                    <path fill="currentColor" d="M240.971 130.524l194.343 194.343c9.373 9.373 9.373 24.569 0 33.941l-22.667 22.667c-9.357 9.357-24.522 9.375-33.901.04L224 227.495 69.255 381.516c-9.379 9.335-24.544 9.317-33.901-.04l-22.667-22.667c-9.373-9.373-9.373-24.569 0-33.941L207.03 130.525c9.372-9.373 24.568-9.373 33.941-.001z" className=""></path>
                                                </svg>
                                            ) : (
                                                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="chevron-down" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" className="svg-inline--fa fa-chevron-down fa-w-16">
                                                    <path fill="currentColor" d="M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z" className=""></path>
                                                </svg>
                                            ) }
                                        </button>
                                    ) }
                                </Fragment>
                            ) }
                        >
                            <ul>
                                { compute.status.toLowerCase() === 'shutoff' && (
                                    <li>
                                        <button disabled={ isBusy } onClick={ () => handleStartCompute(compute.id) } className="btn"><i className="fas fa-play" aria-label="Start"/> { translation.react.compute.start.title }</button>
                                    </li>
                                ) }
                                { compute.status.toLowerCase() === 'active' && (
                                    <li>
                                        <button disabled={ isBusy } onClick={ () => handleShutdownCompute(compute.id) } className="btn"><i className="fas fa-power-off" aria-label="Shudown"/> { translation.react.compute.stop.title }</button>
                                    </li>
                                ) }
                                <li>
                                    <button disabled={ isBusy || compute.status.toLowerCase() === 'resize' } onClick={ () => handleCreateSnapshot(compute.id) } className="btn"><i className="fas fa-camera" aria-label="Snapshot"/> { translation.react.compute.snapshot.title }</button>
                                </li>
                                <li>
                                    <button disabled={ isBusy || compute.status.toLowerCase() === 'resize' } onClick={ () => handleChangeFlavor(compute.id) } className="btn"><i className="far fa-save" aria-label="Change Flavor"/> { translation.react.compute.flavor.init.title }</button>
                                </li>
                                <li>
                                    <button disabled={ isBusy || compute.status.toLowerCase() === 'resize' } onClick={ () => handleAssignFloatingToServer(compute) } className="btn"><i className="fas fa-globe" aria-label="Assign Floating"/> { translation.react.compute.float.title }</button>
                                </li>
                                <li>
                                    <button disabled={ securityGroupsContext.securitygroups.length < 1 || isBusy || compute.status.toLowerCase() === 'resize' } onClick={ () => handleAssignSecurityGroupToComputeModal(compute.id) } className="btn"><i className="fas fa-fire-alt" aria-label="Firewall"/> { translation.react.compute.securityGroups.title }</button>
                                </li>
                                <li>
                                    <button disabled={ isBusy || compute.status.toLowerCase() === 'resize' } onClick={ () => handleDeleteCompute(compute.id) } className="btn"><i className="far fa-trash-alt" aria-label="Delete"/> { translation.react.global.delete }</button>
                                </li>
                            </ul>
                        </Dropdown>
                    ) }
                </div>

                <hr className="mb-4"/>

                <div className="compute-card__properties-wrapper">
                    <Grid>
                        <Column className="flex-row">
                            <h4 className="compute-card__properties-title">{ translation.react.global.properties }</h4>
                        </Column>
                        <Column sm={ 6 }>
                            <div className="compute-card__properties">
                                <span className="compute-card__big-text">{ computeFlavor.cpu }</span>
                                <span className="compute-card__subinfo"> vCPU{ computeFlavor.cpu % 2 !== 0 ? '' : 's' }</span>
                            </div>
                        </Column>
                        <Column sm={ 6 }>
                            <div className="compute-card__properties">
                                <span className="compute-card__big-text">{ Math.round(computeFlavor.ram / 1024) }GB</span>
                                <span className="compute-card__subinfo"> RAM</span>
                            </div>
                        </Column>
                    </Grid>
                </div>


                <hr className="mb-4"/>

                <div className="compute-card__properties-wrapper">
                    <Grid>
                        <Column sm={ 6 }>
                            <div>
                                <h4 className="compute-card__properties-title">{ translation.react.global.ips }:</h4>
                                { compute.addresses && Object.values(compute.addresses).map(address => {
                                    return address.map((flavour, index) => {
                                        return <div key={ `${ flavour.addr }${ index }` }>{ flavour.addr } { flavour[ 'OS-EXT-IPS:type' ] === 'fixed' ? ' (' + translation.react.global.internal + ')' : '' }</div>
                                    });
                                }) }
                            </div>
                        </Column>
                        { computeVolumes.length > 0 && (
                            <Column sm={ 6 }>
                                <h4 className="compute-card__properties-title">{ translation.react.global.disks }:</h4>
                                { computeVolumes.map(volume => (
                                    <div key={ volume.id }>
                                        { volume.size }GB
                                        { volume.type && (
                                            <small> { `${ volume.type }`.toUpperCase() }</small>
                                        ) }
                                    </div>
                                )) }
                            </Column>
                        ) }
                    </Grid>
                </div>
            </Card>
        </Column>
    );
};

ComputeItem.propTypes = {
    translation: PropTypes.object.isRequired,
    compute: PropTypes.shape({
        id: PropTypes.string.isRequired,
        addresses: PropTypes.any,
        status: PropTypes.string.isRequired
    })
};

export default React.memo(ComputeItem);
