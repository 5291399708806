import React, { useContext } from "react";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";

import { ModalContext } from "../../../../../Context/ModalContextProvider";
import { SecurityGroupRulesContext } from "../../../../../Context/Api/Openstack/SecurityGroupRulesContextProvider";
import { ip, port } from "../../../../../Ultils/RegexPatterns";
import withTranslation from "../../../../../Ultils/withTranslation";
import { NotificationManager } from "react-notifications";

const NewSecurityGroupRuleModal = props => {
    const modalContext = useContext(ModalContext);
    const securityGroupRulesContext = useContext(SecurityGroupRulesContext);
    const { handleSubmit, errors, register, formState } = useForm();

    const { translation, securitygroupId } = props;
    const defaultSecurityGroupData = {
        direction: 'ingress',
        ethertype: 'IPv4',
        portRangeMin: '',
        portRangeMax: '',
        protocol: 'TCP',
        remoteIpPrefix: '',
        securitygroupId,
    };

    /**
     * @param securityGroupRuleFormData
     */
    const handleOnSubmit = securityGroupRuleFormData => {
        // check if errors happen or if formState is valid to submit
        if (Object.keys(errors).length < 1 || formState.isValid) {
            const newSecurityGroupData = {
                ...defaultSecurityGroupData,
                ...securityGroupRuleFormData,
            };

            securityGroupRulesContext.actions.createNewSecurityGroupRule(newSecurityGroupData, securityGroupRulesContext.actions.fetchSecurityGroupRules.bind(null, securitygroupId));
            modalContext.actions.closeModal();
            NotificationManager.success(translation.react.securityrule.create.success, translation.react.global.success);
        }
    };

    return (
        <div className="form-group">
            <h2>{ translation.react.securityrule.new }</h2>
            <form onSubmit={ handleSubmit(handleOnSubmit) }>
                <div className="grid">
                    <div className={ `grid__column grid__column--xs-12 grid__column--sm-6 form-group${ errors.portRangeMin && (' form-group--error') }` }>
                        <label htmlFor="portRangeMin">{ translation.react.securityrule.portRangeMin }*</label>
                        <input type="number" id="portRangeMin" name="portRangeMin" placeholder="80" ref={ register({
                            pattern: {
                                value: port,
                                message: translation.react.validations.port
                            }
                        }) }/>
                        { errors.portRangeMin && (
                            <div className="form-group-error">{ errors.portRangeMin.message }</div>
                        ) }
                    </div>
                    <div className={ `grid__column grid__column--xs-12 grid__column--sm-6 form-group${ errors.portRangeMax && (' form-group--error') }` }>
                        <label htmlFor="portRangeMax">{ translation.react.securityrule.portRangeMax }*</label>
                        <input type="number" id="portRangeMax" name="portRangeMax" placeholder="80" ref={ register({
                            pattern: {
                                value: port,
                                message: translation.react.validations.port
                            }
                        }) }/>
                        { errors.portRangeMax && (
                            <div className="form-group-error">{ errors.portRangeMax.message }</div>
                        ) }
                    </div>
                </div>
                <div className="form-group">
                    <label htmlFor="protocol">{ translation.react.securityrule.protocol }*</label>
                    <select id="protocol" name="protocol" className="form-control mb-3" ref={ register }>
                        <option value="TCP">TCP</option>
                        <option value="UDP">UDP</option>
                    </select>
                </div>
                <div className={ `form-group${ errors.remoteIpPrefix && (' form-group--error') }` }>
                    <label htmlFor="remoteIpPrefix">{ translation.react.securityrule.remoteIP }*</label>
                    <input type="text" id="remoteIpPrefix" name="remoteIpPrefix" placeholder="127.0.0.1" ref={ register({
                        required: translation.react.validations.required,
                        pattern: {
                            value: ip,
                            message: translation.react.validations.IP
                        }
                    }) }/>
                    { errors.remoteIpPrefix && (
                        <div className="form-group-error">{ errors.remoteIpPrefix.message }</div>
                    ) }
                </div>
                <button disabled={ Object.keys(errors).length > 0 } type="submit" className="btn btn--big btn--danger mr-3">{ translation.react.global.submit }</button>
                <button type="button" className="btn btn--secondary btn--big" onClick={ () => modalContext.actions.closeModal() }>{ translation.react.global.cancel }</button>
            </form>
        </div>
    );
};

NewSecurityGroupRuleModal.propTypes = {
    securitygroupId: PropTypes.string.isRequired
};

export default withTranslation(NewSecurityGroupRuleModal, [ 'react' ]);
