import React, { Component, createContext } from "react";

import API from "../../../Api/Openstack";
import { ErrorContext } from "../../ErrorContextProvider";
import isEqual from "../../../Ultils/isEqual";

export const SecurityGroupRulesContext = createContext({});

class SecurityGroupRulesContextProvider extends Component {
    static contextType = ErrorContext;

    state = {
        securityGroupRules: [],
        loading: true,
    };

    /**
     * Fetch SecurityGroupRules
     * @param ruleId
     */
    fetchSecurityGroupRules = ruleId => {
        if (!ruleId) return;

        return API.getSecurityGroup(ruleId)
            .then(({ data }) => {
                //Filter empty default securityGroupRules
                const newSecurityGroupRulesArray = Object.values(data.rules).filter(rule => rule.protocol !== null);
                // check if loading is true => set it to false
                if (this.state.loading) {
                    this.setState({ loading: false });
                }

                if (!isEqual(this.state.securityGroupRules, newSecurityGroupRulesArray)) {
                    this.setState({ securityGroupRules: newSecurityGroupRulesArray });
                }
            })
            .catch(error => {
                // check if loading is true => set it to false
                if (this.state.loading) {
                    this.setState({ loading: false });
                }

                this.context.actions.showError(error);
                throw new Error(error);
            });
    };

    /**
     * Delete a SecurityGroupRule
     * @param securityGroupRuleId
     * @param callbackFn
     */
    deleteSecurityGroupRule = (securityGroupRuleId, callbackFn = () => {
    }) => {
        if (!securityGroupRuleId) return;
        return API.deleteSecurityGroupRule(securityGroupRuleId)
            .then(() => {
                const newSecurityGroupRulesArray = this.state.securityGroupRules.filter(securityGroupRule => securityGroupRule.id !== securityGroupRuleId);
                if (!isEqual(this.state.securityGroupRules, newSecurityGroupRulesArray)) {
                    this.setState({ securityGroupRules: newSecurityGroupRulesArray });
                }
            })
            .catch((error) => {
                this.context.actions.showError(error);
                throw new Error(error);
            });
    };

    /**
     * Create a new SecurityGroupRule
     * @param data
     * @param callbackFn
     */
    createNewSecurityGroupRule = (data, callbackFn = () => {
    }) => {
        if (!data) return;
        return API.createNewSecurityGroupRule(data)
            .then(() => {
                callbackFn();
            })
            .catch(error => {
                callbackFn();
                this.context.actions.showError(error);
                throw new Error(error);
            });
    };

    clearSecurityGroupRules = () => {
        this.setState({ securityGroupRules: [] });
    };

    /**
     * @param loading
     */
    setLoading = loading => {
        this.setState({ loading });
    };

    render() {
        return (
            <SecurityGroupRulesContext.Provider value={ {
                ...this.state,
                actions: {
                    fetchSecurityGroupRules: this.fetchSecurityGroupRules,
                    deleteSecurityGroupRule: this.deleteSecurityGroupRule,
                    createNewSecurityGroupRule: this.createNewSecurityGroupRule,
                    clearSecurityGroupRules: this.clearSecurityGroupRules,
                    setLoading: this.setLoading,
                }
            } }>
                { this.props.children }
            </SecurityGroupRulesContext.Provider>
        );
    }
}

export default SecurityGroupRulesContextProvider;
