import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";

import { ModalContext } from "../../../../Context/ModalContextProvider";
import withTranslation from "../../../../Ultils/withTranslation";
import { BillingContext } from "../../../../Context/Api/Openstack/BillingContextProvider";
import { VolumesContext } from "../../../../Context/Api/Openstack/VolumesContextProvider";
import { useDidMount } from "react-hooks-lib";
import { Column, Grid } from "../../../Styling/Grid";
import { RadioCard } from "../../../Styling/Card";
import { NotificationManager } from "react-notifications";

const NewVolumeResizeModal = props => {
    const [ loading, setLoading ] = useState(true);
    const modalContext = useContext(ModalContext);
    const volumesContext = useContext(VolumesContext);
    const { volume } = props;
    const { handleSubmit, errors, register, formState } = useForm();
    const { translation } = props;

    const billingContext = useContext(BillingContext);
    const [ hddSize, setHddSize ] = useState(volume.size);
    const [ hddTypeKey ] = useState(`Volume${ volume.type.toUpperCase() }`);

    useDidMount(() => {
        // load intial fresh data before the form appears
        const loadFormInitialData = async () => {
            await billingContext.actions.fetchPrices();
            setLoading(false);
        };
        loadFormInitialData();
    });

    const handleOnSubmit = newVolumeFormData => {
        // check if errors happen or if formState is valid to submit
        if (Object.keys(errors).length < 1 || formState.isValid) {
            setLoading(true);
            let data = { id: volume.id, size: parseInt(newVolumeFormData.customSize) }; //Convert to Object for REST-Api
            volumesContext.actions.resizeVolume(data, () => {
                setTimeout(() => {
                    volumesContext.actions.fetchVolumes(); //@todo Basti: Ich glaube das geht auch eleganter
                    setLoading(false);
                    modalContext.actions.closeModal();
                    NotificationManager.success(translation.react.volume.resize.success, translation.react.global.success);
                }, 3000);
            });
        }
    };

    const handleChangeHddSize = e => {
        const newSize = parseInt(e.target.value);
        if (!newSize || newSize < volume.size) {
            setHddSize(volume.size);
            return;
        }

        setHddSize(newSize);
    };

    return (
        <div className="form-group">
            <h2>{ translation.react.volume.resize.title }</h2>
            { loading ? (
                <h3>{ translation.react.global.save } ...</h3>
            ) : (
                <form onSubmit={ handleSubmit(handleOnSubmit) }>
                    <Grid>
                        <Column xs={ 12 } md={ 12 } xl={ 12 } xxl={ 12 }>
                            <RadioCard
                                id="size"
                                name="size"
                                defaultChecked={ true }
                            >
                                <div className="radio-card__header">
                                    <h3 className="flavor-price mb-0">
                                        { billingContext.actions.getPricingPerMonth(billingContext.prices[ hddTypeKey ], hddSize) }
                                        <small> € / { translation.react.global.month }</small>
                                    </h3>
                                </div>
                                <div className="radio-card__content">
                                <span className="flavor-price flavor-price--input mb-0">
                                    <input id={ `customgb` } type="number" name="customSize" onChange={ handleChangeHddSize } defaultValue={ volume.size } min={ (volume.size + 10) } step={ 10 } max={ 2000 } ref={ register }/>
                                    <small> GB</small>
                                </span>
                                </div>
                            </RadioCard>
                        </Column>
                    </Grid>
                    <button disabled={ Object.keys(errors).length > 0 } type="submit" className="btn btn--big btn--danger mr-3">{ translation.react.global.save }</button>
                    <button type="button" className="btn btn--secondary btn--big" onClick={ () => modalContext.actions.closeModal() }>{ translation.react.global.cancel }</button>
                </form>
            ) }
        </div>
    );
};

NewVolumeResizeModal.propTypes = {
    volume: PropTypes.object.isRequired
};

export default withTranslation(NewVolumeResizeModal, [ 'react' ]);
