import React, { useContext, useState } from "react";
import PropTypes from "prop-types";

import ConfirmationModal from "../../../Modal/ConfirmationModal";
import { ModalContext } from "../../../../Context/ModalContextProvider";
import { SecurityGroupRulesContext } from "../../../../Context/Api/Openstack/SecurityGroupRulesContextProvider";
import { Dropdown } from "../../../Styling/Dropdown";
import { NotificationManager } from "react-notifications";

const SecurityGroupRuleItem = props => {
    const securityGroupRuleContext = useContext(SecurityGroupRulesContext);

    const { securitygrouprule, translation } = props;
    const [ isBusy, setIsBusy ] = useState(false);
    const modalContext = useContext(ModalContext);

    /**
     *
     * @param securityGroupRuleId
     */
    const deleteSecurityGroupRule = async securityGroupRuleId => {
        if (!securityGroupRuleId) return;

        // set busy state to deactivate delete button
        setIsBusy(true);

        try {
            await securityGroupRuleContext.actions.deleteSecurityGroupRule(securityGroupRuleId);
            NotificationManager.success(translation.react.securityrule.delete.success, translation.react.global.success);
        } catch (e) {

        }

    };

    /**
     *
     * @param securityGroupRuleId
     */
    const handleDeleteSecurityGroupRule = securityGroupRuleId => {
        modalContext.actions.setModalContent(ConfirmationModal,
            {
                confirmAction: () => deleteSecurityGroupRule(securityGroupRuleId),
                title: translation.react.securityrule.delete.title,
                action: translation.react.global.delete,
                cancel: translation.react.global.cancel
            },
            { maxWidth: '330px' });
    };

    return (
        <tr>
            <td data-label={ `${ translation.react.securityrule.direction }:` }><b>{ securitygrouprule.direction }</b></td>
            <td data-label={ `${ translation.react.securityrule.ethertype }:` }>{ securitygrouprule.ethertype }</td>
            <td data-label={ `${ translation.react.securityrule.protocol }:` }>{ securitygrouprule.protocol }</td>
            <td data-label={ `${ translation.react.securityrule.portRangeMin }:` }>{ securitygrouprule.portRangeMin }</td>
            <td data-label={ `${ translation.react.securityrule.portRangeMax }:` }>{ securitygrouprule.portRangeMax }</td>
            <td data-label={ `${ translation.react.securityrule.remoteIP }:` }>{ securitygrouprule.remoteIP }</td>
            <td data-label={ `${ translation.react.global.action }:` } className="ta-r">
                <Dropdown
                    dropdownAlignment="right-desktop"
                    arrowAlignment="right-desktop"
                >
                    <ul>
                        <li>
                            <button disabled={ isBusy } onClick={ () => handleDeleteSecurityGroupRule(securitygrouprule.id) } className="btn"><i className="far fa-trash-alt" aria-label="Delete"/> { translation.react.global.delete }</button>
                        </li>
                    </ul>
                </Dropdown>
            </td>
        </tr>
    );
};

SecurityGroupRuleItem.propTypes = {
    translation: PropTypes.object.isRequired,
    securityGroupRules: PropTypes.shape({
        id: PropTypes.string.isRequired,
    })
};
export default React.memo(SecurityGroupRuleItem);
