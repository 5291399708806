import axios from "axios";
import API_CONST from "../constants";

/**
 * fetch flavors action
 *
 * @returns {AxiosPromise<any>}
 */
const fetchFlavors = () => {
    return axios.get(API_CONST.FLAVOR_URL);
};

export {
    fetchFlavors,
}
