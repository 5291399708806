import React, { useContext } from "react";
import { useForm } from "react-hook-form";

import { ModalContext } from "../../../../Context/ModalContextProvider";
import { no_start_space_alpha, sshKey } from "../../../../Ultils/RegexPatterns";
import withTranslation from "../../../../Ultils/withTranslation";
import { KeypairsContext } from "../../../../Context/Api/Openstack/KeypairsContextProvider";
import { NotificationManager } from "react-notifications";

const NewKeypairModal = props => {
    const modalContext = useContext(ModalContext);
    const { handleSubmit, errors, register, formState } = useForm();
    const keypairsContext = useContext(KeypairsContext);
    const {
        translation, confirmAction = () => {
        }
    } = props;

    const handleOnSubmit = keypairsFormData => {
        // check if errors happen or if formState is valid to submit
        if (Object.keys(errors).length < 1 || formState.isValid) {
            keypairsContext.actions.createKeypair(keypairsFormData, keypairsContext.actions.fetchKeypairs);
            confirmAction();
            modalContext.actions.closeModal();
            NotificationManager.success(translation.react.keypair.create.success, translation.react.global.success);
        }
    };

    return (
        <div className="form-group">
            <h2>{ translation.react.keypair.create.title }</h2>
            <form onSubmit={ handleSubmit(handleOnSubmit) }>
                <div className={ `form-group${ errors.name && (' form-group--error') }` }>
                    <label htmlFor="name">{ translation.react.global.name }*</label>
                    <input type="text" id="name" name="name" ref={ register({
                        required: translation.react.validations.required,
                        minLength: {
                            value: 5,
                            message: translation.react.validations.min5
                        },
                        pattern: {
                            value: no_start_space_alpha,
                            message: translation.react.validations.spaces
                        }
                    }) }/>
                    { errors.name && (
                        <div className="form-group-error">{ errors.name.message }</div>
                    ) }
                </div>
                <div className={ `form-group${ errors.publicKey && (' form-group--error') }` }>
                    <label htmlFor="description">{ translation.react.keypair.pubkey }*</label>
                    <textarea type="text" id="publicKey" name="publicKey" ref={ register({
                        required: translation.react.validations.required,
                        pattern: {
                            value: sshKey,
                            message: translation.react.validations.ssh
                        }
                    }) }/>
                    { errors.publicKey && (
                        <div className="form-group-error">{ errors.publicKey.message }</div>
                    ) }
                </div>
                <button disabled={ Object.keys(errors).length > 0 } type="submit" className="btn btn--big btn--danger mr-3">{ translation.react.global.save }</button>
                <button type="button" className="btn btn--secondary btn--big" onClick={ () => modalContext.actions.closeModal() }>{ translation.react.global.cancel }</button>
            </form>
        </div>
    );
};

export default withTranslation(NewKeypairModal, [ 'react' ]);
