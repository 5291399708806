import React, { useContext } from "react";

import { ModalContext } from "../../Context/ModalContextProvider";
import PropTypes from "prop-types";

const ConfirmationModal = props => {
    const modalContext = useContext(ModalContext);
    const { confirmAction, denyAction, title, action, cancel, description } = props;
    const handleConfirmation = () => {
        confirmAction();
        modalContext.actions.closeModal();
    };

    const handleDeny = () => {
        if (denyAction) {
            denyAction();
        }
        modalContext.actions.closeModal();
    };

    return (
        <div>
            <h2>{ title }</h2>
            { description && (
                <p> { description }</p>
            ) }
            <button onClick={ handleConfirmation } className="btn btn--big btn--danger mr-3">{ action }</button>
            <button onClick={ handleDeny } className="btn btn--big btn--secondary">{ cancel }</button>
        </div>
    );
};

ConfirmationModal.propTypes = {
    confirmAction: PropTypes.func.isRequired,
    denyAction: PropTypes.func,
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    action: PropTypes.string.isRequired,
    cancel: PropTypes.string.isRequired,
};

export default React.memo(ConfirmationModal);
