import axios from "axios";
import API_CONST from "../constants";

/**
 *  Get all groups
 * @returns {AxiosPromise<any>}
 */
const getAllSecurityGroups = () => {
    return axios.get(API_CONST.SECURITYGROUPS_URL)
};

/**
 * Get a single security group
 *
 * @param groupId
 * @returns {AxiosPromise<any>}
 */
const getSecurityGroup = groupId => {
    return axios.get(`${ API_CONST.SECURITYGROUPS_URL }/${ groupId }`);
};

/**
 * Create a new security group
 *
 * @param data
 * @returns {AxiosPromise<any>}
 */
const createNewSecurityGroup = data => {
    return axios.post(API_CONST.SECURITYGROUPS_URL, data);
};

/**
 * Delete Security Group
 *
 * @param groupId
 * @returns {AxiosPromise}
 */
const deleteSecurityGroup = groupId => {
    return axios.delete(`${ API_CONST.SECURITYGROUPS_URL }/${ groupId }`);
};

export {
    getAllSecurityGroups,
    getSecurityGroup,
    createNewSecurityGroup,
    deleteSecurityGroup
}
