import React, { Fragment, useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDidMount, useWillUnmount } from "react-hooks-lib";
import { useForm } from "react-hook-form";
import Recaptcha from "react-google-recaptcha";

import withTranslation from "../../../Ultils/withTranslation";
import scrollToTop from "../../../Ultils/_scrollToTop";
import { AuthContext } from "../../../Context/Api/Openstack/AuthContextProvider";
import { ErrorContext } from "../../../Context/ErrorContextProvider";
import { Card } from "../../Styling/Card";
import { birthday, email, phone, zip_de } from "../../../Ultils/RegexPatterns";
import { Column, Grid } from "../../Styling/Grid";
import { Spinner } from "../../Styling/Loading";
import { UserContext } from "../../../Context/Api/Openstack/UserContextProvider";
import { RegisterSteps } from "./index";

import Logo from "../../../Assets/Images/mobi-logo-login.svg";

const Register = props => {
    const authContext = useContext(AuthContext);
    const userContext = useContext(UserContext);
    const errorContext = useContext(ErrorContext);
    const { translation } = props;

    const { register, handleSubmit, errors, formState, watch } = useForm();
    const [ registerState, setRegisterState ] = useState(false);
    const [ registerStep, setRegisterStep ] = useState(0);
    const [ captchaCode, setCaptchaCode ] = useState(null);

    useEffect(() => {
        // check if crefoPayToken and Redirect url is given => redirect to crefoPay
        if (userContext.crefoPayToken && userContext.crefoPayToken.redirect) {
            setTimeout(() => {
                window.location = userContext.crefoPayToken.redirect;
            }, 3000);
        }
    }, [ userContext.crefoPayToken ]);

    // clear errors in authContext to get a cleared error view
    useDidMount(() => {
        authContext.actions.clearError();
    });

    // get login from sessionStorage
    //useDidMount(authContext.actions.getAuthenticationFromSession);
    useWillUnmount(() => resetForm());

    /**
     * @param registerFormData
     * @returns {Promise<void>}
     */
    const handleOnSubmit = async registerFormData => {
        // check if errors happen or if formState is valid to submit
        if (Object.keys(errors).length < 1 || formState.isValid || captchaCode) {
            // set loginState
            setRegisterState(true);

            // scroll page to top
            scrollToTop();

            const registerUserError = await userContext.actions.registerUser(registerFormData, captchaCode);

            // if error happen
            if (registerUserError) {
                setRegisterState(false);
                return;
            }
            setRegisterStep(1);
            setRegisterState(false);
        } else {
            setRegisterState(false);
        }
    };

    const resetForm = () => {
        setRegisterState(false);
    };

    if (authContext.hasError && registerState) {
        setRegisterState(false);
    }

    return (
        <Fragment>
            <Card className={ `register register--step-1 ${ registerState ? 'register--is-busy' : '' }` }>
                <Link to="/">
                    <img className="logo mt-4" src={ Logo } width={ 160 } alt="Mobilistics"/>
                </Link>

                { registerStep === 0 && (
                    <div className="mb-4" dangerouslySetInnerHTML={ { __html: translation.react.register.infotext.replace("_", "") } }></div>
                ) }

                <RegisterSteps step={ registerStep }/>
                { authContext.hasError && (
                    <ul>
                        { errorContext.actions.getTranslatedErrorMessagesByError(authContext.error).map((errorMessage, index) => (
                            <li key={ index } className="form-error">{ errorMessage }</li>
                        )) }
                    </ul>
                ) }

                { registerStep === 0 && (
                    <form onSubmit={ handleSubmit(handleOnSubmit) }>
                        <Grid>
                            <Column md={ 3 }>
                                <div className="form-group">
                                    <input type="radio" className="form-control" id="typePrivate" name="type" defaultChecked={ true } value="PRIVATE" ref={ register }/>
                                    <label htmlFor="typePrivate">{ translation.react.register.typePrivate }</label>
                                </div>
                            </Column>
                            <Column md={ 3 }>
                                <div className="form-group">
                                    <input type="radio" className="form-control" id="typeBusiness" name="type" value="BUSINESS" ref={ register }/>
                                    <label htmlFor="typeBusiness">{ translation.react.register.typeBusiness }</label>
                                </div>
                            </Column>
                        </Grid>

                        { watch('type') === "BUSINESS" && (
                            <Grid>
                                <Column md={ 6 }>
                                    <div className={ `form-group${ errors.company && (' form-group--error') }` }>
                                        <label htmlFor="company">{ translation.react.register.company }*</label>
                                        <input type="text" className="form-control" id="company" name="company" placeholder={ translation.react.register.companyPlace } ref={ register({ required: translation.react.validations.required }) }/>
                                        { errors.company && (
                                            <div className="form-group-error">{ errors.company.message }</div>
                                        ) }
                                    </div>
                                </Column>
                                <Column md={ 6 }>
                                    <div className={ `form-group${ errors.companyVatID && (' form-group--error') }` }>
                                        <label htmlFor="companyVatID">{ translation.react.register.companyVatID }*</label>
                                        <input type="text" className="form-control" id="companyVatID" name="companyVatID" placeholder={ translation.react.register.companyVatID } ref={ register({ required: translation.react.validations.required }) }/>
                                        { errors.companyVatID && (
                                            <div className="form-group-error">{ errors.companyVatID.message }</div>
                                        ) }
                                    </div>
                                </Column>
                            </Grid>
                        ) }

                        <Grid>
                            <Column md={ 6 }>
                                <div className={ `form-group${ errors.firstName && (' form-group--error') }` }>
                                    <label htmlFor="firstName">{ translation.react.register.firstname }*</label>
                                    <input type="text" className="form-control" id="firstName" name="firstName" placeholder={ translation.react.register.firstnamePlace } ref={ register({ required: translation.react.validations.required }) }/>
                                    { errors.firstName && (
                                        <div className="form-group-error">{ errors.firstName.message }</div>
                                    ) }
                                </div>
                            </Column>
                            <Column md={ 6 }>
                                <div className={ `form-group${ errors.lastName && (' form-group--error') }` }>
                                    <label htmlFor="lastName">{ translation.react.register.lastname }*</label>
                                    <input type="text" className="form-control" id="lastName" name="lastName" placeholder={ translation.react.register.lastnamePlace } ref={ register({ required: translation.react.validations.required }) }/>
                                    { errors.lastName && (
                                        <div className="form-group-error">{ errors.lastName.message }</div>
                                    ) }
                                </div>
                            </Column>
                        </Grid>

                        { watch('type') !== "BUSINESS" && (
                            <Grid>
                                <Column md={ 6 }>
                                    <div className={ `form-group${ errors.birthday && (' form-group--error') }` }>
                                        <label htmlFor="birthday">{ translation.react.register.birthday }*</label>
                                        <input type="text" className="form-control" id="birthday" name="birthday" placeholder={ translation.react.register.birthdayPlace } ref={ register({
                                            required: translation.react.validations.required,
                                            pattern: {
                                                value: birthday,
                                                message: translation.react.validations.birthday
                                            }
                                        }) }/>
                                        { errors.birthday && (
                                            <div className="form-group-error">{ errors.birthday.message }</div>
                                        ) }
                                    </div>
                                </Column>
                            </Grid>
                        ) }

                        <Grid>
                            <Column md={ 6 }>
                                <div className={ `form-group${ errors.email && (' form-group--error') }` }>
                                    <label htmlFor="email">{ translation.react.register.email }*</label>
                                    <input type="text" className="form-control" id="email" name="email" placeholder={ translation.react.register.emailPlace } ref={ register({
                                        required: translation.react.validations.required,
                                        pattern: {
                                            value: email,
                                            message: translation.react.validations.email
                                        }
                                    }) }/>
                                    { errors.email && (
                                        <div className="form-group-error">{ errors.email.message }</div>
                                    ) }
                                </div>
                            </Column>
                            <Column md={ 6 }>
                                <div className={ `form-group${ errors.emailBilling && (' form-group--error') }` }>
                                    <label htmlFor="emailBilling">{ translation.react.register.emailBilling }*</label>
                                    <input type="text" className="form-control" id="emailBilling" name="emailBilling" placeholder={ translation.react.register.emailBillingPlace } ref={ register({
                                        required: translation.react.validations.required,
                                        pattern: {
                                            value: email,
                                            message: translation.react.validations.email
                                        }
                                    }) }/>
                                    { errors.emailBilling && (
                                        <div className="form-group-error">{ errors.emailBilling.message }</div>
                                    ) }
                                </div>
                            </Column>
                        </Grid>

                        <Grid className="mt-5">
                            <Column sm={ 6 }>
                                <div className="form-group">
                                    <label htmlFor="country">{ translation.react.register.country }*</label>
                                    <select name="country" id="country" defaultValue="DE" ref={ register }>
                                        <option value="DE">{ translation.react.global.germany }</option>
                                        <option value="AT">{ translation.react.global.austria }</option>
                                        <option value="CH">{ translation.react.global.switzerland }</option>
                                    </select>
                                </div>
                            </Column>
                        </Grid>

                        <Grid>
                            <Column sm={ 8 } md={ 9 }>
                                <div className={ `form-group${ errors.street && (' form-group--error') }` }>
                                    <label htmlFor="street">{ translation.react.register.street }*</label>
                                    <input type="text" className="form-control" id="street" name="street" placeholder={ translation.react.register.streetPlace } ref={ register({ required: translation.react.validations.required }) }/>
                                    { errors.street && (
                                        <div className="form-group-error">{ errors.street.message }</div>
                                    ) }
                                </div>
                            </Column>
                            <Column sm={ 4 } md={ 3 }>
                                <div className={ `form-group${ errors.houseNumber && (' form-group--error') }` }>
                                    <label htmlFor="houseNumber">{ translation.react.register.houseNumber }*</label>
                                    <input type="text" className="form-control" id="houseNumber" name="houseNumber" placeholder={ translation.react.register.houseNumberPlace } ref={ register({ required: translation.react.validations.required }) }/>
                                    { errors.houseNumber && (
                                        <div className="form-group-error">{ errors.houseNumber.message }</div>
                                    ) }
                                </div>
                            </Column>
                        </Grid>

                        <Grid>
                            <Column sm={ 4 } md={ 3 }>
                                <div className={ `form-group${ errors.zipCode && (' form-group--error') }` }>
                                    <label htmlFor="zipCode">{ translation.react.register.zip }*</label>
                                    <input type="text" className="form-control" id="zipCode" name="zipCode" placeholder={ translation.react.register.zipPlace } ref={ register({
                                        required: translation.react.validations.required,
                                        pattern: {
                                            value: zip_de,
                                            message: translation.react.validations.zip
                                        }
                                    }) }/>
                                    { errors.zipCode && (
                                        <div className="form-group-error">{ errors.zipCode.message }</div>
                                    ) }
                                </div>
                            </Column>
                            <Column sm={ 8 } md={ 9 }>
                                <div className={ `form-group${ errors.city && (' form-group--error') }` }>
                                    <label htmlFor="city">{ translation.react.register.city }*</label>
                                    <input type="text" className="form-control" id="city" placeholder={ translation.react.register.cityPlace } name="city" ref={ register({ required: translation.react.validations.required }) }/>
                                    { errors.city && (
                                        <div className="form-group-error">{ errors.city.message }</div>
                                    ) }
                                </div>
                            </Column>
                        </Grid>

                        <Grid>
                            <Column md={ 6 }>
                                <div className={ `form-group${ errors.tel && (' form-group--error') }` }>
                                    <label htmlFor="tel">{ translation.react.register.phone }*</label>
                                    <input type="text" className="form-control" id="tel" name="tel" placeholder={ translation.react.register.phonePlace } ref={ register({
                                        required: translation.react.validations.required,
                                        pattern: {
                                            value: phone,
                                            message: translation.react.validations.phone
                                        }
                                    }) }/>
                                    { errors.tel && (
                                        <div className="form-group-error">{ errors.tel.message }</div>
                                    ) }
                                </div>
                            </Column>
                        </Grid>

                        <Grid>
                            <Column md={ 12 }>
                                <div className={ `form-group${ errors.gtac && (' form-group--error') }` }>
                                    <input type="checkbox" className="form-control" id="gtac" name="gtac" ref={ register({
                                        required: translation.react.validations.required
                                    }) }/>
                                    <label htmlFor="gtac"><span dangerouslySetInnerHTML={ { __html: translation.react.register.gtac } }></span>*</label>
                                    { errors.gtac && (
                                        <div className="form-group-error">{ errors.gtac.message }</div>
                                    ) }
                                </div>
                            </Column>
                        </Grid>

                        <Grid>
                            <Column md={ 12 }>
                                <div className={ `form-group${ errors.privacy_statement && (' form-group--error') }` }>
                                    <input type="checkbox" className="form-control" id="privacy_statement" name="privacy_statement" ref={ register({
                                        required: translation.react.validations.required
                                    }) }/>
                                    <label htmlFor="privacy_statement"><span dangerouslySetInnerHTML={ { __html: translation.react.register.privacyStatement } }></span>*</label>
                                    { errors.privacy_statement && (
                                        <div className="form-group-error">{ errors.privacy_statement.message }</div>
                                    ) }
                                </div>
                            </Column>
                        </Grid>

                        { watch('type') === "PRIVATE" && (
                            <Grid>
                                <Column md={ 12 }>
                                    <div className={ `form-group${ errors.revocation && (' form-group--error') }` }>
                                        <input type="checkbox" className="form-control" id="revocation" name="revocation" ref={ register({
                                            required: translation.react.validations.required
                                        }) }/>
                                        <label htmlFor="revocation">{ translation.react.register.revocation }*</label>
                                        { errors.revocation && (
                                            <div className="form-group-error">{ errors.revocation.message }</div>
                                        ) }
                                    </div>
                                </Column>
                            </Grid>
                        ) }

                        <Recaptcha sitekey={ process.env.REACT_APP_GOOGLE_RECAPTCHA_PUBLIC } onChange={ captcha => setCaptchaCode(captcha) }/>

                        <button disabled={ !captchaCode || Object.keys(errors).length > 0 } type="submit" className="btn btn--primary btn--big mt-6">{ translation.react.global.register }</button>
                    </form>
                ) }

                { registerStep === 1 && (
                    <Fragment>
                        <p>{ translation.react.validations.paymentRedirect } <a href={ userContext.crefoPayToken.redirect } title="CrefoPay confirmation">{ translation.react.global.here }</a></p>
                    </Fragment>
                ) }
                <Spinner className="loader"/>
            </Card>
        </Fragment>
    );
};

export default withTranslation(React.memo(Register), [ 'react' ]);

