import React, { useContext, useState } from "react";
import PropTypes from "prop-types";

import { KeypairsContext } from "../../../Context/Api/Openstack/KeypairsContextProvider";
import ConfirmationModal from "../../Modal/ConfirmationModal";
import { ModalContext } from "../../../Context/ModalContextProvider";
import { Dropdown } from "../../Styling/Dropdown";
import { NotificationManager } from "react-notifications";

const KeypairItem = props => {
    const keypairsContext = useContext(KeypairsContext);
    const modalContext = useContext(ModalContext);
    const { keypair, translation } = props;
    const [ isBusy, setIsBusy ] = useState(false);

    /**
     * @param keypairName
     */
    const deleteKeypair = keypairName => {
        if (!keypairName) return;
        // set busy state to deactivate delete button
        setIsBusy(true);
        try {
            keypairsContext.actions.deleteKeypair(keypairName, () => {
                keypairsContext.actions.fetchKeypairs();
                NotificationManager.success(translation.react.keypair.delete.success, translation.react.global.success);
            });
        } catch (e) {

        }
    };

    /**
     * handle fn for delete btn
     * @param keypairName
     */
    const handleDeleteKeypair = keypairName => {
        modalContext.actions.setModalContent(ConfirmationModal, {
            confirmAction: () => deleteKeypair(keypairName),
            title: translation.react.keypair.delete.title,
            action: translation.react.global.delete,
            cancel: translation.react.global.cancel
        }, { maxWidth: '330px' });
    };

    return (
        <tr>
            <td data-label={ `${ translation.react.global.name }:` }><b>{ keypair.name }</b></td>
            <td data-label={ `${ translation.react.keypair.fingerprint }:` }>{ keypair.fingerprint }</td>
            <td data-label={ `${ translation.react.global.action }:` } className="ta-r">
                <Dropdown
                    dropdownAlignment="right-desktop"
                    arrowAlignment="right-desktop"
                >
                    <ul>
                        <li>
                            <button disabled={ isBusy } onClick={ () => handleDeleteKeypair(keypair.name) } className="btn"><i className="far fa-trash-alt" aria-label="Delete"/> { translation.react.global.delete }</button>
                        </li>
                    </ul>
                </Dropdown>
            </td>
        </tr>
    );
};

KeypairItem.propTypes = {
    translation: PropTypes.object.isRequired,
    keypair: PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        fingerprint: PropTypes.string.isRequired
    })
};

export default React.memo(KeypairItem);
