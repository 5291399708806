import PropTypes from "prop-types";
import React from "react";

export const Column = props => {
    const { xs = 12, sm, md, lg, xl, xxl, children, className, setElRef = null, ...rest } = props;

    return (
        <div ref={ setElRef } className={ `grid__column${ xs ? ` grid__column--xs-${ xs }` : '' }${ sm ? ` grid__column--sm-${ sm }` : '' }${ md ? ` grid__column--md-${ md }` : '' }${ lg ? ` grid__column--lg-${ lg }` : '' }${ xl ? ` grid__column--xl-${ xl }` : '' }${ xxl ? ` grid__column--xxl-${ xxl }` : '' } ${ className ? className : '' }` } { ...rest }>
            { children }
        </div>
    );
};

Column.propTypes = {
    setElRef: PropTypes.object,
    className: PropTypes.string,
    xs: PropTypes.number,
    sm: PropTypes.number,
    md: PropTypes.number,
    lg: PropTypes.number,
    xl: PropTypes.number,
    xxl: PropTypes.number,
};
