import axios from "axios";
import API_CONST from "../constants";

/**
 * fetch Keypairs
 *
 * @returns {AxiosPromise<any>}
 */
const fetchKeypairs = () => {
    return axios.get(API_CONST.KEYPAIR_URL);
};

/**
 * Delete a KeyPair
 *
 * @param keypairName
 * @returns {AxiosPromise}
 */
const deleteKeypair = keypairName => {
    return axios.delete(`${ API_CONST.KEYPAIR_URL }/${ keypairName }`);
};

/**
 * Create a new Keypair
 *
 * @param data
 * @returns {AxiosPromise<any>}
 */
const createNewKeypair = data => {
    return axios.post(API_CONST.KEYPAIR_URL, data);
};

export {
    fetchKeypairs,
    deleteKeypair,
    createNewKeypair
}
