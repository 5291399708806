import React, { Fragment, useContext, useState } from "react";
import Gravatar from 'react-awesome-gravatar';
import { useDidMount } from "react-hooks-lib";
import { useForm } from "react-hook-form";
import { NotificationManager } from "react-notifications";

import withTranslation from "../../../Ultils/withTranslation";
import { UserContext } from "../../../Context/Api/Openstack/UserContextProvider";
import { CrefoPayContext } from "../../../Context/Api/Openstack/CrefoPayContextProvider";
import { Column, Grid } from "../../Styling/Grid";
import { birthday, email, phone, zip_de } from "../../../Ultils/RegexPatterns";
import { Spinner } from "../../Styling/Loading";
import Card from "../../Styling/Card/Card";
import isEqual from "../../../Ultils/isEqual";

const EditProfile = props => {
    const userContext = useContext(UserContext);
    const crefoPayContext = useContext(CrefoPayContext);
    const { register, handleSubmit, errors, formState } = useForm();
    const [ loading, setLoading ] = useState(true);
    const [ saving, setSaving ] = useState(false);
    const [ changePayment, setChangePayment ] = useState(false);
    const { translation, history } = props;
    const { user } = userContext;

    useDidMount(() => {
        const fetchUserData = async () => {
            await userContext.actions.getUser();
            setLoading(false);
        };

        fetchUserData();

        // check if crefoPayData is available
        if (crefoPayContext.orderID && crefoPayContext.paymentMethod && crefoPayContext.paymentInstrumentID) {
            updatePayment();
        }
    });

    const handleOnSubmit = async registerFormData => {
        let extractedUserData = {};

        const registerFormDataKeys = Object.keys(registerFormData);
        registerFormDataKeys.forEach(userFormKey => {
            extractedUserData[ userFormKey ] = user[ userFormKey ];
        });

        // if extractedUserData !== registerFormData => save new userData
        if (!isEqual(extractedUserData, registerFormData)) {
            setSaving(true);
            try {
                await userContext.actions.updateUser(registerFormData);
                await userContext.actions.getUser();
                setSaving(false);
                NotificationManager.success(translation.react.user.edit.success, translation.react.global.success);
            } catch (e) {
                setSaving(false);
            }
        }
    };

    const handleChangePayment = async () => {
        setChangePayment(true);
        const userChangePaymentResponse = await userContext.actions.userChangePayment();

        // if error happen
        if (userChangePaymentResponse.message) {
            setChangePayment(false);
            return null;
        }

        // redirect => crefoPay
        setChangePayment(false);
        window.location = userChangePaymentResponse;
    };

    const updatePayment = async () => {
        // try confirm given crefoPay data
        const checkCrefoPayConfirmationError = await crefoPayContext.actions.checkCrefoPayConfirmation(crefoPayContext.actions.getPaymentDataForUser());

        // clear crefoPayContext State and remove the params
        crefoPayContext.actions.clearCrefoPayValidationParams();

        // if error happen
        if (checkCrefoPayConfirmationError) {
            return;
        }

        // success message
        NotificationManager.success(translation.react.user.edit.changePaymentSuccess, translation.react.global.success);

        // redirect to normal edit page if payment infos successfully updated
        history.push("/dashboard/profile/edit");
    };

    return (
        <div>
            { loading ? (
                <h2>{ translation.react.global.loading } ...</h2>
            ) : (
                <Fragment>
                    { Object.keys(user).length > 0 && (
                        <form aria-busy={ saving } onSubmit={ handleSubmit(handleOnSubmit) }>
                            <h2 className="mb-6">{ translation.react.user.edit.editProfile }</h2>
                            <Grid>
                                <Column md={ 2 } className="hide-md">
                                    <Gravatar email={ user.email } options={ { size: 120 } }>
                                        { url => (
                                            <img src={ url } alt={ `${ user.firstName } ${ user.lastName }` } width="120" className="avatar"/>
                                        ) }
                                    </Gravatar>
                                </Column>
                                <Column sm={ 12 } md={ 12 } lg={ 10 }>
                                    <div>

                                        <Grid>
                                            <Column xl={ 6 }>
                                                <h3>{ translation.react.user.edit.personalData }</h3>
                                                <Card className="pb-0">
                                                    { user.type === "BUSINESS" && (
                                                        <Grid>
                                                            <Column md={ 12 }>
                                                                <div className={ `form-group${ errors.company && (' form-group--error') }` }>
                                                                    <label htmlFor="company">{ translation.react.register.company }*</label>
                                                                    <input disabled={ saving } type="text" className="form-control" id="company" name="company" placeholder={ translation.react.register.companyPlace } defaultValue={ user.company } ref={ register({ required: translation.react.validations.required }) }/>
                                                                    { errors.company && (
                                                                        <div className="form-group-error">{ errors.company.message }</div>
                                                                    ) }
                                                                </div>
                                                            </Column>
                                                        </Grid>
                                                    ) }

                                                    <Grid>
                                                        <Column md={ 6 }>
                                                            <div className={ `form-group${ errors.firstName && (' form-group--error') }` }>
                                                                <label htmlFor="firstName">{ translation.react.register.firstname }*</label>
                                                                <input disabled={ saving } type="text" className="form-control" id="firstName" name="firstName" placeholder={ translation.react.register.firstnamePlace } defaultValue={ user.firstName } ref={ register({ required: translation.react.validations.required }) }/>
                                                                { errors.firstName && (
                                                                    <div className="form-group-error">{ errors.firstName.message }</div>
                                                                ) }
                                                            </div>
                                                        </Column>
                                                        <Column md={ 6 }>
                                                            <div className={ `form-group${ errors.lastName && (' form-group--error') }` }>
                                                                <label htmlFor="lastName">{ translation.react.register.lastname }*</label>
                                                                <input disabled={ saving } type="text" className="form-control" id="lastName" name="lastName" placeholder={ translation.react.register.lastnamePlace } defaultValue={ user.lastName } ref={ register({ required: translation.react.validations.required }) }/>
                                                                { errors.lastName && (
                                                                    <div className="form-group-error">{ errors.lastName.message }</div>
                                                                ) }
                                                            </div>
                                                        </Column>
                                                    </Grid>

                                                    { user.type === "PRIVATE" && (
                                                        <Grid>
                                                            <Column md={ 6 }>
                                                                <div className={ `form-group${ errors.birthday && (' form-group--error') }` }>
                                                                    <label htmlFor="birthday">{ translation.react.register.birthday }*</label>
                                                                    <input disabled={ saving } type="text" className="form-control" id="birthday" name="birthday" defaultValue={ user.birthday } placeholder={ translation.react.register.birthdayPlace } ref={ register({
                                                                        required: translation.react.validations.required,
                                                                        pattern: {
                                                                            value: birthday,
                                                                            message: translation.react.validations.birthday
                                                                        }
                                                                    }) }/>
                                                                    { errors.birthday && (
                                                                        <div className="form-group-error">{ errors.birthday.message }</div>
                                                                    ) }
                                                                </div>
                                                            </Column>
                                                        </Grid>
                                                    ) }

                                                    <Grid>
                                                        <Column md={ 12 }>
                                                            <div className={ `form-group${ errors.email && (' form-group--error') }` }>
                                                                <label htmlFor="email">{ translation.react.register.email }*</label>
                                                                <input disabled={ saving } type="text" className="form-control" id="email" name="email" defaultValue={ user.email } placeholder={ translation.react.register.emailPlace } ref={ register({
                                                                    required: translation.react.validations.required,
                                                                    pattern: {
                                                                        value: email,
                                                                        message: translation.react.validations.email
                                                                    }
                                                                }) }/>
                                                                { errors.email && (
                                                                    <div className="form-group-error">{ errors.email.message }</div>
                                                                ) }
                                                            </div>
                                                        </Column>
                                                        <Column md={ 12 }>
                                                            <div className={ `form-group${ errors.emailBilling && (' form-group--error') }` }>
                                                                <label htmlFor="emailBilling">{ translation.react.register.emailBilling }*</label>
                                                                <input disabled={ saving } type="text" className="form-control" id="emailBilling" name="emailBilling" defaultValue={ user.emailBilling } placeholder={ translation.react.register.emailBillingPlace } ref={ register({
                                                                    required: translation.react.validations.required,
                                                                    pattern: {
                                                                        value: email,
                                                                        message: translation.react.validations.email
                                                                    }
                                                                }) }/>
                                                                { errors.emailBilling && (
                                                                    <div className="form-group-error">{ errors.emailBilling.message }</div>
                                                                ) }
                                                            </div>
                                                        </Column>
                                                    </Grid>
                                                </Card>
                                            </Column>
                                            <Column xl={ 6 }>
                                                <h3>{ translation.react.user.edit.addressData }</h3>
                                                <Card>
                                                    <Grid className="mt-5">
                                                        <Column sm={ 12 }>
                                                            <div className="form-group">
                                                                <label htmlFor="country">{ translation.react.register.country }*</label>
                                                                <select name="country" id="country" defaultValue={ user.country } ref={ register }>
                                                                    <option value="DE">{ translation.react.global.germany }</option>
                                                                    <option value="AT">{ translation.react.global.austria }</option>
                                                                    <option value="CH">{ translation.react.global.switzerland }</option>
                                                                </select>
                                                            </div>
                                                        </Column>
                                                    </Grid>

                                                    <Grid>
                                                        <Column sm={ 8 } md={ 9 }>
                                                            <div className={ `form-group${ errors.street && (' form-group--error') }` }>
                                                                <label htmlFor="street">{ translation.react.register.street }*</label>
                                                                <input disabled={ saving } type="text" className="form-control" id="street" name="street" defaultValue={ user.street } placeholder={ translation.react.register.streetPlace } ref={ register({ required: translation.react.validations.required }) }/>
                                                                { errors.street && (
                                                                    <div className="form-group-error">{ errors.street.message }</div>
                                                                ) }
                                                            </div>
                                                        </Column>
                                                        <Column sm={ 4 } md={ 3 }>
                                                            <div className={ `form-group${ errors.houseNumber && (' form-group--error') }` }>
                                                                <label htmlFor="houseNumber">{ translation.react.register.houseNumber }*</label>
                                                                <input disabled={ saving } type="text" className="form-control" id="houseNumber" name="houseNumber" defaultValue={ user.houseNumber } placeholder={ translation.react.register.houseNumberPlace } ref={ register({ required: translation.react.validations.required }) }/>
                                                                { errors.houseNumber && (
                                                                    <div className="form-group-error">{ errors.houseNumber.message }</div>
                                                                ) }
                                                            </div>
                                                        </Column>
                                                    </Grid>

                                                    <Grid>
                                                        <Column sm={ 4 } md={ 3 }>
                                                            <div className={ `form-group${ errors.zipCode && (' form-group--error') }` }>
                                                                <label htmlFor="zipCode">{ translation.react.register.zip }*</label>
                                                                <input disabled={ saving } type="text" className="form-control" id="zipCode" name="zipCode" defaultValue={ user.zipCode } placeholder={ translation.react.register.zipPlace } ref={ register({
                                                                    required: translation.react.validations.required,
                                                                    pattern: {
                                                                        value: zip_de,
                                                                        message: translation.react.validations.zip
                                                                    }
                                                                }) }/>
                                                                { errors.zipCode && (
                                                                    <div className="form-group-error">{ errors.zipCode.message }</div>
                                                                ) }
                                                            </div>
                                                        </Column>
                                                        <Column sm={ 8 } md={ 9 }>
                                                            <div className={ `form-group${ errors.city && (' form-group--error') }` }>
                                                                <label htmlFor="city">{ translation.react.register.city }*</label>
                                                                <input disabled={ saving } type="text" className="form-control" id="city" placeholder={ translation.react.register.cityPlace } name="city" defaultValue={ user.city } ref={ register({ required: translation.react.validations.required }) }/>
                                                                { errors.city && (
                                                                    <div className="form-group-error">{ errors.city.message }</div>
                                                                ) }
                                                            </div>
                                                        </Column>
                                                    </Grid>

                                                    <Grid>
                                                        <Column md={ 12 }>
                                                            <div className={ `form-group${ errors.tel && (' form-group--error') }` }>
                                                                <label htmlFor="tel">{ translation.react.register.phone }*</label>
                                                                <input disabled={ saving } type="text" className="form-control" id="tel" name="tel" defaultValue={ user.tel } placeholder={ translation.react.register.phonePlace } ref={ register({
                                                                    required: translation.react.validations.required,
                                                                    pattern: {
                                                                        value: phone,
                                                                        message: translation.react.validations.phone
                                                                    }
                                                                }) }/>
                                                                { errors.tel && (
                                                                    <div className="form-group-error">{ errors.tel.message }</div>
                                                                ) }
                                                            </div>
                                                        </Column>
                                                    </Grid>
                                                </Card>
                                            </Column>
                                        </Grid>

                                        <Grid>
                                            <Column xl={ 6 }>
                                                <h3>{ translation.react.user.edit.paymentInformations }</h3>
                                                <Card>
                                                    <button disabled={ changePayment } type="button" className="btn btn--secondary" onClick={ handleChangePayment }>{ translation.react.user.edit.changePayment }{ changePayment && (<Spinner style={ { position: "relative", top: "3px" } } className="ml-2" size={ 20 } color="#ffffff"/>) }</button>
                                                </Card>
                                            </Column>
                                        </Grid>
                                        <button disabled={ saving || Object.keys(errors).length > 0 || formState.touched.length < 1 } type="submit" className="btn btn--primary mb-5 mr-4">{ translation.react.global.save }{ saving && (<Spinner style={ { position: "relative", top: "3px" } } className="ml-2" size={ 20 } color="#ffffff"/>) }</button>

                                    </div>
                                </Column>
                            </Grid>
                        </form>
                    ) }
                </Fragment>
            ) }
        </div>
    );
};

export default withTranslation(EditProfile, [ 'react' ]);
