import React, { Fragment, useContext } from "react";
import { useDidMount } from "react-hooks-lib";
import Skeleton from "react-loading-skeleton";

import withTranslation from "../../../Ultils/withTranslation";
import VolumeItem from "./VolumeItem";
import { ModalContext } from "../../../Context/ModalContextProvider";
import { VolumesContext } from "../../../Context/Api/Openstack/VolumesContextProvider";
import { ComputesContext } from "../../../Context/Api/Openstack/ComputesContextProvider";
import NewVolumeModal from "./Modal/NewVolumeModal";
import { EmptyGroup } from "../../Styling/EmptyGroup";

const Volumes = props => {
    const { translation } = props;
    const volumesContext = useContext(VolumesContext);
    const computesContext = useContext(ComputesContext);
    const modalContext = useContext(ModalContext);

    useDidMount(() => {
        computesContext.actions.fetchComputes();
        volumesContext.actions.fetchVolumes();
    });

    const handleRequestNewVolume = () => {
        modalContext.actions.setModalContent(NewVolumeModal, { volumes: volumesContext }, { maxWidth: '800px' });
    };

    return (
        <Fragment>
            { volumesContext.volumes.length > 0 && (
                <button className="btn btn--primary float-right" onClick={ handleRequestNewVolume }>{ translation.react.volume.newVolume }</button>
            ) }
            <h2 className="mb-4">{ translation.react.volume.volumes }</h2>
            { !volumesContext.loading ? (
                <Fragment>
                    { volumesContext.volumes.length > 0 ? (
                        <table className="table table--striped table--bordered mb-0">
                            <thead>
                            <tr>
                                <th scope="col" width={ 120 }>{ translation.react.global.size }</th>
                                <th scope="col" width={ 60 }>{ translation.react.global.type }</th>
                                <th scope="col" width={ 250 }>{ translation.react.global.name }</th>
                                <th scope="col">{ translation.react.global.server }</th>
                                <th scope="col" width={ 60 }></th>
                            </tr>
                            </thead>
                            <tbody>
                            { volumesContext.volumes.map(volume => (
                                <VolumeItem key={ volume.id } translation={ translation } volume={ volume }/>
                            )) }
                            </tbody>
                        </table>
                    ) : (
                        <EmptyGroup>
                            <svg className="svg-inline--fa fa-hdd fa-w-18 fa-3x" aria-hidden="true" focusable="false" data-prefix="far" data-icon="hdd" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                                <path fill="currentColor" d="M576 304v96c0 26.51-21.49 48-48 48H48c-26.51 0-48-21.49-48-48v-96c0-26.51 21.49-48 48-48h480c26.51 0 48 21.49 48 48zm-48-80a79.557 79.557 0 0 1 30.777 6.165L462.25 85.374A48.003 48.003 0 0 0 422.311 64H153.689a48 48 0 0 0-39.938 21.374L17.223 230.165A79.557 79.557 0 0 1 48 224h480zm-48 96c-17.673 0-32 14.327-32 32s14.327 32 32 32 32-14.327 32-32-14.327-32-32-32zm-96 0c-17.673 0-32 14.327-32 32s14.327 32 32 32 32-14.327 32-32-14.327-32-32-32z" className=""></path>
                            </svg>
                            <h3 className="mb-0">{ translation.react.volume.empty.title }</h3>
                            <p>{ translation.react.volume.empty.text }</p>
                            <button className="btn btn--primary mt-4 mb-0" onClick={ handleRequestNewVolume }>{ translation.react.volume.empty.buttontext }</button>
                        </EmptyGroup>
                    ) }
                </Fragment>
            ) : (
                <table className="table table--striped table--bordered mb-0">
                    <thead>
                    <tr>
                        <th scope="col" width={ 120 }>{ translation.react.global.size }</th>
                        <th scope="col" width={ 60 }>{ translation.react.global.type }</th>
                        <th scope="col" width={ 250 }>{ translation.react.global.name }</th>
                        <th scope="col">{ translation.react.global.server }</th>
                        <th scope="col" width={ 60 }></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td><Skeleton/></td>
                        <td><Skeleton/></td>
                        <td><Skeleton/></td>
                        <td><Skeleton/></td>
                        <td><Skeleton/></td>
                    </tr>
                    </tbody>
                </table>
            ) }
        </Fragment>
    );
};

export default withTranslation(Volumes, [ 'react', 'volumes' ]);
