import React, { useContext } from "react";
import { useForm } from "react-hook-form";
import PropTypes from "prop-types";
import { NotificationManager } from "react-notifications";

import { ModalContext } from "../../../../Context/ModalContextProvider";
import { SecurityGroupsContext } from "../../../../Context/Api/Openstack/SecurityGroupsContextProvider";
import { no_start_space_alpha } from "../../../../Ultils/RegexPatterns";
import withTranslation from "../../../../Ultils/withTranslation";

const NewSecurityGroupModal = props => {
    const modalContext = useContext(ModalContext);
    const { handleSubmit, errors, register, formState } = useForm();
    const securityGroupsContext = useContext(SecurityGroupsContext);
    const { translation } = props;

    const handleOnSubmit = securityGroupFormData => {
        // check if errors happen or if formState is valid to submit
        if (Object.keys(errors).length < 1 || formState.isValid) {
            securityGroupsContext.actions.createNewSecurityGroup(securityGroupFormData, securityGroupsContext.actions.fetchAllSecurityGroups);
            modalContext.actions.closeModal();
            NotificationManager.success(translation.react.securitygroup.create.success, translation.react.global.success);
        }
    };

    return (
        <div className="form-group">
            <h2>{ translation.react.securitygroup.create.title }</h2>
            <form onSubmit={ handleSubmit(handleOnSubmit) }>
                <div className={ `form-group${ errors.name && (' form-group--error') }` }>
                    <label htmlFor="name">{ translation.react.global.name }*</label>
                    <input type="text" id="name" name="name" ref={ register({
                        required: translation.react.validations.required,
                        pattern: {
                            value: no_start_space_alpha,
                            message: translation.react.validations.spaces
                        }
                    }) }/>
                    { errors.name && (
                        <div className="form-group-error">{ errors.name.message }</div>
                    ) }
                </div>
                <div className="form-group">
                    <label htmlFor="description">{ translation.react.global.description }</label>
                    <input type="text" id="description" name="description" ref={ register }/>
                </div>
                <button disabled={ Object.keys(errors).length > 0 } type="submit" className="btn btn--big btn--danger mr-4">{ translation.react.global.submit }</button>
                <button type="button" className="btn btn--secondary btn--big" onClick={ () => modalContext.actions.closeModal() }>{ translation.react.global.cancel }</button>
            </form>
        </div>
    );
};

NewSecurityGroupModal.propTypes = {
    securityGroups: PropTypes.shape({
        actions: PropTypes.object.isRequired
    }).isRequired
};

export default withTranslation(NewSecurityGroupModal, [ 'react' ]);
