import React, { Component, createContext } from "react";

import API from "../../../Api/Openstack";
import { ErrorContext } from "../../ErrorContextProvider";
import isEqual from "../../../Ultils/isEqual";

export const KeypairsContext = createContext({});

class KeypairsContextProvider extends Component {
    static contextType = ErrorContext;

    // default keypairs state
    state = {
        keypairs: [],
        loading: true,
    };

    /**
     * fetch Keypairs
     */
    fetchKeypairs = () => {
        return API.fetchKeypairs()
            .then(({ data }) => {
                // check if loading is true => set it to false
                if (this.state.loading) {
                    this.setState({ loading: false });
                }

                if (!isEqual(this.state.keypairs, Object.values(data))) {
                    this.setState({ keypairs: Object.values(data) });
                }
            })
            .catch(error => {
                // check if loading is true => set it to false
                if (this.state.loading) {
                    this.setState({ loading: false });
                }

                this.context.actions.showError(error);
                throw new Error(error);
            });
    };

    /**
     * Delete a keypair
     *
     * @param keypairName
     * @param callbackFn
     */
    deleteKeypair = (keypairName, callbackFn = () => {
    }) => {
        if (!keypairName) return;
        return API.deleteKeypair(keypairName)
            .then(() => {
                const newKeypairsArray = this.state.keypairs.filter(keypair => keypair.name !== keypairName);
                if (!isEqual(this.state.keypairs, newKeypairsArray)) {
                    this.setState({ keypairs: newKeypairsArray });
                }
                callbackFn();
            })
            .catch(error => {
                callbackFn();
                this.context.actions.showError(error);
                throw new Error(error);
            });
    };

    /**
     * @param data
     * @param callbackFn
     */
    createKeypair = (data, callbackFn = () => {
    }) => {
        if (!data) return;
        return API.createNewKeypair(data)
            .then(() => {
                callbackFn();
            })
            .catch(error => {
                callbackFn();
                throw new Error(error);
            });
    };

    render() {
        return (
            <KeypairsContext.Provider value={ {
                ...this.state,
                actions: {
                    fetchKeypairs: this.fetchKeypairs,
                    deleteKeypair: this.deleteKeypair,
                    createKeypair: this.createKeypair
                }
            } }>
                { this.props.children }
            </KeypairsContext.Provider>
        );
    }
}

export default KeypairsContextProvider;
