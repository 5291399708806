import React, { Component, createContext } from 'react';

import API from "../../../Api/Openstack";
import { ErrorContext } from "../../ErrorContextProvider";
import isEqual from "../../../Ultils/isEqual";

export const VolumesContext = createContext({});

class VolumesContextProvider extends Component {
    static contextType = ErrorContext;
    state = {
        volumes: [],
        loading: true,
    };

    /**
     * @returns {Q.Promise<any> | Promise<T>}
     */
    fetchVolumes = () => {
        return API.fetchVolumes()
            .then(({ data }) => {
                // check if loading is true => set it to false
                if (this.state.loading) {
                    this.setState({ loading: false });
                }

                if (!isEqual(this.state.volumes, Object.values(data))) {
                    this.setState({
                        volumes: Object.values(data)
                    });
                }
            }).catch(error => {
                // check if loading is true => set it to false
                if (this.state.loading) {
                    this.setState({ loading: false });
                }

                this.context.actions.showError(error);
                return new Error(error);
            });
    };

    /**
     * @param computeId
     */
    getVolumeForCompute = computeId => {
        return this.state.volumes.filter(volume => volume.server_id === computeId);
    };

    /**
     * @param volumeId
     * @param callbackFn
     * @returns {Q.Promise<any> | Promise<T>}
     */
    deleteVolume = (volumeId, callbackFn = () => {
    }) => {
        if (!volumeId) return;
        return API.deleteVolume(volumeId)
            .then(() => {
                const newVolumesArray = this.state.volumes.filter(volume => volume.id !== volumeId);
                if (!isEqual(this.state.volumes, newVolumesArray)) {
                    this.setState({ volumes: newVolumesArray });
                }
                callbackFn();
            })
            .catch(error => {
                this.context.actions.showError(error);
                return new Error(error);
            });
    };

    /**
     * @param data
     * @param callbackFn
     * @returns {Q.Promise<any> | Promise<T>}
     */
    createNewVolume = (data, callbackFn = () => {
    }) => {
        if (!data) return;
        return API.createNewVolume(data)
            .then(() => {
                callbackFn();
            })
            .catch(error => {
                callbackFn();
                throw new Error(error);
            });

    };

    /**
     * @param data
     * @param callbackFn
     * @returns {Q.Promise<any> | Promise<T>}
     */
    resizeVolume = (data, callbackFn = () => {
    }) => {
        if (!data) return;
        return API.resizeVolume(data)
            .then(() => {
                callbackFn();
            })
            .catch(error => {
                callbackFn();
                throw new Error(error);
            });

    };

    render() {
        return (
            <VolumesContext.Provider value={ {
                ...this.state,
                actions: {
                    fetchVolumes: this.fetchVolumes,
                    getVolumeForCompute: this.getVolumeForCompute,
                    deleteVolume: this.deleteVolume,
                    createNewVolume: this.createNewVolume,
                    resizeVolume: this.resizeVolume,
                }
            } }>
                { this.props.children }
            </VolumesContext.Provider>
        );
    }
}

export default VolumesContextProvider;
