import React from 'react';

import ContextProviderComposer from '../Ultils/ContexProviderComposer';
import AuthContextProvider from "./Api/Openstack/AuthContextProvider";
import ComputesContextProvider from "./Api/Openstack/ComputesContextProvider";
import SnapshotsContextProvider from "./Api/Openstack/SnapshotsContextProvider";
import LogsContextProvider from "./Api/Openstack/LogsContextProvider";
import FloatingsContextProvider from "./Api/Openstack/FloatingsContextProvider";
import FlavorsContextProvider from "./Api/Openstack/FlavorsContextProvider";
import TranslationContextProvider from "./Api/Openstack/TranslationContextProvider";
import ModalContextProvider from "./ModalContextProvider";
import SecurityGroupsContextProvider from "./Api/Openstack/SecurityGroupsContextProvider";
import ErrorContextProvider from "./ErrorContextProvider";
import SecurityGroupRulesContextProvider from "./Api/Openstack/SecurityGroupRulesContextProvider";
import BillingContextProvider from "./Api/Openstack/BillingContextProvider";
import ImagesContextProvider from "./Api/Openstack/ImagesContextProvider";
import KeypairsContextProvider from "./Api/Openstack/KeypairsContextProvider";
import VolumesContextProvider from "./Api/Openstack/VolumesContextProvider";
import UserContextProvider from "./Api/Openstack/UserContextProvider";
import CrefoPayContextProvider from "./Api/Openstack/CrefoPayContextProvider";

const GlobalAppState = props => {
    return (
        <ContextProviderComposer contextProviders={ [
            <TranslationContextProvider/>,
            <ErrorContextProvider/>,
            <UserContextProvider/>,
            <AuthContextProvider/>,
            <CrefoPayContextProvider/>,
            <BillingContextProvider/>,
            <ComputesContextProvider/>,
            <ImagesContextProvider/>,
            <LogsContextProvider/>,
            <SnapshotsContextProvider/>,
            <ModalContextProvider/>,
            <FloatingsContextProvider/>,
            <FlavorsContextProvider/>,
            <SecurityGroupsContextProvider/>,
            <SecurityGroupRulesContextProvider/>,
            <KeypairsContextProvider/>,
            <VolumesContextProvider/>
        ] }>
            { props.children }
        </ContextProviderComposer>
    );
};

export default GlobalAppState;
