import React, { Fragment, useContext, useEffect, useRef } from "react";
import { useDidMount, useWillUnmount } from "react-hooks-lib";
import Skeleton from "react-loading-skeleton";

import withTranslation from "../../../../Ultils/withTranslation";
import SecurityGroupRuleItem from "./SecurityGroupRuleItem";
import NewSecurityGroupRuleModal from "./Modal/NewSecurityGroupRuleModal"
import { ModalContext } from "../../../../Context/ModalContextProvider";
import { SecurityGroupsContext } from "../../../../Context/Api/Openstack/SecurityGroupsContextProvider";
import { SecurityGroupRulesContext } from "../../../../Context/Api/Openstack/SecurityGroupRulesContextProvider";
import { EmptyGroup } from "../../../Styling/EmptyGroup";

const SecurityGroupRules = props => {
    let fetchInterval = useRef();
    const securityGroupContext = useContext(SecurityGroupsContext);
    const securityGroupRulesContext = useContext(SecurityGroupRulesContext);
    const modalContext = useContext(ModalContext);
    const { translation, match: { params: { id: groupId } } } = props;

    useDidMount(() => {
        securityGroupRulesContext.actions.setLoading(true);

        // fetch all SecurityGroups
        if (securityGroupContext.securitygroups.length < 1) {
            const fetchSecurityGroups = async () => {
                try {
                    await securityGroupContext.actions.fetchAllSecurityGroups();
                } catch (e) {
                }
            };
            fetchSecurityGroups();
        }

        securityGroupRulesContext.actions.fetchSecurityGroupRules(groupId);
    });

    // clear and reinitialize interval
    useEffect(() => {
        clearInterval(fetchInterval.current);
        fetchInterval.current = setInterval(async () => {
            await securityGroupRulesContext.actions.fetchSecurityGroupRules(groupId);
        }, 3000);
    }, [ fetchInterval, securityGroupRulesContext.actions, groupId ]);

    // clear and reinitialize interval
    useWillUnmount(() => {
        securityGroupRulesContext.actions.clearSecurityGroupRules();
        clearInterval(fetchInterval.current);
    });

    const handleRequestNewSecurityGroupRule = () => {
        modalContext.actions.setModalContent(NewSecurityGroupRuleModal, { securitygroupId: groupId }, { maxWidth: '600px' });
    };

    return (
        <Fragment>
            { securityGroupRulesContext.securityGroupRules.length > 0 && (
                <button className="btn btn--primary float-right mb-4" onClick={ handleRequestNewSecurityGroupRule }>{ translation.react.securityrule.new }</button>
            ) }
            <h2 className="mb-4">{ securityGroupContext.actions.getSecurityGroupNameById(groupId) } { translation.react.securitygroup.securitygroups } - { translation.react.securityrule.rules }</h2>
            { !securityGroupRulesContext.loading ? (
                <Fragment>
                    { securityGroupRulesContext.securityGroupRules.length > 0 ? (
                        <table className="table table--striped table--bordered">
                            <thead>
                            <tr>
                                <th scope="col">{ translation.react.securityrule.direction }</th>
                                <th scope="col">{ translation.react.securityrule.ethertype }</th>
                                <th scope="col">{ translation.react.securityrule.protocol }</th>
                                <th scope="col">{ translation.react.securityrule.portRangeMin }</th>
                                <th scope="col">{ translation.react.securityrule.portRangeMax }</th>
                                <th scope="col">{ translation.react.securityrule.remoteIP }</th>
                                <th scope="col" width={ 60 }></th>
                            </tr>
                            </thead>
                            <tbody>
                            { securityGroupRulesContext.securityGroupRules.map(securitygrouprule => (
                                <SecurityGroupRuleItem key={ securitygrouprule.id } securitygrouprule={ securitygrouprule } securitygroupId={ groupId } translation={ translation }/>
                            )) }
                            </tbody>
                        </table>
                    ) : (
                        <EmptyGroup>
                            <svg className="svg-inline--fa fa-fire-alt fa-w-14 fa-3x" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="fire-alt" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                <path fill="currentColor" d="M323.56 51.2c-20.8 19.3-39.58 39.59-56.22 59.97C240.08 73.62 206.28 35.53 168 0 69.74 91.17 0 209.96 0 281.6 0 408.85 100.29 512 224 512s224-103.15 224-230.4c0-53.27-51.98-163.14-124.44-230.4zm-19.47 340.65C282.43 407.01 255.72 416 226.86 416 154.71 416 96 368.26 96 290.75c0-38.61 24.31-72.63 72.79-130.75 6.93 7.98 98.83 125.34 98.83 125.34l58.63-66.88c4.14 6.85 7.91 13.55 11.27 19.97 27.35 52.19 15.81 118.97-33.43 153.42z" className=""></path>
                            </svg>
                            <h3 className="mb-0">{ translation.react.securityrule.empty.title }</h3>
                            <p>{ translation.react.securityrule.empty.text }</p>
                            <button className="btn btn--primary mt-4 mb-0" onClick={ handleRequestNewSecurityGroupRule }>{ translation.react.securityrule.empty.buttontext }</button>
                        </EmptyGroup>
                    ) }
                </Fragment>
            ) : (
                <table className="table table--striped table--bordered">
                    <thead>
                    <tr>
                        <th scope="col">{ translation.react.securityrule.direction }</th>
                        <th scope="col">{ translation.react.securityrule.ethertype }</th>
                        <th scope="col">{ translation.react.securityrule.protocol }</th>
                        <th scope="col">{ translation.react.securityrule.portRangeMin }</th>
                        <th scope="col">{ translation.react.securityrule.portRangeMax }</th>
                        <th scope="col">{ translation.react.securityrule.remoteIP }</th>
                        <th scope="col" width={ 60 }></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td><Skeleton/></td>
                        <td><Skeleton/></td>
                        <td><Skeleton/></td>
                        <td><Skeleton/></td>
                        <td><Skeleton/></td>
                        <td><Skeleton/></td>
                        <td><Skeleton/></td>
                    </tr>
                    </tbody>
                </table>
            ) }
        </Fragment>
    );
};

export default withTranslation(SecurityGroupRules, [ 'react' ]);
