import axios from "axios";

import * as Auth from './Modules/Auth';
import * as Billing from './Modules/Billing';
import * as Computes from './Modules/Computes';
import * as Flavors from './Modules/Flavors';
import * as Floats from './Modules/Floats';
import * as Images from './Modules/Images';
import * as Keypairs from './Modules/Keypairs';
import * as Logs from './Modules/Logs';
import * as l10n from './Modules/l10n';
import * as Snapshots from './Modules/Snapshots';
import * as SecurityGroups from './Modules/SecurityGroups';
import * as SecurityGroupRules from './Modules/SecurityGroupRules';
import * as Volumes from './Modules/Volumes';
import * as User from './Modules/User';

export default {
    ...Auth,
    ...Billing,
    ...Computes,
    ...Flavors,
    ...Floats,
    ...Images,
    ...Keypairs,
    ...Logs,
    ...l10n,
    ...Snapshots,
    ...SecurityGroups,
    ...SecurityGroupRules,
    ...User,
    ...Volumes,
};

// Add a axios request interceptor
axios.interceptors.request.use(function (config) {

    // add token to every request if it's not the locale request
    if (!config.url.includes("locale")) {
        const token = window.sessionStorage.jwt;
        const expires = window.sessionStorage.expires;

        //check if jwt token is available and set it
        config.headers = {
            ...config.headers,
            'X-Auth-Jwt': token
        };

        // check if token is expired => redirect to login
        if (!config.url.includes("locale") && Math.floor(Date.now() / 1000) > expires) {
            window.location = "/";
        }
    }

    return config;
}, function (error) {
    // Do something with request error
    return Promise.reject(error);
});
