import React, { useContext } from "react";
import { useForm } from "react-hook-form";
import PropTypes from "prop-types";

import withTranslation from "../../../../Ultils/withTranslation";
import { ComputesContext } from "../../../../Context/Api/Openstack/ComputesContextProvider";
import { ModalContext } from "../../../../Context/ModalContextProvider";
import { Column, Grid } from "../../../Styling/Grid";
import { NotificationManager } from "react-notifications";

const AssignSecurityGroupToComputeModal = props => {
    const { handleSubmit, errors, register, formState } = useForm();
    const computesContext = useContext(ComputesContext);
    const modalContext = useContext(ModalContext);

    const { securityGroups, computeId, translation } = props;

    const handleOnSubmit = securityGroupFormData => {
        // check if errors happen or if formState is valid to submit
        if (Object.keys(errors).length < 1 || formState.isValid) {
            let data = { form: Object.values(securityGroupFormData).filter(group => group !== false) };
            computesContext.actions.setSecurityGroupsToCompute(computeId, data);
            modalContext.actions.closeModal();
            NotificationManager.success(translation.react.compute.securityGroups.success, translation.react.global.success);
        }
    };

    return (
        <div className="form-group">
            <h2>{ translation.react.compute.assignGroups }</h2>
            <form onSubmit={ handleSubmit(handleOnSubmit) }>
                <Grid>
                    { securityGroups.map(securitygroup => (
                        <Column key={ securitygroup.id } xs={ 4 } md={ 4 } xl={ 4 } xxl={ 4 }>
                            <div className="form-group" key={ `${ securitygroup.id }` }>
                                <input type="checkbox" name={ securitygroup.id } id={ `check${ securitygroup.name }` } value={ securitygroup.id } className="form-control" defaultChecked={ computesContext.actions.checkIfGroupIsActive(securitygroup.id) } ref={ register }/>
                                <label htmlFor={ `check${ securitygroup.name }` } className="form-control">{ securitygroup.name }</label>
                            </div>
                        </Column>
                    )) }
                </Grid>
                <button disabled={ Object.keys(errors).length > 0 } type="submit" className="btn btn--big btn--danger mr-3">{ translation.react.global.save }</button>
                <button onClick={ modalContext.actions.closeModal } className="btn btn--big btn--secondary">{ translation.react.global.cancel }</button>
            </form>
        </div>
    );
};

AssignSecurityGroupToComputeModal.propTypes = {
    computeId: PropTypes.string.isRequired,
    securityGroups: PropTypes.array.isRequired
};

export default withTranslation(AssignSecurityGroupToComputeModal, [ 'react' ]);
