import axios from "axios";
import API_CONST from "../constants";

/**
 * authenticate action
 *
 * @param username
 * @param password
 * @returns {AxiosPromise<any>}
 */
const authenticate = (username, password) => {
    if (!username || !password) return;
    return axios.post(`${ API_CONST.AUTH_URL }${ username }`, { password });
};

export {
    authenticate,
}
