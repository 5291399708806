import React, { Fragment, useContext, useState } from 'react';
import { Link, Redirect, Route, Switch } from 'react-router-dom';
import { useDidMount, useWillUnmount } from "react-hooks-lib";
import Modal from "react-modal";
import Collapse, { Panel } from 'rc-collapse';

import { AuthContext } from "../Context/Api/Openstack/AuthContextProvider";
import { ModalContext } from "../Context/ModalContextProvider";
import withTranslation from "../Ultils/withTranslation";
import ListItemLink from "../Ultils/ListItemLink";
import isMobileViewPort from "../Ultils/isMobileViewPort";
import SecurityGroups from "../Components/Dashboard/SecurityGroups";
import SecurityGroupRules from "../Components/Dashboard/SecurityGroups/Rules";
import Snapshots from "../Components/Dashboard/Snapshots";
import Computes from "../Components/Dashboard/Computes";
import { NewCompute } from "../Components/Dashboard/Computes/New";
import Floating from "../Components/Dashboard/Floating";
import Keypairs from "../Components/Dashboard/Keypairs";
import Volumes from "../Components/Dashboard/Volumes";
import Logo from '../Assets/Images/mobi-logo-dashboard.svg';
import { Account, LanguageMenu, Support } from "../Components/Dashboard/Header";
import { UserContext } from "../Context/Api/Openstack/UserContextProvider";
import Billings from "../Components/Dashboard/Billing/Billings";
import InterimBilling from "../Components/Dashboard/Billing/Interim/InterimBilling";
import Profile from "../Components/Dashboard/User/EditProfile";

// set root element for modal accessibility
Modal.setAppElement('#root');

const Dashboard = props => {
    const authContext = useContext(AuthContext);
    const modalContext = useContext(ModalContext);
    const userContext = useContext(UserContext);
    const ModalContent = modalContext.modalContentComponent;
    const ModalContentProps = modalContext.modalContentProps;
    const [ sidebarState, setSidebarState ] = useState({ sidebarOpen: true });

    const { translation, history } = props;

    let resizeTimer;
    const updateDimensions = () => {
        // only trigger resize after 250ms pause and not on every pixel! => performance
        clearTimeout(resizeTimer);
        resizeTimer = setTimeout(function () {
            //toggle sidebar if window with is for mobile devices
            if (isMobileViewPort()) {
                //if sidebar is open => close
                setSidebarState({ sidebarOpen: false });
            }
        }, 250);
    };

    // componendDidMount and componentDidUnmount
    useDidMount(() => {
        updateDimensions();
        userContext.actions.getUser();
        window.addEventListener('resize', updateDimensions);
    });

    useWillUnmount(() => {
        window.removeEventListener('resize', updateDimensions);
    });

    return (
        <Fragment>
            { Object.keys(translation).length !== 0 && (
                <div className={ `dashboard${ sidebarState.sidebarOpen ? ' dashboard--sidebar-open' : '' }` }>
                    <div className="dashboard__sidebar">
                        { authContext.loggedIn && (
                            <Fragment>
                                <div className="dashboard__sidebar-header">
                                    <Link to={ '/dashboard/computes' }>
                                        <img className="logo" src={ Logo } width={ 300 } alt="Mobilistics"/>
                                    </Link>
                                </div>
                                <Collapse defaultActiveKey={ [ '0', '1' ] }>
                                    <Panel header={ translation.react.global.manage }>
                                        <ul className="main-navigation">
                                            <ListItemLink to={ `/dashboard/computes` }>{ translation.react.compute.computes }</ListItemLink>
                                            <ListItemLink to={ `/dashboard/volumes` }>{ translation.react.volume.volumes }</ListItemLink>
                                            <ListItemLink to={ `/dashboard/floating` }>{ translation.react.float.floatingIps }</ListItemLink>
                                            <ListItemLink to={ `/dashboard/snapshots` }>{ translation.react.snapshot.snapshots }</ListItemLink>
                                            <ListItemLink to={ `/dashboard/securitygroups` }>{ translation.react.securitygroup.securitygroups }</ListItemLink>
                                        </ul>
                                    </Panel>
                                    <Panel header={ translation.react.global.account }>
                                        <ul className="main-navigation">
                                            <ListItemLink to={ `/dashboard/profile/edit` }>{ translation.react.user.edit.editProfile }</ListItemLink>
                                            <ListItemLink to={ `/dashboard/billings/interim` }>{ translation.react.billing.interimBilling }</ListItemLink>
                                            <ListItemLink to={ `/dashboard/billings` } exact={ true }>{ translation.react.billing.billings }</ListItemLink>
                                            <ListItemLink to={ `/dashboard/keypairs` }>{ translation.react.keypair.keypairs }</ListItemLink>
                                        </ul>
                                    </Panel>
                                </Collapse>
                            </Fragment>
                        ) }
                    </div>
                    <div className="dashboard__content-area">
                        <div className="dashboard__top-bar">
                            <Support/>
                            <LanguageMenu/>
                            <Account history={ history } user={ userContext.user }/>
                        </div>
                        <div className="dashboard__content-main">
                            <Switch>
                                <Route path={ `/dashboard/computes` } exact={ true } component={ Computes }/>
                                <Route path={ `/dashboard/computes/new` } exact={ true } component={ NewCompute }/>
                                <Route path={ `/dashboard/snapshots` } exact={ true } component={ Snapshots }/>
                                <Route path={ `/dashboard/securitygroups` } exact={ true } component={ SecurityGroups }/>
                                <Route path={ `/dashboard/securitygroups/:id` } exact={ true } component={ SecurityGroupRules }/>
                                <Route path={ `/dashboard/floating` } exact={ true } component={ Floating }/>
                                <Route path={ `/dashboard/keypairs` } exact={ true } component={ Keypairs }/>
                                <Route path={ `/dashboard/volumes` } exact={ true } component={ Volumes }/>
                                <Route path={ `/dashboard/billings` } exact={ true } component={ Billings }/>
                                <Route path={ `/dashboard/billings/interim` } exact={ true } component={ InterimBilling }/>
                                <Route path={ `/dashboard/profile` } exact={ true } component={ () => <Redirect to={ '/dashboard/computes' }/> }/>
                                <Route path={ `/dashboard/profile/edit` } exact={ true } component={ Profile }/>
                                <Route component={ () => <Redirect to={ '/dashboard/computes' }/> }/>
                            </Switch>
                            <Modal
                                isOpen={ modalContext.isOpen }
                                onRequestClose={ modalContext.actions.closeModal }
                                style={ modalContext.modalStyles }
                                shouldCloseOnOverlayClick={ true }
                            >
                                <ModalContent { ...ModalContentProps }/>
                            </Modal>
                        </div>
                    </div>
                    <button hidden={ !authContext.loggedIn } className="dashboard__sidebar-toggle" onClick={ () => setSidebarState({ sidebarOpen: !sidebarState.sidebarOpen }) }>
                        { sidebarState.sidebarOpen ? (
                            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="arrow-left" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                <path fill="currentColor" d="M257.5 445.1l-22.2 22.2c-9.4 9.4-24.6 9.4-33.9 0L7 273c-9.4-9.4-9.4-24.6 0-33.9L201.4 44.7c9.4-9.4 24.6-9.4 33.9 0l22.2 22.2c9.5 9.5 9.3 25-.4 34.3L136.6 216H424c13.3 0 24 10.7 24 24v32c0 13.3-10.7 24-24 24H136.6l120.5 114.8c9.8 9.3 10 24.8.4 34.3z"></path>
                            </svg>
                        ) : (
                            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="arrow-right" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                <path fill="currentColor" d="M190.5 66.9l22.2-22.2c9.4-9.4 24.6-9.4 33.9 0L441 239c9.4 9.4 9.4 24.6 0 33.9L246.6 467.3c-9.4 9.4-24.6 9.4-33.9 0l-22.2-22.2c-9.5-9.5-9.3-25 .4-34.3L311.4 296H24c-13.3 0-24-10.7-24-24v-32c0-13.3 10.7-24 24-24h287.4L190.9 101.2c-9.8-9.3-10-24.8-.4-34.3z"></path>
                            </svg>
                        ) }
                    </button>
                </div>
            ) }
        </Fragment>
    );
};

export default withTranslation(Dashboard, [ 'react' ]);
