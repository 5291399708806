import axios from "axios";
import API_CONST from "../constants";

/**
 * Create a new security group rule
 *
 * @param data
 * @returns {AxiosPromise<any>}
 */
const createNewSecurityGroupRule = data => {
    return axios.post(`${ API_CONST.SECURITYGROUPS_URL }/${ data.securitygroupId }/rule`, data);
};

/**
 * Delete Security Group Rule
 *
 * @param securitygroupruleId
 * @returns {AxiosPromise}
 */
const deleteSecurityGroupRule = securitygroupruleId => {
    return axios.delete(`${ API_CONST.SECURITYGROUPS_URL }/rule/${ securitygroupruleId }`);
};

export {
    createNewSecurityGroupRule,
    deleteSecurityGroupRule
}
