import React from 'react';
import PropTypes from 'prop-types';
import { Link, Route } from "react-router-dom";

const ListItemLink = props => {
    const { to, exact, strict, activeClassName, className, activeStyle, style, isActive: getIsActive, ...rest } = props;

    return (
        <Route
            path={ typeof to === 'object' ? to.pathname : to }
            exact={ exact }
            strict={ strict }
            children={ ({ location, match }) => {
                const isActive = !!(getIsActive ? getIsActive(match, location) : match);

                let activeClass = activeClassName || "active";
                let classNames = className || "";
                let activeStyles = activeStyle || null;

                return (
                    <li
                        className={ isActive ? [ activeClass, classNames ].join(' ') : classNames }
                        style={ isActive ? { ...style, ...activeStyles } : style }>
                        <Link
                            to={ to }
                            { ...rest }
                        />
                    </li>
                )
            } }
        />
    );
};

ListItemLink.propTypes = {
    to: PropTypes.string.isRequired
};

export default ListItemLink;
