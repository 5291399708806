import React, { Component } from "react";

class NotFound extends Component {
    componentDidMount() {
        setTimeout(() => {
            this.props.history.push('/');
        }, 5000);
    }

    render() {
        return (
            <div className="container mt-6">
                <h1>Seite kann nicht gefunden werden.</h1>
                <p>Sie werden automatisch weiterleitet ...</p>
            </div>
        );
    }
}

export default NotFound;
