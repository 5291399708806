import axios from "axios";
import API_CONST from "../constants";

/**
 * create compute
 * @param computeData
 * @returns {AxiosPromise<any>}
 */
const createCompute = computeData => {
    if (!computeData) return;
    return axios.post(API_CONST.COMPUTE_URL, computeData);
};

/**
 * fetch computes action
 *
 * @returns {AxiosPromise<any>}
 */
const fetchComputes = () => {
    return axios.get(API_CONST.COMPUTE_URL);
};

/**
 * start compute action
 *
 * @param computeId
 * @returns {AxiosPromise<any>}
 */
const startCompute = computeId => {
    if (!computeId) return;
    return axios.post(`${ API_CONST.COMPUTE_URL }/${ computeId }/start`, null);
};

/**
 * shutdown compute action
 *
 * @param computeId
 * @returns {AxiosPromise<any>}
 */
const shutdownCompute = computeId => {
    if (!computeId) return;
    return axios.post(`${ API_CONST.COMPUTE_URL }/${ computeId }/shutdown`, null);
};

/**
 * delete compute action
 *
 * @param computeId
 * @returns {*}
 */
const deleteCompute = computeId => {
    if (!computeId) return;
    return axios.delete(`${ API_CONST.COMPUTE_URL }/${ computeId }`);
};

/**
 * resize flavor action
 *
 * @param computeId
 * @param data
 * @returns {AxiosPromise<any>}
 */
const resizeComputeFlavor = (computeId, data) => {
    if (!computeId && data) return;
    return axios.post(`${ API_CONST.COMPUTE_URL }/${ computeId }/resize`, data);
};

/**
 *
 * @param data
 * @returns {AxiosPromise<any>}
 */
const confirmResize = data => {
    if (!data) return;
    return axios.post(`${ API_CONST.COMPUTE_URL }/${ data.id }/confirmresize`, data);
};

/**
 *
 * @param data
 * @returns {AxiosPromise<any>}
 */
const resetResize = data => {
    if (!data) return;
    return axios.post(`${ API_CONST.COMPUTE_URL }/${ data.id }/resetresize`, data);
};

/**
 * Attach a volume to a server
 *
 * @param data
 * @returns {AxiosPromise<any>}
 */
const attachVolume = data => {
    if (!data) return;
    return axios.post(`${ API_CONST.COMPUTE_URL }/${ data.ComputeId }/volume`, data);
};

/**
 * Detach Volume from a server
 *
 * @param data
 * @returns {*}
 */
const detachVolume = data => {
    if (!data) return;
    return axios.delete(`${ API_CONST.COMPUTE_URL }/${ data.ComputeId }/volume/${ data.volume_id }`);
};

/**
 *
 * @param computeId
 * @returns {AxiosPromise<any>}
 */
const getAttachedSecurityGroups = computeId => {
    if (!computeId) return;
    return axios.get(`${ API_CONST.COMPUTE_URL }/${ computeId }/security_groups`);
};

/**
 *
 * @param computeId
 * @param data
 * @returns {AxiosPromise<any>}
 */
const setSecurityGroupsToCompute = (computeId, data) => {
    if (!computeId || !data) return;
    return axios.post(`${ API_CONST.COMPUTE_URL }/${ computeId }/security_groups`, data);
};

export {
    createCompute,
    fetchComputes,
    startCompute,
    shutdownCompute,
    deleteCompute,
    resizeComputeFlavor,
    confirmResize,
    resetResize,
    attachVolume,
    detachVolume,
    getAttachedSecurityGroups,
    setSecurityGroupsToCompute
}
