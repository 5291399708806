import axios from "axios";
import API_CONST from "../constants";

/**
 * fetch logs action
 *
 * @param page
 * @param amount
 * @returns {AxiosPromise<any>}
 */
const fetchLogs = (page, amount) => {
    return axios.get(`${ API_CONST.LOGS_URL }${ page }/${ amount }`);
};

export {
    fetchLogs,
}
