import React from "react";
//import { TranslationContext } from "../../../Context/Api/Openstack/TranslationContextProvider";
import withTranslation from "../../../Ultils/withTranslation";

const LanguageMenu = props => {
    //const l10nContext = useContext(TranslationContext);
    //const { translation } = props;

    return (
        <span></span>
    );

    /*
    return (
        <Fragment>
            <a href="#EN" hidden={ translation.locale === 'en' } style={ { alignSelf: 'center' } } onClick={ e => {
                e.preventDefault();
                l10nContext.actions.getTranslationWithId('en');
            } }>EN</a>
            <a href="#DE" hidden={ translation.locale === 'de' } style={ { alignSelf: 'center' } } onClick={ e => {
                e.preventDefault();
                l10nContext.actions.getTranslationWithId('de');
            } }>DE</a>
        </Fragment>
    )
     */
};

export default withTranslation(React.memo(LanguageMenu));
