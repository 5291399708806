import React, { Component, createContext } from "react";
import { saveAs } from "file-saver";
import moment from "moment";

import API from "../../../Api/Openstack";
import { ErrorContext } from "../../ErrorContextProvider";
import isEqual from "../../../Ultils/isEqual";

export const BillingContext = createContext({});

class BillingContextProvider extends Component {
    static contextType = ErrorContext;
    state = {
        prices: {},
        billings: [],
        interim: {
            items: null,
            summary: {}
        },
        loading: true,
    };

    fetchPrices = () => {
        return API.fetchPrices()
            .then(({ data }) => {
                // check if loading is true => set it to false
                if (this.state.loading) {
                    this.setState({ loading: false });
                }

                if (!isEqual(this.state.prices, data)) {
                    this.setState({
                        prices: data
                    });
                }
            }).catch(error => {
                // check if loading is true => set it to false
                if (this.state.loading) {
                    this.setState({ loading: false });
                }

                this.context.actions.showError(error);
                return new Error(error);
            });
    };

    fetchBillings = () => {
        return API.fetchBillings()
            .then(({ data }) => {
                // check if loading is true => set it to false
                if (this.state.loading) {
                    this.setState({ loading: false });
                }

                if (!isEqual(this.state.billings, data)) {
                    this.setState({
                        billings: data
                    });
                }
            }).catch(error => {
                // check if loading is true => set it to false
                if (this.state.loading) {
                    this.setState({ loading: false });
                }

                this.context.actions.showError(error);
                return new Error(error);
            });
    };

    fetchInterimBilling = () => {
        return API.fetchInterimBillings()
            .then(({ data }) => {
                // check if loading is true => set it to false
                if (this.state.loading) {
                    this.setState({ loading: false });
                }

                if (!isEqual(this.state.interim, data)) {
                    this.setState({
                        interim: {
                            items: [ ...data.items ],
                            summary: { ...data.summary }
                        }
                    });
                }
            }).catch(error => {
                // check if loading is true => set it to false
                if (this.state.loading) {
                    this.setState({ loading: false });
                }

                this.context.actions.showError(error);
                throw new Error(error);
            });
    };

    /**
     * @param billing
     */
    getBillingAsPdf = billing => {
        return API.getBillingAsPdf(billing.number)
            .then(({ data }) => {
                // check if loading is true => set it to false
                if (this.state.loading) {
                    this.setState({ loading: false });
                }

                // start downloading pdf
                saveAs(data, `Rechnung-${ moment(billing.begin).format('MM-YYYY') }.pdf`);
            }).catch((error) => {
                // check if loading is true => set it to false
                if (this.state.loading) {
                    this.setState({ loading: false });
                }

                this.context.actions.showError(error);
                throw new Error(error);
            });
    };

    /**
     *
     * @param priceObject
     * @param multiplier
     * @returns {string}
     */
    getPricingPerMonth = (priceObject = {}, multiplier = 1) => {
        if (priceObject === undefined) return;

        let pricePerMonth = (priceObject.price * 60 * 24 * 30) * multiplier;
        return (Math.round(pricePerMonth * 100) / 100).toFixed(2);
    };

    render() {
        return (
            <BillingContext.Provider value={ {
                ...this.state,
                actions: {
                    fetchPrices: this.fetchPrices,
                    fetchBillings: this.fetchBillings,
                    fetchInterimBilling: this.fetchInterimBilling,
                    getBillingAsPdf: this.getBillingAsPdf,
                    getPricingPerMonth: this.getPricingPerMonth,
                }
            } }>
                { this.props.children }
            </BillingContext.Provider>
        );
    }
}

export default BillingContextProvider;
