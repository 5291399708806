import React from "react";
import PropTypes from 'prop-types';

export const Grid = React.memo(props => {
    const { className = '', children, setElRef = null, ...rest } = props;

    return (
        <div className={ `grid ${ className ? className : '' }` } ref={ setElRef } { ...rest } >
            { children }
        </div>
    );
});

Grid.propTypes = {
    className: PropTypes.string,
    setElRef: PropTypes.object,
};
