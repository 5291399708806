import React, { Component, createContext } from 'react';

import API from "../../../Api/Openstack";
import { ErrorContext } from "../../ErrorContextProvider";
import isEqual from "../../../Ultils/isEqual";

export const ImagesContext = createContext({});

class ImagesContextProvider extends Component {
    static contextType = ErrorContext;
    state = {
        images: [],
        loading: true,
    };

    fetchImages = () => {
        return API.fetchImages()
            .then(({ data }) => {
                // check if loading is true => set it to false
                if (this.state.loading) {
                    this.setState({ loading: false });
                }

                if (!isEqual(this.state.images, Object.values(data))) {
                    this.setState({
                        images: Object.values(data)
                    });
                }
            }).catch(error => {
                // check if loading is true => set it to false
                if (this.state.loading) {
                    this.setState({ loading: false });
                }

                this.context.actions.showError(error);
                throw new Error(error);
            });
    };

    render() {
        return (
            <ImagesContext.Provider value={ {
                ...this.state,
                actions: {
                    fetchImages: this.fetchImages,
                }
            } }>
                { this.props.children }
            </ImagesContext.Provider>
        );
    }
}

export default ImagesContextProvider;
