import React, { useContext, useState } from "react";
import { Link } from 'react-router-dom';
import PropTypes from "prop-types";

import ConfirmationModal from "../../Modal/ConfirmationModal";
import { SecurityGroupsContext } from "../../../Context/Api/Openstack/SecurityGroupsContextProvider";
import { ModalContext } from "../../../Context/ModalContextProvider";
import { Dropdown } from "../../Styling/Dropdown";
import { NotificationManager } from "react-notifications";

const SecurityGroupItem = props => {
    const securityGroupsContext = useContext(SecurityGroupsContext);
    const modalContext = useContext(ModalContext);
    const { securitygroup, translation } = props;
    const [ isBusy, setIsBusy ] = useState(false);

    const deleteSecurityGroup = async securitygroupId => {
        if (!securitygroupId) return;

        // set busy state to deactivate delete button
        setIsBusy(true);

        try {
            await securityGroupsContext.actions.deleteSecurityGroup(securitygroupId);
            NotificationManager.success(translation.react.securitygroup.delete.success, translation.react.global.success);
        } catch (e) {

        }
    };

    const handleDeleteSecurityGroup = securitygroupId => {
        modalContext.actions.setModalContent(ConfirmationModal, {
            confirmAction: () => deleteSecurityGroup(securitygroupId),
            title: translation.react.securitygroup.delete.title,
            action: translation.react.global.delete,
            cancel: translation.react.global.cancel
        }, { maxWidth: '330px' });
    };

    return (
        <tr>
            <td data-label={ `${ translation.react.global.name }:` }><Link to={ `/dashboard/securitygroups/${ securitygroup.id }` }><b>{ securitygroup.name }</b></Link></td>
            <td data-label={ `${ translation.react.global.description }:` }>{ securitygroup.description }</td>
            <td data-label={ `${ translation.react.global.action }:` } className="ta-r">
                <Dropdown
                    dropdownAlignment="right-desktop"
                    arrowAlignment="right-desktop"
                >
                    <ul>
                        <li>
                            <button disabled={ isBusy } onClick={ () => handleDeleteSecurityGroup(securitygroup.id) } className="btn"><i className="far fa-trash-alt" aria-label="Delete"/> { translation.react.global.delete }</button>
                        </li>
                    </ul>
                </Dropdown>
            </td>
        </tr>
    );
};

SecurityGroupItem.propTypes = {
    translation: PropTypes.object.isRequired,
    securitygroup: PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        description: PropTypes.string
    })
};
export default React.memo(SecurityGroupItem);
