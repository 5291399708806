import React, { useContext } from 'react';

import Login from '../Components/Dashboard/Login';
import { TranslationContext } from "../Context/Api/Openstack/TranslationContextProvider";

const LoginPage = props => {
    const translationContext = useContext(TranslationContext);

    return (
        <div className="page page--login">
            { Object.keys(translationContext.translation).length > 0 && (
                <Login { ...props } />
            ) }
        </div>
    );
};

export default LoginPage;
