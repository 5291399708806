import React from "react";
import PropTypes from 'prop-types';

const Card = props => {
    const { noBorder = false, className = '', CardHeader, ...rest } = props;
    return (
        <div className={ `card${ noBorder ? ' card--no-border' : '' } ${ className }` } { ...rest }>
            { CardHeader && (
                <div className="card__image">
                    <CardHeader/>
                </div>
            ) }
            <div className="card__body">
                { props.children }
            </div>
        </div>
    );
};

Card.propTypes = {
    noBorder: PropTypes.bool,
    className: PropTypes.string,
    CardHeader: PropTypes.func,
};

export default React.memo(Card);
