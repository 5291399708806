import React, { Fragment } from "react";
import PropTypes from "prop-types";
import Steps from "rc-steps";

import 'rc-steps/assets/index.css';
import withTranslation from "../../../Ultils/withTranslation";

const RegisterSteps = props => {
    const { step = 0, translation } = props;
    return (
        <Fragment>
            <hr className="mb-5"/>
            { Object.keys(translation).length > 0 && (
                <Steps current={ step } className="mb-5">
                    <Steps.Step title={ translation.react.register.step0 }/>
                    <Steps.Step title={ translation.react.register.step1 }/>
                    <Steps.Step title={ translation.react.register.step2 }/>
                </Steps>
            ) }
            <hr className="mb-8"/>
        </Fragment>
    );
};

RegisterSteps.propTypes = {
    step: PropTypes.number
};

export default withTranslation(React.memo(RegisterSteps), [ 'react' ]);
