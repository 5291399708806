import axios from "axios";
import API_CONST from "../constants";

/**
 * fetch floats action
 *
 * @returns {AxiosPromise<any>}
 */
const fetchFloats = () => {
    return axios.get(API_CONST.FLOATING_URL);
};

/**
 * Request new Floating IP
 * @returns {AxiosPromise<any>}
 */
const requestNewFloating = () => {
    return axios.post(API_CONST.FLOATING_URL, {})
};

/**
 * Delete Floating IP
 * @param id
 * @returns {AxiosPromise<any>}
 */
const deleteFloating = id => {
    return axios.post(`${ API_CONST.FLOATING_URL }/delete`, {
        id: id
    });
};

/**
 *
 * @param data
 * @returns {AxiosPromise<any>}
 */
const assignFloatingToServer = data => {
    return axios.post(`${ API_CONST.FLOATING_URL }/assign`, data);
};

export {
    fetchFloats,
    requestNewFloating,
    deleteFloating,
    assignFloatingToServer
}
