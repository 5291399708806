import React, { useContext } from 'react';
import { Route, Switch } from "react-router-dom";

import { TranslationContext } from "../Context/Api/Openstack/TranslationContextProvider";
import { RegisterUserConfirmation, RegisterCheckPayment, RegisterConfirmation, CrefoPayNotification, Register } from "../Components/Dashboard/Register";

const RegisterPage = () => {
    const translationContext = useContext(TranslationContext);

    return (
        <div className="page page--register">
            { Object.keys(translationContext.translation).length > 0 && (
                <Switch>
                    <Route path="/notification/crefopay/:orderID/:paymentMethod/:paymentInstrumentID" component={ CrefoPayNotification }/>
                    <Route path={ `/register/checkPayment` } exact={ true } component={ RegisterCheckPayment }/>
                    <Route path={ `/register/confirm` } exact={ true } component={ RegisterConfirmation }/>
                    <Route path={ `/register/confirm/:userId` } exact={ true } component={ RegisterUserConfirmation }/>
                    <Route path={ `/register` } component={ Register }/>
                </Switch>
            ) }
        </div>
    );
};

export default RegisterPage;
