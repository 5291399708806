import React, { Fragment, useContext, useEffect, useRef } from "react";
import { useDidMount, useWillUnmount } from "react-hooks-lib";
import Skeleton from "react-loading-skeleton";

import withTranslation from "../../../Ultils/withTranslation";
import { SnapshotContext } from "../../../Context/Api/Openstack/SnapshotsContextProvider";
import SnapshotItem from "./SnapshotItem";
import { EmptyGroup } from "../../Styling/EmptyGroup";

const Snapshots = props => {
    let fetchInterval = useRef();
    const snapshotsContext = useContext(SnapshotContext);
    const { translation, history } = props;

    useDidMount(() => {
        snapshotsContext.actions.fetchSnapshots();
    });

    // clear and reinitialize interval
    useEffect(() => {
        clearInterval(fetchInterval.current);
        fetchInterval.current = setInterval(async () => {
            await snapshotsContext.actions.fetchSnapshots();
        }, 3000);
    }, [ fetchInterval, snapshotsContext.actions ]);

    // clear and reinitialize interval
    useWillUnmount(() => {
        clearInterval(fetchInterval.current);
    });

    return (
        <Fragment>
            <h2 className="mb-4">{ translation.react.snapshot.snapshots }</h2>
            { !snapshotsContext.loading ? (
                <Fragment>
                    { snapshotsContext.snapshots.length > 0 ? (
                        <table className="table table--striped table--bordered">
                            <thead>
                            <tr>
                                <th scope="col">{ translation.react.global.name }</th>
                                <th scope="col" width={ 120 }>{ translation.react.global.status }</th>
                                <th scope="col" width={ 60 }></th>
                            </tr>
                            </thead>
                            <tbody>
                            { !snapshotsContext.loading ? (
                                <Fragment>
                                    { snapshotsContext.snapshots.map(snapshot => (
                                        <SnapshotItem key={ snapshot.id } snapshot={ snapshot } translation={ translation }/>
                                    )) }
                                </Fragment>
                            ) : (
                                <tr>
                                    <td><Skeleton/></td>
                                    <td><Skeleton/></td>
                                    <td><Skeleton/></td>
                                </tr>
                            ) }
                            </tbody>
                        </table>
                    ) : (
                        <EmptyGroup>
                            <svg className="svg-inline--fa fa-camera fa-w-16 fa-3x" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="camera" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                <path fill="currentColor" d="M512 144v288c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V144c0-26.5 21.5-48 48-48h88l12.3-32.9c7-18.7 24.9-31.1 44.9-31.1h125.5c20 0 37.9 12.4 44.9 31.1L376 96h88c26.5 0 48 21.5 48 48zM376 288c0-66.2-53.8-120-120-120s-120 53.8-120 120 53.8 120 120 120 120-53.8 120-120zm-32 0c0 48.5-39.5 88-88 88s-88-39.5-88-88 39.5-88 88-88 88 39.5 88 88z" className=""></path>
                            </svg>
                            <h3 className="mb-0">{ translation.react.snapshot.empty.title }</h3>
                            <p>{ translation.react.snapshot.empty.text }</p>
                            <button className="btn btn--primary mt-4 mb-0" onClick={ () => history.push('/dashboard/computes') }>{ translation.react.snapshot.empty.buttontext }</button>
                        </EmptyGroup>
                    ) }
                </Fragment>
            ) : (
                <table className="table table--striped table--bordered">
                    <thead>
                    <tr>
                        <th scope="col">{ translation.react.global.name }</th>
                        <th scope="col" width={ 120 }>{ translation.react.global.status }</th>
                        <th scope="col" width={ 60 }></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td><Skeleton/></td>
                        <td><Skeleton/></td>
                        <td><Skeleton/></td>
                    </tr>
                    </tbody>
                </table>
            ) }
        </Fragment>
    );
};

export default withTranslation(Snapshots, [ 'react' ]);
