import React from "react";

import withTranslation from "../../../Ultils/withTranslation";

const Support = props => {
    const { translation } = props;

    return (
        <div className="support">
            <i className="fa fa-phone" aria-label="Phone"/> { translation.react.global.supportPhone }
        </div>
    );
};

export default withTranslation(React.memo(Support), [ 'react' ]);
