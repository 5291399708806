import React, { useContext } from "react";
import PropTypes from "prop-types";
import Gravatar from 'react-awesome-gravatar';

import { AuthContext } from "../../../Context/Api/Openstack/AuthContextProvider";
import { Dropdown } from "../../Styling/Dropdown";
import withTranslation from "../../../Ultils/withTranslation";

const Account = props => {
    const authContext = useContext(AuthContext);
    const { translation, user, history } = props;

    // if no user is there => return nothing
    if (Object.keys(user).length < 1) return;

    return (
        <div className="account">
            <Dropdown
                Header={ () => (
                    <button className="btn">
                        <Gravatar email={ user.email } options={ { size: 35 } }>
                            { url => (
                                <img src={ url } alt={ `${ user.firstName } ${ user.lastName }` } width="35" className="avatar"/>
                            ) }
                        </Gravatar> { user.firstName } { user.lastName }
                    </button>
                ) }
                hidden={ !authContext.loggedIn }
                arrowAlignment="right"
                dropdownAlignment="right"
            >
                <ul className="account__list">
                    <li className="account__list-item">
                        <button className="btn" onClick={ () => history.push("/dashboard/profile/edit") }>{ translation.react.user.edit.editProfile }</button>
                    </li>
                    <li className="account__list-item">
                        <button className="btn" onClick={ authContext.actions.logout }>{ translation.react.global.logout }</button>
                    </li>
                </ul>
            </Dropdown>
        </div>
    );
};

Account.propTypes = {
    user: PropTypes.object.isRequired,
};

export default withTranslation(React.memo(Account), [ 'react' ]);
