import React, { Fragment, useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDidMount } from "react-hooks-lib";

import withTranslation from "../../../../Ultils/withTranslation";
import { FlavorsContext } from "../../../../Context/Api/Openstack/FlavorsContextProvider";
import { BillingContext } from "../../../../Context/Api/Openstack/BillingContextProvider";
import { ImagesContext } from "../../../../Context/Api/Openstack/ImagesContextProvider";
import { no_start_space_alpha } from "../../../../Ultils/RegexPatterns";
import { KeypairsContext } from "../../../../Context/Api/Openstack/KeypairsContextProvider";
import { ComputesContext } from "../../../../Context/Api/Openstack/ComputesContextProvider";
import { ModalContext } from "../../../../Context/ModalContextProvider";
import { SnapshotContext } from "../../../../Context/Api/Openstack/SnapshotsContextProvider";
import { Column, Grid } from "../../../Styling/Grid";
import { RadioCard } from "../../../Styling/Card";
import NewKeypairModal from "../../Keypairs/Modal/NewKeypairModal";
import Card from "../../../Styling/Card/Card";
import EmptyKeyPairs from "../../Keypairs/EmptyKeyPairs";
import scrollToTop from "../../../../Ultils/_scrollToTop";

const VolumeSizes = [ 50, 100, 250, 500, 1000, 2000 ];

const NewCompute = props => {
    const [ loading, setLoading ] = useState(true);
    const [ busy, setBusy ] = useState(false);
    const [ hddSize, setHddSize ] = useState(10);
    const [ hddTypeKey, setHddTypeKey ] = useState('VolumeSSD');
    const [ computeType, setComputeType ] = useState('installation');
    const [ totalPrices, setTotalPrices ] = useState({
        hddPrice: 0.00,
        flavorPrice: 0.00,
        total: 0.00
    });
    const flavorContext = useContext(FlavorsContext);
    const billingContext = useContext(BillingContext);
    const imagesContext = useContext(ImagesContext);
    const snapshotsContext = useContext(SnapshotContext);
    const keypairsContext = useContext(KeypairsContext);
    const computeContext = useContext(ComputesContext);
    const modalContext = useContext(ModalContext);

    const { translation, history } = props;
    const { handleSubmit, errors, register, getValues, watch, formState } = useForm();

    useDidMount(() => {
        // load intial fresh data before the form appears
        const loadFormInitialData = async () => {
            await flavorContext.actions.fetchFlavors();
            await imagesContext.actions.fetchImages();
            await snapshotsContext.actions.fetchSnapshots();
            await keypairsContext.actions.fetchKeypairs();
            await billingContext.actions.fetchPrices();

            // if all data is loaded => set loading to false
            setLoading(false);
        };

        loadFormInitialData();
    });

    const handleChangeHddType = () => {
        const HddTypeKey = `Volume${ getValues()[ 'type' ].toUpperCase() }`;
        setHddTypeKey(HddTypeKey);
    };

    const handleChangeHddSize = e => {
        const newSize = parseInt(e.target.value);
        if (!newSize || newSize < 10) {
            setHddSize(10);
            return;
        }

        setHddSize(newSize);
    };

    const handleOnChange = () => {
        const formFields = watch([ "type", "flavor", "size", "customSize", "snapshot" ]);
        if (!formFields || Object.keys(formFields).length < 1) return;

        // destructure every key to single variable
        let { type, flavor, size, customSize, snapshot } = formFields;

        // check if snapshot is available
        if (snapshot) {
            // get current selected snapshot
            const activeSnapshot = snapshotsContext.actions.getSnapshotWithId(snapshot);

            // check if snapshot has size => use given size
            size = activeSnapshot.size ? activeSnapshot.size : size;
            type = activeSnapshot.type ? activeSnapshot.type : type;
        }

        // Volume price
        const HddType = type || 'ssd';
        const HddTypeKey = `Volume${ HddType.toUpperCase() }`;
        const hddSize = !size ? parseInt(customSize) : parseInt(size);
        const hddPrice = billingContext.actions.getPricingPerMonth(billingContext.prices[ HddTypeKey ], hddSize);

        // flavorPrice
        const flavorPrice = billingContext.actions.getPricingPerMonth(billingContext.prices[ flavor ], 1);

        // total
        const priceTotal = (parseFloat(hddPrice) + parseFloat(flavorPrice)).toFixed(2);

        // set prices to state to rerender price table
        setTotalPrices({
            hddPrice: hddPrice,
            flavorPrice: flavorPrice,
            total: !isNaN(priceTotal) ? priceTotal : 0.00
        });
    };

    const handleRequestNewKeypair = () => {
        modalContext.actions.setModalContent(NewKeypairModal, {
            confirmAction: () => keypairsContext.actions.fetchKeypairs(),
        }, { maxWidth: '400px' });
    };

    /**
     * @param newComputeFormData
     * @returns {Promise<void>}
     */
    const handleOnSubmit = async newComputeFormData => {
        const newComputeData = {
            ...newComputeFormData,
            size: parseInt(newComputeFormData.customSize) > 0 && !newComputeFormData.size ? parseInt(newComputeFormData.customSize) : parseInt(newComputeFormData.size),
        };

        // check if errors happen => return
        if (Object.keys(errors).length > 0) {
            // scrollToTop
            scrollToTop(document.querySelector('.dashboard__content-main'));
            return;
        }
        setBusy(true);

        try {
            // check if restore snapshot or create compute by image
            if (newComputeData.hasOwnProperty("snapshot")) {
                // restore compute via computeContext
                await snapshotsContext.actions.restoreSnapshot(newComputeData);
            } else {
                // create server via computeContext
                await computeContext.actions.createCompute(newComputeData);
            }
            await computeContext.actions.fetchComputes();

            // redirect to computes
            history.push("/dashboard/computes");
        } catch (e) {
            setBusy(false);
        }
    };

    /**
     * @param computeType
     */
    const handleToggleComputeType = computeType => {
        setComputeType(computeType);
        setTimeout(handleOnChange, 100);
    };

    // initial calculation of default price
    useEffect(() => {
        if (Object.keys(billingContext.prices).length > 0) {
            setTimeout(handleOnChange, 500);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ loading, billingContext.prices ]);

    return (
        <div>
            <h2>{ translation.react.compute.create.title }</h2>
            { loading ? (
                <h3>{ translation.react.global.loading } ...</h3>
            ) : (
                <Fragment>
                    { busy ? (
                        <h3>{ translation.react.global.save } ...</h3>
                    ) : (
                        <form onSubmit={ handleSubmit(handleOnSubmit) } onChange={ handleOnChange }>
                            <fieldset className={ `form-group ${ errors.name && ('form-group--error') } mb-5` }>
                                <label className="h3-style" htmlFor="name">{ translation.react.compute.create.chooseHost }*</label>
                                <input type="text" id="name" name="name" placeholder={ translation.react.compute.create.placeholder.webserver } ref={ register({
                                    required: translation.react.validations.required,
                                    pattern: {
                                        value: no_start_space_alpha,
                                        message: translation.react.validations.spaces,
                                    }
                                }) }/>
                                { errors.name && (
                                    <div className="form-group-error">{ errors.name.message }</div>
                                ) }
                            </fieldset>

                            <button type="button" className={ `card__toggle ${ computeType === 'installation' ? 'card__toggle--active' : '' }` } onClick={ () => handleToggleComputeType('installation') }>Installationen
                            </button>
                            { snapshotsContext.snapshots.length > 0 && (
                                <button type="button" className={ `card__toggle ${ computeType === 'snapshot' ? 'card__toggle--active' : '' }` } onClick={ () => handleToggleComputeType('snapshot') }>Snapshots</button>
                            ) }
                            <Card>
                                { computeType === 'installation' && (
                                    <fieldset>
                                        <h3>{ translation.react.compute.create.chooseImage }</h3>
                                        <Grid>
                                            { imagesContext.images.map((image, index) => (
                                                <Column key={ image.id } sm={ 6 } md={ 4 } xl={ 3 }>
                                                    <RadioCard
                                                        id={ `${ image.id }` }
                                                        name="image"
                                                        defaultChecked={ index === 0 }
                                                        value={ image.id }
                                                        radioRef={ register }
                                                    >
                                                        <div className="radio-card__header">
                                                            <img className="flavor-logo" src={ `/images/flavors/${ image.name }_logo.svg` } alt={ image.name } width={ 150 }/>
                                                        </div>
                                                        <div className="radio-card__content">
                                                            { image.name }
                                                        </div>
                                                    </RadioCard>
                                                </Column>
                                            )) }
                                        </Grid>
                                    </fieldset>
                                ) }

                                { snapshotsContext.snapshots.length > 0 && computeType === 'snapshot' && (
                                    <fieldset>
                                        <h3>{ translation.react.compute.create.chooseSnapshot }</h3>
                                        <Grid>
                                            { snapshotsContext.snapshots.map((snapshot, index) => (
                                                <Column key={ snapshot.id } sm={ 6 } md={ 4 } xl={ 3 }>
                                                    <RadioCard
                                                        id={ `${ snapshot.id }` }
                                                        name="snapshot"
                                                        defaultChecked={ index === 0 }
                                                        value={ snapshot.id }
                                                        radioRef={ register }
                                                    >
                                                        <div className="radio-card__header">
                                                            <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="copy" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" className="svg-inline--fa fa-copy fa-w-14 fa-2x">
                                                                <path fill="currentColor" d="M433.941 65.941l-51.882-51.882A48 48 0 0 0 348.118 0H176c-26.51 0-48 21.49-48 48v48H48c-26.51 0-48 21.49-48 48v320c0 26.51 21.49 48 48 48h224c26.51 0 48-21.49 48-48v-48h80c26.51 0 48-21.49 48-48V99.882a48 48 0 0 0-14.059-33.941zM266 464H54a6 6 0 0 1-6-6V150a6 6 0 0 1 6-6h74v224c0 26.51 21.49 48 48 48h96v42a6 6 0 0 1-6 6zm128-96H182a6 6 0 0 1-6-6V54a6 6 0 0 1 6-6h106v88c0 13.255 10.745 24 24 24h88v202a6 6 0 0 1-6 6zm6-256h-64V48h9.632c1.591 0 3.117.632 4.243 1.757l48.368 48.368a6 6 0 0 1 1.757 4.243V112z" className=""></path>
                                                            </svg>
                                                            <h3 className="ml-2 mb-0" style={ { fontWeight: 'bold' } }>
                                                                { snapshot.size } GB <small>{ snapshot.type.toUpperCase() }</small>
                                                            </h3>
                                                        </div>
                                                        <div className="radio-card__content">
                                                            { snapshot.name }
                                                        </div>
                                                    </RadioCard>
                                                </Column>
                                            )) }
                                        </Grid>
                                    </fieldset>
                                ) }
                            </Card>

                            <fieldset className="mb-5">
                                <h3>{ translation.react.compute.create.chooseSize }</h3>
                                <Grid className="compute-list">
                                    { flavorContext.flavors.filter(flavor => flavor.bookable).map((flavor, index) => (
                                        <Column key={ flavor.id } xs={ 6 } md={ 4 } xl={ 3 }>
                                            <RadioCard
                                                id={ `${ flavor.id }` }
                                                name="flavor"
                                                defaultChecked={ index === 0 }
                                                value={ flavor.id }
                                                radioRef={ register }
                                            >
                                                <div className="radio-card__header">
                                                    <h3 className="flavor-price mb-0">
                                                        { billingContext.actions.getPricingPerMonth(billingContext.prices[ flavor.id ]) }
                                                        <small> € / { translation.react.global.month }</small>
                                                    </h3>
                                                </div>
                                                <div className="radio-card__content">
                                                    <div>{ flavor.cpu } vCPU{ flavor.cpu % 2 !== 0 ? '' : 's' }</div>
                                                    <div>{ Math.round(flavor.ram / 1024) } GB RAM</div>
                                                </div>
                                            </RadioCard>
                                        </Column>
                                    )) }
                                </Grid>
                            </fieldset>

                            { computeType === 'installation' && (
                                <Fragment>
                                    <fieldset className="mb-5 form-group">
                                        <h3>{ translation.react.global.type }</h3>
                                        <Grid>
                                            <Column xs={ 6 } md={ 3 } xxl={ 2 }>
                                                <RadioCard
                                                    id="ssd"
                                                    name="type"
                                                    defaultChecked={ true }
                                                    value="ssd"
                                                    onChange={ handleChangeHddType }
                                                    radioRef={ register }
                                                >
                                                    <div className="radio-card__content">
                                                        <svg aria-hidden="true" focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" className="svg-inline--fa fa-w-32 fa-sm">
                                                            <path fill="currentColor" d="M567.403 235.642L462.323 84.589A48 48 0 0 0 422.919 64H153.081a48 48 0 0 0-39.404 20.589L8.597 235.642A48.001 48.001 0 0 0 0 263.054V400c0 26.51 21.49 48 48 48h480c26.51 0 48-21.49 48-48V263.054c0-9.801-3-19.366-8.597-27.412zM153.081 112h269.838l77.913 112H75.168l77.913-112zM528 400H48V272h480v128zm-32-64c0 17.673-14.327 32-32 32s-32-14.327-32-32 14.327-32 32-32 32 14.327 32 32zm-96 0c0 17.673-14.327 32-32 32s-32-14.327-32-32 14.327-32 32-32 32 14.327 32 32z" className=""></path>
                                                        </svg>
                                                        &nbsp;SSD
                                                    </div>
                                                </RadioCard>
                                            </Column>
                                            <Column xs={ 6 } md={ 3 } xxl={ 2 }>
                                                <RadioCard
                                                    id="hdd"
                                                    name="type"
                                                    value="hdd"
                                                    onChange={ handleChangeHddType }
                                                    radioRef={ register }
                                                >
                                                    <div className="radio-card__content">
                                                        <svg aria-hidden="true" focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" className="svg-inline--fa fa-w-32 fa-sm">
                                                            <path fill="currentColor" d="M567.403 235.642L462.323 84.589A48 48 0 0 0 422.919 64H153.081a48 48 0 0 0-39.404 20.589L8.597 235.642A48.001 48.001 0 0 0 0 263.054V400c0 26.51 21.49 48 48 48h480c26.51 0 48-21.49 48-48V263.054c0-9.801-3-19.366-8.597-27.412zM153.081 112h269.838l77.913 112H75.168l77.913-112zM528 400H48V272h480v128zm-32-64c0 17.673-14.327 32-32 32s-32-14.327-32-32 14.327-32 32-32 32 14.327 32 32zm-96 0c0 17.673-14.327 32-32 32s-32-14.327-32-32 14.327-32 32-32 32 14.327 32 32z" className=""></path>
                                                        </svg>
                                                        &nbsp;HDD
                                                    </div>
                                                </RadioCard>
                                            </Column>
                                        </Grid>
                                    </fieldset>

                                    <fieldset className="mb-10 form-group">
                                        <h3>{ translation.react.compute.create.disksize }</h3>
                                        <Grid>
                                            <Column xs={ 6 } md={ 4 } xl={ 3 } xxl={ 2 }>
                                                <RadioCard
                                                    id="size"
                                                    name="size"
                                                >
                                                    <div className="radio-card__header">
                                                        <h3 className="flavor-price mb-0">
                                                            { billingContext.actions.getPricingPerMonth(billingContext.prices[ hddTypeKey ], hddSize) }
                                                            <small> € / { translation.react.global.month }</small>
                                                        </h3>
                                                    </div>
                                                    <div className="radio-card__content">
                                                        <span className="flavor-price flavor-price--input mb-0">
                                                            <input id={ `customgb` } type="number" name="customSize" onChange={ handleChangeHddSize } defaultValue={ 10 } min={ 10 } step={ 10 } max={ 2000 } ref={ register }/>
                                                            <small> GB</small>
                                                        </span>
                                                    </div>
                                                </RadioCard>
                                            </Column>
                                            { VolumeSizes.map(volumeSize => (
                                                <Column key={ `${ volumeSize }gb` } xs={ 6 } md={ 4 } xl={ 3 } xxl={ 2 }>
                                                    <RadioCard
                                                        id={ `${ volumeSize }gb` }
                                                        name="size"
                                                        defaultChecked={ volumeSize === 100 }
                                                        value={ volumeSize }
                                                        radioRef={ register }
                                                    >
                                                        <div className="radio-card__header">
                                                            <h3 className="flavor-price mb-0">
                                                                { billingContext.actions.getPricingPerMonth(billingContext.prices[ hddTypeKey ], volumeSize) }
                                                                <small> € / { translation.react.global.month }</small>
                                                            </h3>
                                                        </div>
                                                        <div className="radio-card__content">
                                                            <span className="flavor-price mb-0">
                                                                { volumeSize }
                                                                <small> GB</small>
                                                            </span>
                                                        </div>
                                                    </RadioCard>
                                                </Column>
                                            )) }
                                        </Grid>
                                    </fieldset>
                                </Fragment>
                            ) }

                            <fieldset className="mb-5">
                                { keypairsContext.keypairs.length > 0 && (
                                    <button type="button" className="btn btn--primary float-right" onClick={ handleRequestNewKeypair }>{ translation.react.keypair.create.title }</button>
                                ) }
                                <h3>{ translation.react.compute.create.addSSH }</h3>
                                <Grid>
                                    { keypairsContext.keypairs.length > 0 ? (
                                        <Fragment>
                                            { keypairsContext.keypairs.map((keypair, index) => (
                                                <Column key={ keypair.id } xs={ 6 } md={ 4 } xl={ 3 } xxl={ 2 }>
                                                    <RadioCard
                                                        id={ `${ keypair.id }` }
                                                        name="keypair"
                                                        defaultChecked={ index === 0 }
                                                        value={ `${ keypair.name }` }
                                                        radioRef={ register }
                                                    >
                                                        <div className="radio-card__header">
                                                            <h4>
                                                                <svg aria-hidden="true" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className="svg-inline--fa fa-w-16 fa-sm">
                                                                    <path fill="currentColor" d="M512 176.001C512 273.203 433.202 352 336 352c-11.22 0-22.19-1.062-32.827-3.069l-24.012 27.014A23.999 23.999 0 0 1 261.223 384H224v40c0 13.255-10.745 24-24 24h-40v40c0 13.255-10.745 24-24 24H24c-13.255 0-24-10.745-24-24v-78.059c0-6.365 2.529-12.47 7.029-16.971l161.802-161.802C163.108 213.814 160 195.271 160 176 160 78.798 238.797.001 335.999 0 433.488-.001 512 78.511 512 176.001zM336 128c0 26.51 21.49 48 48 48s48-21.49 48-48-21.49-48-48-48-48 21.49-48 48z" className=""></path>
                                                                </svg>
                                                                &nbsp;{ keypair.name }
                                                            </h4>
                                                        </div>
                                                        <div className="radio-card__content">
                                                            { keypair.fingerprint }
                                                        </div>
                                                    </RadioCard>
                                                </Column>
                                            )) }
                                        </Fragment>
                                    ) : (
                                        <Column xs={ 12 }>
                                            <EmptyKeyPairs handleClickFn={ handleRequestNewKeypair }/>
                                        </Column>
                                    ) }
                                </Grid>
                            </fieldset>

                            { totalPrices.total > 0 && (
                                <fieldset className="form-group mb-5">
                                    <h2>{ translation.react.global.monthlyCosts }</h2>
                                    <table className="table table--striped table--bordered">
                                        <thead>
                                        <tr>
                                            <th scope="col">{ translation.react.global.service }</th>
                                            <th scope="col" width={ 200 }>{ translation.react.global.price }</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            <td data-label="Data">{ translation.react.global.server }</td>
                                            <td data-label="Data">{ totalPrices.flavorPrice } EUR</td>
                                        </tr>
                                        <tr>
                                            <td data-label="Data">{ translation.react.global.disks }</td>
                                            <td data-label="Data">{ totalPrices.hddPrice } EUR</td>
                                        </tr>
                                        <tr>
                                            <td className="hide-md" align="right">{ translation.react.global.total }:</td>
                                            <td data-label={ translation.react.global.total }><strong>{ totalPrices.total } EUR</strong></td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </fieldset>
                            ) }

                            <button disabled={ Object.keys(errors).length > 0 } onClick={ () => {
                                if (!formState.isSubmitted) {
                                    scrollToTop(document.querySelector('.dashboard__content-main'));
                                }
                            } } type="submit" className="btn btn--big btn--danger mr-4">{ translation.react.global.order }</button>
                            <button type="button" className="btn btn--big btn--secondary" onClick={ () => history.push("/") }>{ translation.react.global.cancel }</button>
                        </form>
                    ) }
                </Fragment>
            ) }
        </div>
    );
};

export default withTranslation(NewCompute, [ 'react' ]);
