import React, { useContext } from 'react';
import { TranslationContext } from "../Context/Api/Openstack/TranslationContextProvider";

/**
 * Higher Order Component to compose components with translation data
 *
 * @param ComposedComponent
 * @param translationObjectKeys => for example "login" to get the login key or an array with key values ['login', 'validations'] to get an Object with { login: {...}, validations: {...} } in it.
 * @returns {function(*): *}
 */
const withTranslation = (ComposedComponent, translationObjectKeys) => React.memo(props => {
    const l10nContext = useContext(TranslationContext);
    let componentTranslation;

    // check if translationObjectKeys is an array with keys
    if (Array.isArray(translationObjectKeys)) {
        let foundedTranslations = {};
        // map through keys
        translationObjectKeys.map(key => {

            // check if translation is available
            if (Object.keys(l10nContext.translation).length > 0) {
                // check if key is available in translation object
                if (l10nContext.translation.hasOwnProperty(key)) {
                    // put translation into componentTranslations object
                    foundedTranslations = {
                        ...foundedTranslations,
                        [ key ]: { ...l10nContext.translation[ key ] },
                    }
                } else {
                    // throw warning into the console that the iterated key wasn't found in translation object
                    console.warn(`The translation key: "${ key }" could not be found in translation object.`, ComposedComponent.name);
                }
            }

            return null;
        });
        // check if foundedTranslations has any languages in it => otherwise set complete translation object
        componentTranslation = Object.keys(foundedTranslations).length > 0 ? foundedTranslations : l10nContext.translation;
    } else {
        // this else is used if translationObjectKeys is only a string with one key in it
        // check if translationObjectKeys is found in global translation and paste it down to given component => otherwise give global l10n data
        componentTranslation = l10nContext.translation && l10nContext.translation.hasOwnProperty(translationObjectKeys) ? l10nContext.translation[ translationObjectKeys ] : l10nContext.translation;
    }
    return <ComposedComponent translation={ componentTranslation } { ...props } />;
});

export default withTranslation;
