import React, { Fragment, useContext, useState } from "react";
import PropTypes from 'prop-types';

import { VolumesContext } from "../../../Context/Api/Openstack/VolumesContextProvider";
import ConfirmationModal from "../../Modal/ConfirmationModal";
import SelectboxModal from "../../Modal/SelectboxModal"
import { ModalContext } from "../../../Context/ModalContextProvider";
import { ComputesContext } from "../../../Context/Api/Openstack/ComputesContextProvider";
import NewVolumeResizeModal from "./Modal/NewVolumeResizeModal";
import { Dropdown } from "../../Styling/Dropdown";
import { NotificationManager } from "react-notifications";

const VolumeItem = props => {
    const volumesContext = useContext(VolumesContext);
    const computesContext = useContext(ComputesContext);
    const modalContext = useContext(ModalContext);
    const { volume, translation } = props;
    const [ isBusy, setIsBusy ] = useState(false);

    /**
     * @param volumeId
     */
    const deleteVolume = async volumeId => {
        if (!volumeId) return;
        // set busy state to deactivate delete button
        setIsBusy(true);
        const deleteVolumeError = await volumesContext.actions.deleteVolume(volumeId);

        // if error happen
        if (deleteVolumeError) {
            setIsBusy(false);
            return;
        }

        NotificationManager.success(translation.react.volume.delete.success, translation.react.global.success);
    };

    /**
     * @param volumeId
     */
    const handleDelete = volumeId => {
        modalContext.actions.setModalContent(ConfirmationModal, {
            confirmAction: () => deleteVolume(volumeId),
            title: translation.react.volume.delete.title,
            action: translation.react.global.delete,
            cancel: translation.react.global.cancel
        }, { maxWidth: '450px' });
    };

    const handleAssignVolumeToServer = volumeId => {
        let select_array = [];
        computesContext.computes.map(compute => (
            select_array.push({ id: compute.id, name: compute.name })
        ));

        modalContext.actions.setModalContent(SelectboxModal, {
            confirmAction: assignVolumeToServer,
            computeId: volumeId,
            selectBoxData: select_array,
            title: translation.react.volume.attach.title,
            action: translation.react.global.assign,
            cancel: translation.react.global.cancel
        }, { maxWidth: '500px' });
    };

    /**
     * @param volumeId
     */
    const handleResize = volume => {
        modalContext.actions.setModalContent(NewVolumeResizeModal, { volume: volume }, { maxWidth: '600px' });
    };

    const assignVolumeToServer = async (volumeId, computeId) => {
        setIsBusy(true);
        let data = { volume_id: volumeId, ComputeId: computeId }; //Convert to Object for REST-Api
        const attachVolumeError = await computesContext.actions.attachVolume(data);

        // if error happen
        if (attachVolumeError) {
            setIsBusy(false);
            return;
        }

        setTimeout(async () => {
            await volumesContext.actions.fetchVolumes();
            NotificationManager.success(translation.react.volume.attach.success, translation.react.global.success);
            setIsBusy(false);
        }, 5000);
    };

    const handleDetachVolumeToServer = volume => {
        modalContext.actions.setModalContent(ConfirmationModal, {
            confirmAction: () => detachVolumeToServer(volume.id, volume.server_id),
            title: translation.react.volume.detach.title,
            action: translation.react.global.detach,
            cancel: translation.react.global.cancel
        }, { maxWidth: '450px' });
    };

    const detachVolumeToServer = async (volumeId, computeId) => {
        setIsBusy(true);

        let data = { volume_id: volumeId, ComputeId: computeId }; //Convert to Object for REST-Api
        const detachVolumeError = await computesContext.actions.detachVolume(data);

        // if error happen
        if (detachVolumeError) {
            setIsBusy(false);
            return;
        }

        setTimeout(async () => {
            await volumesContext.actions.fetchVolumes();
            NotificationManager.success(translation.react.volume.detach.success, translation.react.global.success);
            setIsBusy(false);
        }, 5000);
    };

    return (
        <tr key={ volume.id }>
            <td data-label={ `${ translation.react.global.size }:` }><b>{ volume.size } GB</b></td>
            <td data-label={ `${ translation.react.global.type }:` }>{ `${ volume.type ? volume.type : "ssd" }`.toUpperCase() }</td>
            <td data-label={ `${ translation.react.global.name }:` }>{ volume.name ? volume.name : 'Root' }</td>
            <td data-label={ `${ translation.react.global.server }:` }>
                { volume.server_id ? (
                    computesContext.actions.getServerNameFromId(volume.server_id)
                ) : (
                    translation.react.volume.notInUse
                ) }
            </td>
            <td data-label={ `${ translation.react.global.action }:` }>
                <Dropdown
                    dropdownAlignment="right-desktop"
                    arrowAlignment="right-desktop"
                >
                    <ul>
                        { volume.status === 'available' ? (
                            <Fragment>
                                <li>
                                    <button disabled={ isBusy } onClick={ () => handleAssignVolumeToServer(volume.id) } className="btn">
                                        <i className="fas fa-link" aria-label={ translation.react.volume.attachDisk }/> { translation.react.volume.attachDisk }
                                    </button>
                                </li>
                                <li>
                                    <button disabled={ isBusy } onClick={ () => handleResize(volume) } className="btn">
                                        <i className="far fa-save" aria-label={ translation.react.volume.resizeDisk }/> { translation.react.volume.resizeDisk }
                                    </button>
                                </li>
                                <li>
                                    <button disabled={ isBusy } onClick={ () => handleDelete(volume.id) } className="btn">
                                        <i className="far fa-trash-alt" aria-label={ translation.react.volume.deleteDisk }/> { translation.react.volume.deleteDisk }
                                    </button>
                                </li>
                            </Fragment>
                        ) : (
                            <li>
                                <button disabled={ isBusy } onClick={ () => handleDetachVolumeToServer(volume) } className="btn">
                                    <i className="fas fa-times" aria-label={ translation.react.volume.detachDisk }/> { translation.react.volume.detachDisk }
                                </button>
                            </li>
                        )

                        }
                    </ul>
                </Dropdown>
            </td>
        </tr>
    );
};

VolumeItem.propTypes = {
    translation: PropTypes.object.isRequired,
    volume: PropTypes.shape({
        id: PropTypes.string.isRequired,
        size: PropTypes.number.isRequired,
    })
};

export default React.memo(VolumeItem);
