import React, { Fragment, useContext } from "react";
import { useDidMount } from "react-hooks-lib";

import withTranslation from "../../../Ultils/withTranslation";
import { BillingContext } from "../../../Context/Api/Openstack/BillingContextProvider";
import BillingItem from "./BillingItem";
import Skeleton from "react-loading-skeleton";
import { EmptyGroup } from "../../Styling/EmptyGroup";

const Billings = props => {
    const billingContext = useContext(BillingContext);
    const { translation } = props;

    useDidMount(() => {
        const fetchBillings = async () => {
            await billingContext.actions.fetchBillings();
        };

        fetchBillings();
    });

    return (
        <Fragment>
            <h2>{ translation.react.billing.billings }</h2>
            { !billingContext.loading ? (
                <Fragment>
                    { billingContext.billings.length > 0 ? (
                        <table className="table table--striped table--bordered">
                            <thead>
                            <tr>
                                <th scope="col">{ translation.react.global.periodOfTime }</th>
                                <th scope="col" width="150">{ translation.react.global.sum }</th>
                                <th scope="col" width="60"></th>
                            </tr>
                            </thead>
                            <tbody>
                            { billingContext.billings.map(billing => (
                                <BillingItem billing={ billing } key={ billing.id }/>
                            )) }
                            </tbody>
                        </table>
                    ) : (
                        <EmptyGroup>
                            <svg className="svg-inline--fa fa-wallet fa-w-16 fa-3x" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="wallet" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                <path fill="currentColor" d="M461.2 128H80c-8.84 0-16-7.16-16-16s7.16-16 16-16h384c8.84 0 16-7.16 16-16 0-26.51-21.49-48-48-48H64C28.65 32 0 60.65 0 96v320c0 35.35 28.65 64 64 64h397.2c28.02 0 50.8-21.53 50.8-48V176c0-26.47-22.78-48-50.8-48zM416 336c-17.67 0-32-14.33-32-32s14.33-32 32-32 32 14.33 32 32-14.33 32-32 32z" className=""></path>
                            </svg>
                            <h3 className="mb-0">{ translation.react.billing.empty.title }</h3>
                            <p>{ translation.react.billing.empty.text }</p>
                        </EmptyGroup>
                    ) }
                </Fragment>
            ) : (
                <table className="table table--striped table--bordered">
                    <thead>
                    <tr>
                        <th scope="col">{ translation.react.global.periodOfTime }</th>
                        <th scope="col" width="150">{ translation.react.global.sum }</th>
                        <th scope="col" width="60"></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td><Skeleton/></td>
                        <td><Skeleton/></td>
                        <td><Skeleton/></td>
                    </tr>
                    </tbody>
                </table>
            ) }

        </Fragment>
    );
};

export default withTranslation(Billings, [ 'react' ]);
