import React, { useContext } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { useDidMount } from "react-hooks-lib";
import { NotificationContainer } from "react-notifications";

import { TranslationContext } from "./Context/Api/Openstack/TranslationContextProvider";
import PrivateRoute from "./Ultils/PrivateRoute";
import LoginPage from "./Pages/LoginPage";
import Dashboard from "./Pages/Dashboard";
import RegisterPage from "./Pages/RegisterPage";
import NotFound from "./Components/Dashboard/NotFound";
import OnlineCheck from "./Pages/OnlineCheck";

const App = () => {
    const l10nContext = useContext(TranslationContext);

    // componentDidMount
    useDidMount(() => {
        l10nContext.actions.getTranslationWithId();
    });

    return (
        <BrowserRouter>
            <Route path="/" component={ OnlineCheck }/>
            <Switch>
                <Route exact={ true } path="/" component={ LoginPage }/>
                <Route path="/register" component={ RegisterPage }/>
                <Route path="/notification" component={ RegisterPage }/>
                <PrivateRoute path="/dashboard" component={ Dashboard }/>
                <Route component={ NotFound }/>
            </Switch>
            <NotificationContainer/>
        </BrowserRouter>
    );
};

export default React.memo(App);
