import React, { useContext } from "react";
import { Redirect } from "react-router-dom";

import { AuthContext } from "../../../Context/Api/Openstack/AuthContextProvider";
import { CrefoPayContext } from "../../../Context/Api/Openstack/CrefoPayContextProvider";

const CrefoPayNotification = props => {
    const authContext = useContext(AuthContext);
    const crefoPayContext = useContext(CrefoPayContext);
    const { match: { params: { orderID, paymentMethod, paymentInstrumentID } } } = props;
    let redirectToPath = "/";

    // if any of the necessary params is not set => redirect to homepage
    if (!orderID || !paymentMethod || !paymentInstrumentID) {
        return <Redirect to="/"/>
    }

    // set given params to crefoPayContext
    crefoPayContext.actions.setCrefoPayValidationParams(orderID, paymentMethod, paymentInstrumentID);

    // check if user is loggedIn or loggedOut
    if (authContext.actions.isUserLoggedIn()) {
        // user is logged in
        redirectToPath = `/dashboard/profile/edit`;
    } else {
        // user is logged out => confirm
        redirectToPath = "/register/checkPayment";
    }

    return <Redirect to={ redirectToPath }/>
};

export default CrefoPayNotification;
