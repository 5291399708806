// add some basic form regex patterns here for validation
export const alpha = /^[A-Z]*$/i;
export const alpha_space = /^[A-Z\s]*$/i;
export const alpha_num = /^[A-Z0-9]*$/i;
export const alpha_num_space = /^[A-Z0-9\s]*$/i;
export const alpha_num_dash = /^[A-Z0-9_-]*$/i;
export const alpha_num_dash_space = /^[A-Z0-9_-\s]*$/i;
export const birthday = /^\d{2}[./-]\d{2}[./-]\d{4}$/;
export const card_exp = /^(([0]?[1-9]{1})|([1]{1}[0-2]{1}))\s?\/\s?(\d{2}|\d{4})$/;
export const card_num = /^\d{4}\s?\d{4,6}\s?\d{4,5}\s?\d{0,8}$/;
export const currency = /^\$?(\d{1,3})(,?\d{3})*\.?\d{0,2}$/;
export const email = /^[A-Z0-9.!#$%&'*+-/=?^_`{|}~]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
// backup pattern for ip addresses => /^(?!0)(?!.*\.$)((1?\d?\d|25[0-5]|2[0-4]\d)(\.|$)){4}$/i
export const ip = /^(?:[0-9]{1,3}.){3}[0-9]{1,3}(\/\d{1,2})?$/;
export const zip_de = /^\d{5}$/;
export const integer = /^\d*$/;
export const no_start_space_alpha = /^\S*[a-z0-9]/i;
export const phone = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;
export const port = /[1-9]/i;
export const url = /^(https?|ftp):\/\/(-\.)?([^\s/?.#-]+\.?)+(\/[^\s]*)?$/i;
export const sshKey = /^ssh-rsa [0-9A-Za-z+/].*/;
