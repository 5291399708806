import React, { Component, createContext } from "react";

import { ErrorContext } from "../../ErrorContextProvider";
import API from "../../../Api/Openstack";

export const CrefoPayContext = createContext({});

class CrefoPayContextProvider extends Component {
    static contextType = ErrorContext;

    state = {
        orderID: null,
        paymentMethod: null,
        paymentInstrumentID: null,
        confirmationStatus: null,
    };

    /**
     * @param orderID
     * @param paymentMethod
     * @param paymentInstrumentID
     */
    setCrefoPayValidationParams = (orderID, paymentMethod, paymentInstrumentID) => {
        this.setState({
            orderID,
            paymentMethod,
            paymentInstrumentID
        });
    };

    clearCrefoPayValidationParams = () => {
        this.setState({
            orderID: null,
            paymentMethod: null,
            paymentInstrumentID: null
        });
    };

    /**
     * @param crefoPayConfirmationData
     * @returns {Promise<AxiosResponse<any> | Error>}
     */
    checkCrefoPayConfirmation = crefoPayConfirmationData => {
        if (!crefoPayConfirmationData) return;

        return API.confirmPayment(crefoPayConfirmationData)
            .then((response) => {
                this.setState({ confirmationStatus: response.status });
            })
            .catch(error => {
                this.context.actions.showError(error);
                return new Error(error);
            });
    };

    getPaymentDataForUser = () => {
        return {
            user: {
                id: this.state.orderID.split('-')[ 1 ]
            },
            payment: {
                paymentMethod: this.state.paymentMethod,
                paymentInstrumentID: this.state.paymentInstrumentID,
                orderID: this.state.orderID
            }
        }
    };

    render() {
        return (
            <CrefoPayContext.Provider value={ {
                ...this.state,
                actions: {
                    getPaymentDataForUser: this.getPaymentDataForUser,
                    setCrefoPayValidationParams: this.setCrefoPayValidationParams,
                    clearCrefoPayValidationParams: this.clearCrefoPayValidationParams,
                    checkCrefoPayConfirmation: this.checkCrefoPayConfirmation,
                }
            } }>
                { this.props.children }
            </CrefoPayContext.Provider>
        );
    }
}

export default CrefoPayContextProvider;
