import axios from "axios";
import API_CONST from "../constants";

/**
 * fetch translation action
 *
 * @param languageId
 * @returns {AxiosPromise<any>}
 */
const fetchTranslationsWithId = (languageId = 'de') => {
    if (!languageId) return;
    return axios.get(`${ API_CONST.LANG_URL }${ languageId }`);
};

export {
    fetchTranslationsWithId
}
