import React, { useContext } from "react";
import classNames from "classnames";

import { DropdownContext } from "@reactway/dropdown";

function extractHTMLProps(props) {
    const {
        children,
        className,
        closedClassName,
        disabledClassName,
        openClassName,
        ...restProps
    } = props;

    return restProps;
}

export const DropdownContent = React.forwardRef((props, ref) => {
    const handlerContext = useContext(DropdownContext);
    const htmlElementProps = extractHTMLProps(props);

    return (
        <div
            ref={ ref }
            className={ classNames(props.className, {
                [ props.openClassName || "" ]: handlerContext.isOpen,
                [ props.closedClassName || "" ]: !handlerContext.isOpen,
                [ props.disabledClassName || "" ]: handlerContext.isDisabled
            }) }
            onClick={ () => handlerContext.onSectionClick() }
            hidden={ !handlerContext.isOpen }
            { ...htmlElementProps }
        >
            { props.children }
        </div>
    );
});
