import axios from "axios";
import API_CONST from "../constants";

/**
 * fetch Images
 *
 * @returns {AxiosPromise<any>}
 */
const fetchImages = () => {
    return axios.get(API_CONST.IMAGE_URL);
};

export {
    fetchImages,
}
