import React from "react";
import PropTypes from "prop-types";
import useWhyDidYouUpdate from "../../../Hooks/useWhyDidYouUpdate";

const RadioCard = props => {
    const { id, name, value, defaultChecked, label, LabelContent, radioRef, children, HeaderContent, className, ...rest } = props;
    useWhyDidYouUpdate(props);

    return (
        <div className={ `radio-card ${ className ? className : '' }` } { ...rest }>
            <input type="radio" id={ id } name={ name } value={ value } defaultChecked={ defaultChecked } className="radio-card__input" ref={ radioRef }/>
            <label className="radio-card__label" htmlFor={ id }>
                { children }
            </label>
        </div>
    );
};

RadioCard.propTypes = {
    id: PropTypes.string,
    name: PropTypes.string,
    value: PropTypes.any,
    label: PropTypes.string,
    defaultChecked: PropTypes.bool,
    radioRef: PropTypes.func,
};

export default React.memo(RadioCard);
