import React, { Fragment, useContext } from "react";
import { useDidMount } from "react-hooks-lib";
import moment from "moment";
import Skeleton from "react-loading-skeleton";

import withTranslation from "../../../../Ultils/withTranslation";
import { BillingContext } from "../../../../Context/Api/Openstack/BillingContextProvider";

const InterimBilling = props => {
    const billingContext = useContext(BillingContext);
    const { translation } = props;

    useDidMount(() => {
        const fetchInterimBilling = async () => {
            try {
                await billingContext.actions.fetchInterimBilling();
            } catch (error) {
            }
        };

        fetchInterimBilling();
    });

    return (
        <div>
            <h2>{ translation.react.billing.interimBilling } - { moment(new Date()).format('MMMM YYYY') }</h2>
            { !billingContext.loading && billingContext.interim.items ? (
                <Fragment>
                    { billingContext.interim.items.length > 0 ? (
                        <table className="table table--striped table--bordered">
                            <thead>
                            <tr>
                                <th scope="col">{ translation.react.global.service }</th>
                                <th scope="col">{ translation.react.global.name }</th>
                                <th scope="col">{ translation.react.global.price }</th>
                            </tr>
                            </thead>
                            <tbody>
                            { billingContext.interim.items.map(billingItem => (
                                <tr key={ `${ billingItem.name }-${ billingItem.service }` }>
                                    <td data-label={ translation.react.global.service }>{ billingItem.service }</td>
                                    <td data-label={ translation.react.global.name }>{ billingItem.name }</td>
                                    <td data-label={ translation.react.global.price }>{ billingItem.price }</td>
                                </tr>
                            )) }
                            <tr>
                                <td className="hide-md"/>
                                <td className="hide-md" align="right">{ translation.react.global.sum }:</td>
                                <td data-label={ translation.react.global.sum }><strong>{ billingContext.interim.summary.sum ? billingContext.interim.summary.sum : 0 } EUR</strong></td>
                            </tr>
                            <tr>
                                <td className="hide-md"/>
                                <td className="hide-md" align="right">{ translation.react.global.vatWithPercentShort }:</td>
                                <td data-label={ translation.react.global.vatWithPercentShort }><strong>{ billingContext.interim.summary.vat ? billingContext.interim.summary.vat : 0 } EUR</strong></td>
                            </tr>
                            <tr>
                                <td className="hide-md"/>
                                <td className="hide-md" align="right">{ translation.react.global.total }:</td>
                                <td data-label={ translation.react.global.total }><strong>{ billingContext.interim.summary.total ? billingContext.interim.summary.total : 0 } EUR</strong></td>
                            </tr>
                            </tbody>
                        </table>
                    ) : (
                        <h3 className="mb-0">{ translation.react.billing.noInterimBillingFound }</h3>
                    ) }
                </Fragment>
            ) : (
                <table className="table table--striped table--bordered">
                    <thead>
                    <tr>
                        <th scope="col">{ translation.react.global.service }</th>
                        <th scope="col">{ translation.react.global.name }</th>
                        <th scope="col">{ translation.react.global.price }</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td><Skeleton/></td>
                        <td><Skeleton/></td>
                        <td><Skeleton/></td>
                    </tr>
                    </tbody>
                </table>
            ) }
        </div>
    );
};

export default withTranslation(InterimBilling, [ 'react' ]);
