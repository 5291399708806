import React, { Fragment, useState, useContext, useEffect } from "react";
import { useDidMount } from "react-hooks-lib";

import withTranslation from "../../../Ultils/withTranslation";
import scrollToTop from "../../../Ultils/_scrollToTop";
import { Card } from "../../Styling/Card";
import { Spinner } from "../../Styling/Loading";
import { CrefoPayContext } from "../../../Context/Api/Openstack/CrefoPayContextProvider";
import { RegisterSteps } from "./index";

import Logo from "../../../Assets/Images/mobi-logo-login.svg";

const RegisterCheckPayment = props => {
    const crefoPayContext = useContext(CrefoPayContext);
    const [ registerState, setRegisterState ] = useState(true);
    const { translation, history } = props;

    useDidMount(() => {
        // if no parameter is given => redirect to register form
        if (!crefoPayContext.orderID || !crefoPayContext.paymentMethod || !crefoPayContext.paymentInstrumentID) {
            history.push("/register");
            return;
        }

        // check confirmation with given params
        checkConfirmation();
    });

    useEffect(() => {
        // redirect => confirmed
        if (crefoPayContext.confirmationStatus === 200) {
            history.push("/register/confirm");
        }
    }, [ crefoPayContext.confirmationStatus, history ]);

    const checkConfirmation = async () => {
        // show loading indicator
        setRegisterState(true);

        // scroll page to top
        scrollToTop();

        // check crefoPay data
        const checkCrefoPayConfirmationError = await crefoPayContext.actions.checkCrefoPayConfirmation(crefoPayContext.actions.getPaymentDataForUser());

        // clear crefoPayContext State and remove the params
        crefoPayContext.actions.clearCrefoPayValidationParams();

        // if error happen
        if (checkCrefoPayConfirmationError) {
            // redirect to register page if error happen
            history.push("/register");
        }
    };

    return (
        <Fragment>
            <Card
                className={ `register register--step-2 ${ registerState ? 'register--is-busy' : '' }` }>
                <img className="logo mt-4" src={ Logo } width={ 160 } alt="Mobilistics"/>
                <RegisterSteps step={ 1 }/>
                <h3>{ translation.react.validations.checkPayment }</h3>
                <Spinner className="loader"/>
            </Card>
        </Fragment>
    );
};

export default withTranslation(React.memo(RegisterCheckPayment), [ 'react' ]);

