import 'react-app-polyfill/ie9';
import React from 'react';
import ReactDOM from 'react-dom';

import GlobalAppState from "./Context/GlobalAppState";
import App from "./App";

import 'nprogress/nprogress.css';
import 'react-notifications/lib/notifications.css';
import './Styles/import.scss';

ReactDOM.render(
    <GlobalAppState>
        <App/>
    </GlobalAppState>,
    document.getElementById('root'));
