import React, { Component, createContext } from 'react';
import { NotificationManager } from 'react-notifications';
import withTranslation from "../Ultils/withTranslation";

export const ErrorContext = createContext({});

class ErrorContextProvider extends Component {
    state = {
        hasError: false,
    };

    /**
     * @param error
     * @returns {string}
     */
    getTranslatedErrorMessage = error => {
        const { translation } = this.props;
        let errorMessage;
        if (translation.react.error) {
            errorMessage = translation.react.error[ `e${ error.code }` ] || translation.react.error.e500;
        } else {
            errorMessage = "oops an error occurred";
        }
        return errorMessage;
    };

    /**
     * @param error
     */
    showError = error => {
        if (!error) return;
        const { translation } = this.props;
        this.setState({ hasError: true });

        // check if it's a normal error
        if (error.response && error.response.data && error.response.data.errors) {
            // iterate through errors and send out notification to the user
            error.response.data.errors.map(error => {
                // find right translation or set default e500
                let errorMessage = this.getTranslatedErrorMessage(error);
                // show up notification
                NotificationManager.error(errorMessage, translation.react.global.error);
                return null;
            });
        } else {
            // catch normal error and show notification
            NotificationManager.error(error.message, "Error");
        }

        this.setState({ hasError: false });
    };

    /**
     * @param error
     * @returns {Array}
     */
    getTranslatedErrorMessagesByError = error => {
        let errorMessages = [];
        if (error.response && error.response.data) {
            error.response.data.errors.map(error => {
                return errorMessages.push(this.getTranslatedErrorMessage(error));
            });
        }
        return errorMessages;
    };

    render() {
        return (
            <ErrorContext.Provider value={ {
                ...this.state,
                actions: {
                    showError: this.showError,
                    getTranslatedErrorMessagesByError: this.getTranslatedErrorMessagesByError,
                }
            } }>
                { this.props.children }
            </ErrorContext.Provider>
        );
    }
}

export default withTranslation(ErrorContextProvider, [ 'react' ]);
