import React, { Component, createContext } from "react";

import API from "../../../Api/Openstack";
import { ErrorContext } from "../../ErrorContextProvider";
import isEqual from "../../../Ultils/isEqual";

export const FloatsContext = createContext({});

class FloatingsContextProvider extends Component {
    static contextType = ErrorContext;

    // default floats state
    state = {
        floats: [],
        loading: true,
    };

    /**
     * fetchFloats
     */
    fetchFloats = (callbackFn = () => {
    }) => {
        return API.fetchFloats()
            .then(({ data }) => {
                // check if loading is true => set it to false
                if (this.state.loading) {
                    this.setState({ loading: false });
                }

                if (!isEqual(this.state.floats, Object.values(data))) {
                    this.setState({ floats: Object.values(data) });
                }
                callbackFn();
            }).catch(error => {
                // check if loading is true => set it to false
                if (this.state.loading) {
                    this.setState({ loading: false });
                }

                callbackFn();
                this.context.actions.showError(error);
                throw new Error(error);
            });
    };

    /**
     * Request a new IP
     */
    requestNewFloating = (callbackFn = () => {
    }) => {
        return API.requestNewFloating()
            .then(() => {
                this.fetchFloats();
                callbackFn();
            }).catch((error) => {
                callbackFn();
                this.context.actions.showError(error);
                throw new Error(error);
            });
    };

    /**
     * Delete a Floating IP
     * @param floatId
     * @param callbackFn
     */
    deleteFloating = (floatId, callbackFn = () => {
    }) => {
        if (!floatId) return;
        return API.deleteFloating(floatId)
            .then(() => {
                const newFloatingArray = this.state.floats.filter(float => float.id !== floatId);
                if (!isEqual(this.state.floats, newFloatingArray)) {
                    this.setState({ floats: newFloatingArray });
                }
                callbackFn();
            })
            .catch((error) => {
                callbackFn();
                this.context.actions.showError(error);
                throw new Error(error);
            });
    };

    /**
     *
     * @param data
     * @param callbackFn
     */
    assignFloatingToServer = (data, callbackFn = () => {
    }) => {
        if (!data) return;
        return API.assignFloatingToServer(data)
            .then(() => {
                callbackFn();
            })
            .catch((error) => {
                callbackFn();
                this.context.actions.showError(error);
                throw new Error(error);
            });
    };

    render() {
        return (
            <FloatsContext.Provider value={ {
                ...this.state,
                actions: {
                    fetchFloats: this.fetchFloats,
                    requestNewFloating: this.requestNewFloating,
                    deleteFloating: this.deleteFloating,
                    assignFloatingToServer: this.assignFloatingToServer,
                }
            } }>
                { this.props.children }
            </FloatsContext.Provider>
        );
    }
}

export default FloatingsContextProvider;
