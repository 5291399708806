import React from "react";
import Skeleton from "react-loading-skeleton";

import { Card } from "../../Styling/Card";
import { Column, Grid } from "../../Styling/Grid";

const ComputeItemSkeleton = props => {
    const { ...rest } = props;
    return (
        <Column sm={ 6 } xxl={ 4 } { ...rest }>
            <Card>
                <div className="compute-card__header mb-4">
                    <span className="compute-card__status-led"/>
                    <span className="compute-card__title compute-card__title--no-padding"><Skeleton/></span>
                </div>
                <hr className="mb-4"/>
                <div className="compute-card__properties-wrapper">
                    <Grid>
                        <Column>
                            <h4 className="compute-card__properties-title"><Skeleton/></h4>
                        </Column>
                        <Column sm={ 6 }>
                            <div className="compute-card__properties">
                                <span className="compute-card__big-text"><Skeleton/></span>
                            </div>
                        </Column>
                        <Column sm={ 6 }>
                            <div className="compute-card__properties">
                                <span className="compute-card__big-text"><Skeleton/></span>
                            </div>
                        </Column>
                    </Grid>
                </div>
                <hr className="mb-4"/>
                <div className="compute-card__properties-wrapper">
                    <Grid>
                        <Column sm={ 6 }>
                            <h4 className="compute-card__properties-title"><Skeleton/></h4>
                            <div><Skeleton/></div>
                        </Column>
                        <Column sm={ 6 }>
                            <h4 className="compute-card__properties-title"><Skeleton/></h4>
                            <div><Skeleton/></div>
                        </Column>
                    </Grid>
                </div>
            </Card>
        </Column>
    );
};

export default React.memo(ComputeItemSkeleton);
