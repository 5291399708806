import React, { Component, createContext } from "react";

import API from "../../../Api/Openstack";
import { ErrorContext } from "../../ErrorContextProvider";
import isEqual from "../../../Ultils/isEqual";

export const FlavorsContext = createContext({});

class FlavorsContextProvider extends Component {
    static contextType = ErrorContext;

    // default floats state
    state = {
        flavors: [],
        loading: true,
    };

    /**
     * fetchFlavors
     */
    fetchFlavors = () => {
        return API.fetchFlavors()
            .then(({ data }) => {
                // check if loading is true => set it to false
                if (this.state.loading) {
                    this.setState({ loading: false });
                }

                // check if new data is not already set to state ==> prevent rerender
                if (!isEqual(this.state.flavors, Object.values(data))) {
                    this.setState({ flavors: Object.values(data) });
                }
            })
            .catch(error => {
                // check if loading is true => set it to false
                if (this.state.loading) {
                    this.setState({ loading: false });
                }

                this.context.actions.showError(error);
                throw new Error(error);
            });
    };

    /**
     * @param flavorId
     * @returns {string|null}
     */
    getFlavourNameById = flavorId => {
        if (flavorId && this.state.flavors.length > 0) {
            let flavorArr = this.state.flavors.filter(flavor => flavor.id === flavorId);
            // get the first item and return the Name
            if (flavorArr.length > 0) {
                return flavorArr[ 0 ].name;
            }

            // otherwise return null
            return null;
        }
    };

    /**
     * @param flavorId
     * @returns {string|null}
     */
    getFlavourById = flavorId => {
        if (flavorId && this.state.flavors.length > 0) {
            return this.state.flavors.find(flavour => flavour.id === flavorId);
        }
    };

    render() {
        return (
            <FlavorsContext.Provider value={ {
                ...this.state,
                actions: {
                    fetchFlavors: this.fetchFlavors,
                    getFlavourNameById: this.getFlavourNameById,
                    getFlavourById: this.getFlavourById,
                }
            } }>
                { this.props.children }
            </FlavorsContext.Provider>
        );
    }
}

export default FlavorsContextProvider;
