import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";

import { ModalContext } from "../../../../Context/ModalContextProvider";
import { BillingContext } from "../../../../Context/Api/Openstack/BillingContextProvider";
import { RadioCard } from "../../../Styling/Card";
import { Column, Grid } from "../../../Styling/Grid";
import withTranslation from "../../../../Ultils/withTranslation";

const ChangeFlavorSizeModal = props => {
    const [ canConfirm, setCanConfirm ] = useState(false);
    const billingContext = useContext(BillingContext);
    const modalContext = useContext(ModalContext);
    const { translation, computeId, confirmAction, selectedValue = null, selectBoxData, title } = props;

    const { handleSubmit, register, watch } = useForm();

    // allow confirmation if value has changed and if it's not the selectedValue
    const handleFormChange = () => {
        let selectValue = watch("flavor");
        setCanConfirm(selectValue !== selectedValue);
    };

    // allow confirmation if no selectedValue is given
    if (!canConfirm && selectedValue === null) {
        setCanConfirm(true);
    }

    const handleOnSubmit = formData => {
        if (formData.flavor) {
            confirmAction(computeId, formData.flavor);
            modalContext.actions.closeModal();
        }
    };

    return (
        <div>
            <h2>{ title }</h2>
            <form onChange={ handleFormChange } onSubmit={ handleSubmit(handleOnSubmit) }>
                <Grid>
                    { selectBoxData.map((flavor, index) => (
                        <Column key={ flavor.id } xs={ 12 } sm={ 6 } md={ 4 }>
                            <RadioCard
                                id={ `${ flavor.id }` }
                                name="flavor"
                                defaultChecked={ flavor.id === selectedValue }
                                value={ flavor.id }
                                radioRef={ register }
                            >
                                <div className="radio-card__header">
                                    <h3 className="flavor-price mb-0">
                                        { billingContext.actions.getPricingPerMonth(billingContext.prices[ flavor.id ]) }
                                        <small> € / { translation.react.global.month }</small>
                                    </h3>
                                </div>
                                <div className="radio-card__content">
                                    <div>{ flavor.cpu } vCPU{ flavor.cpu % 2 !== 0 ? '' : 's' }</div>
                                    <div>{ Math.round(flavor.ram / 1024) } GB RAM</div>
                                </div>
                            </RadioCard>
                        </Column>
                    )) }
                </Grid>
                <div className="btn-group btn-group--full-width">
                    <button disabled={ !canConfirm } type="submit" className="btn btn--big btn--danger mr-3">{ translation.react.global.order }</button>
                    <button type="button" onClick={ modalContext.actions.closeModal } className="btn btn--big btn--secondary">{ translation.react.global.cancel }</button>
                </div>
            </form>
        </div>
    );
};

ChangeFlavorSizeModal.propTypes = {
    confirmAction: PropTypes.func.isRequired,
    computeId: PropTypes.string.isRequired,
    selectBoxData: PropTypes.array.isRequired,
    selectedValue: PropTypes.string,
    title: PropTypes.string.isRequired,
};

export default withTranslation(ChangeFlavorSizeModal, [ 'react' ]);
