import React, { useContext } from "react";
import PropTypes from "prop-types";
import moment from "moment";

import withTranslation from "../../../Ultils/withTranslation";
import { Dropdown } from "../../Styling/Dropdown";
import { BillingContext } from "../../../Context/Api/Openstack/BillingContextProvider";

const BillingItem = props => {
    const billingContext = useContext(BillingContext);
    const { translation, billing } = props;

    const handleDownloadBilling = () => {
        billingContext.actions.getBillingAsPdf(billing);
    };

    return (
        <tr>
            <td data-label={ `${ translation.react.global.periodOfTime }:` }>{ moment(billing.begin).format('DD.MM.YYYY') } - { moment(billing.end).format('DD.MM.YYYY') }</td>
            <td data-label={ `${ translation.react.global.sum }:` }><strong>{ billing.sum } EUR</strong></td>
            <td data-label={ `${ translation.react.global.action }:` } className="ta-r">
                <Dropdown
                    dropdownAlignment="right-desktop"
                    arrowAlignment="right-desktop"
                >
                    <ul>
                        <li>
                            <button className="btn" onClick={ handleDownloadBilling }><i className="fa fa-download" aria-label="Herunterladen"/> { translation.react.global.download }</button>
                        </li>
                    </ul>
                </Dropdown>
            </td>
        </tr>
    );
};

BillingItem.propTypes = {
    billing: PropTypes.object.isRequired,
};

export default withTranslation(React.memo(BillingItem), [ 'react' ]);
