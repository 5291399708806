import React, { Component, createContext } from "react";

import API from "../../../Api/Openstack";
import { ErrorContext } from "../../ErrorContextProvider";
import isEqual from "../../../Ultils/isEqual";

export const LogContext = createContext({});

class LogsContextProvider extends Component {
    static contextType = ErrorContext;

    // default log state
    state = {
        logs: [],
        totalPages: 0,
        loading: true,
    };

    /**
     * @param page
     * @param amount
     */
    fetchLogs = (page = 0, amount = 10) => {
        return API.fetchLogs(page, amount)
            .then(({ data: { hits } }) => {
                const logs = hits.hits;
                const totalPages = Math.round(hits.total / logs.length);
                // check if loading is true => set it to false
                if (this.state.loading) {
                    this.setState({ loading: false });
                }

                if (!isEqual(this.state.logs, logs)) {
                    this.setState({
                        logs,
                        totalPages
                    });
                }
            })
            .catch((error) => {
                // check if loading is true => set it to false
                if (this.state.loading) {
                    this.setState({ loading: false });
                }

                this.context.actions.showError(error);
                throw new Error(error);
            })
    };

    render() {
        return (
            <LogContext.Provider value={ {
                ...this.state,
                actions: {
                    fetchLogs: this.fetchLogs,
                }
            } }>
                { this.props.children }
            </LogContext.Provider>
        );
    }
}

export default LogsContextProvider;
