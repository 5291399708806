import React from "react";

const EmptyGroup = props => {
    const { children } = props;
    return (
        <div className="empty-group">
            { children }
        </div>
    );
};

export default EmptyGroup;
